import { config } from "config.js";
import axios from "axios";

const uploadFileToS3 = async (file, bucketId, isUrl) => {
  try {
    if (typeof file === "object") {
      const token = localStorage.getItem("token");
      const formData = new FormData();
      formData.append("file", file);
      formData.append("bucketId", bucketId);

      const headers = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axios.post(
        `${config.URL_BACKEND_PG}api/v1/uploadFile`,
        formData,
        headers
      );

      return response.data.urlFile;
    } else {
      return isUrl;
    }
  } catch (error) {
    return error;
  }
};

export default uploadFileToS3;
