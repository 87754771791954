import styled from "styled-components";
import {
  WHITE_BACKGROUND,
  BACKGROUND_GREY,
  BORDER_GREY,
  PRIMARY_COLOR,
  SELECTION_COLOR,
  WHITE_COLOR,
  BLACK_COLOR,
} from "utils/const";

export const Content = styled.div`
  height: 100vh;
  background-color: ${BACKGROUND_GREY};
`;

export const InformationSection = styled.div`
  display: flex;
  width: 100%;
  height: 200px;
  border: 1px solid ${BORDER_GREY};
  background-color: ${WHITE_BACKGROUND};
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: 15%;
  background-position-x: 70%;
  padding: 60px;
  h1 {
    margin: 0;
    font-size: 3rem;
  }
  p {
    font-size: 1.8rem;
    text-align: justify;
    margin-left: 50px;
  }

  
  .title-description {
    margin: 0px 0px 20px;
    font-size: 40px;
    font-family: "Segoe UI", SegoeUI, "Helvetica Neue", Helvetica, Arial,
      sans-serif;
    letter-spacing: -0.05em;
  }

  .description {
    font-size: 1.8rem;
    font-weight: 400;
    margin-left: 0px;
    line-height: 1.5;
    text-align: left;
  }
`;
export const GlobalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: calc(100vh - 20rem);
  height: auto;
  padding: 5rem 0;
`;
export const ConfigurationSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  max-width: 100%;
  min-width: 50%;
  height: 600px;
  margin: 30px;
`;

export const ConfigLibrary = styled.div`
  width: 49%;
  height: 100%;
  background-color: ${BACKGROUND_GREY};
`;

export const ConfigConstant = styled.div`
  width: 49%;
  height: 100%;
  background-color: ${BACKGROUND_GREY};
`;

export const ContentButton = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 20%;
  padding: 10px;
  border: solid 1px ${BORDER_GREY};
  border-radius: 5px;
  background-color: ${WHITE_BACKGROUND};
  text-align: center;

  .select-categories {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    label {
      text-align: center;
      margin-bottom: 10px;
      width: 40%;
      font-size: 2rem;
      font-weight: 700;
      color: ${PRIMARY_COLOR};
    }
    .select {
      font-size: 1.4rem;
      width: 70%;
      padding: 0 5px;
      height: 36px;
      border: none;
      border-radius: 5px;
      background-color: ${PRIMARY_COLOR};
      color: ${WHITE_COLOR};
    }
  }
`;

export const Columns = styled.div`
  display: flex;
  width: 100%;
  height: 10%;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: 20px;
  align-items: center;
  border-radius: 5px 5px 0 0;
  background-color: var(--background-primary_color);
`;

export const Space = styled.div`
  margin-top: 14px;
`;

export const Column = styled.p`
  width: ${(props) => props.width}%;
  font-size: 1.8rem;
  font-weight: 500;
  text-align: ${(props) => props.textAling};
  align-items: center;
  color: ${WHITE_COLOR};
`;

export const ContentData = styled.div`
  width: 100%;
  height: 80%;
  border: solid 1px ${BORDER_GREY};
  margin-top: 14px;
  border-radius: 6px;
  background-color: ${WHITE_BACKGROUND};
  overflow: auto;
`;

export const InformationShare = styled.div`
  background-image: url("https://icons-greendragon.s3.us-east-2.amazonaws.com/other/neurona-gris.png");
  background-repeat: no-repeat;
  background-size: 30%;
  background-position: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 2rem;
  padding: 21px;
  border-radius: 5px;

  .body {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--background-secondary_color);
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-width: 450px;
  padding: 10px;
`;

export const Title = styled.p`
  text-align: center;
  margin-bottom: 20px;
  font-size: 3rem;
  font-weight: 800;
`;

export const ContentTablesTags = styled.div`
  max-height: 100%;
  overflow-y: auto;
`;
export const TableTag = styled.div`
  display: flex;
  justify-content: space-between;
  user-select: none;
  margin: 10px;
  padding: 2px;
  border: 1px solid #fefefe;
  border-radius: 10px;
  background-color: ${({ currentLibraryId, item }) =>
    currentLibraryId === item.id ? SELECTION_COLOR : BACKGROUND_GREY};
  text-align: ${(props) => props.textAling};
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10%;
  }
`;

export const ContentDataTag = styled.p`
  width: ${(props) => props.width}%;
  background-color: ${WHITE_COLOR};
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  font-size: 1.8rem;
  font-weight: 500;
`;

export const Divider = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin: 5px 0;

  :before,
  :after {
    content: "";
    flex: 1;
    border-bottom: 1px solid #ccc;
  }

  .divider-title {
    padding: 0 10px;
    color: #666;
    font-size: 16px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;
  width: 100%;

  label {
    width: 100%;
    margin: 5px 0;
    display: flex;
  }
  input {
    font-size: 1.4rem;
    margin: 0;
    padding: 1 10px;
    height: 30px;
    width: 100%;
    border-radius: 5px;
    border: 1px solid ${PRIMARY_COLOR};
    background-color: ${WHITE_COLOR};
    padding-left: 0.5rem;
  }
`;

export const SelectCategoriesStyles = {
  control: (provided) => ({
    ...provided,
    display: "flex",
    width: "100%",
  }),
  option: (provided) => ({
    ...provided,
    zIndex: 3,
  }),
  placeholder: (provided) => ({
    ...provided,
    display: "flex",
    justifyContent: "start",
    fontSize: "16px",
    "&:hover": {
      color: PRIMARY_COLOR,
    },
  }),
  menu: (provided) => ({
    ...provided,
    width: "100%",
    fontSize: "13px",
    left: 0,
    zIndex: 3,
  }),
  singleValue: (provided) => ({
    ...provided,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "calc(100% - 20px)",
  }),
  valueContainer: (provided) => ({
    ...provided,
    display: "flex",
    overflow: "none",
  }),
  container: (provided) => ({
    ...provided,
    width: "100%",
  }),
};

export const customSelectStyle = {
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "16px",
    color: BLACK_COLOR,
    fontWeight: 900,
  }),
  menu: (base) => ({
    ...base,
    width: "100%",
  }),

  valueContainer: (styles) => {
    return {
      ...styles,
      padding: "1px 2px",
    };
  },
  control: (styles) => ({
    ...styles,
    borderColor: "#017371",
    boxShadow: "none",
    "&:hover": {
      borderColor: "#4cb7a5",
    },
  }),
};

// Estilos generales para el modal
export const ModalContainer = styled.div`
  //background-color: #fff;
  padding: 30px;
  border-radius: 12px;
  //box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  width: 80%;
  max-width: 100%;
  
`;


// Encabezado del modal
export const ModalHeader = styled.div`
  font-size: 1.8rem;
  color: gray;
  font-weight: bold;
  margin-bottom: 15px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 15px;
`;

export const ModalHeadeError = styled.div`
  font-size: 1.6rem;
  color: #ff8e8e;
  font-weight: bold;
  margin-bottom: 12px;
  text-align: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 12px;
  margin-top: 45px;
`;

// Contenido del modal
export const ModalContent = styled.div`
  font-size: 1.4rem;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

// Listado de errores
export const ModalErrorList = styled.ul`
  color: #e74c3c;
  margin-top: 10px;
  list-style-type: none;
  padding: 0;
  text-align: left;
  font-size: 1.4rem;
`;

export const ModalErrorItem = styled.li`
  margin: 5px 0;
  font-size: 0.95rem;
  background-color: #fce4e4;
  padding: 8px;
  border-radius: 5px;
`;

export const SuccessMessage = styled.p`
  color: #27ae60;
  font-size: 1rem;
  margin-top: 10px;
`;

// Botones del modal
export const ModalButtons = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;

export const ModalButton = styled.button`
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s;
  border: none;
`;

export const ConfirmButton = styled(ModalButton)`
  background-color: #4a90e2;
  color: white;

  &:hover {
    background-color: #357abd;
  }
`;

export const CancelButton = styled(ModalButton)`
  background-color: #e74c3c;
  color: white;

  &:hover {
    background-color: #c0392b;
  }
`;
