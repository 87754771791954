import Barsicons from "images/Barsicons";
import ConfigIcon from "images/ConfigIcon";
import GlobalConstantsImg from "images/GlobalConstantsImg";
import GlobalListTableImg from "images/GlobalListTableImg";
import GlobalMeasurementTableImg from "images/GlobalMeasurementTableImg";
import GlobalTablesImg from "images/GlobalTablesImg";
import NeuronaIconD from "images/NeuronaIconD";
import Settingsicon from "images/Settingsicon";

const contentCardsSettings = [

  {
    id: 1,
    title: "CONFIGURATION: NO CODE DEVELOPMENT",
    image: <ConfigIcon />,
    description: "Create the data architecture, platform, configuration, customization without coding barriers",
    toNavigate: "/config-admin",
  },
  {
    id: 2,
    title: "PLATFORM SET UP",
    image: <Barsicons />,
    description: "Configure global app settings",
    toNavigate: "/setUp-admin",
  },
  {
    id: 3,
    title: "UX/UI STYLE PREFERENCES",
    image: <Settingsicon />,
    description: "Customize app settings and preferences",
    toNavigate: "/config-preferences",
  },

];

export default contentCardsSettings;
