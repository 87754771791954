import { ContainerPreviewChart, CardBarPreview } from "./indexStyle";
import CustomButton from "components/Buttons/CustomButton";
import { Line } from "react-chartjs-2";
import { useSetCurrentStyle } from "./Hooks/useSetCurrentStyle";
import { createRgbaColor } from "pages/Dashboard/helpers/createRgbaColor";
import { useState } from "react";
import { fixedDecimalNumber } from "pages/Dashboard/helpers/fixedDecimalNumber";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

const PreviewLineChart = ({
  dataChart,
  setChartEditDialog,
  maxHeight,
  maxWidth,
}) => {
  const {
    currentStyleLineDataChart,
    setCurrentStyleLineDataChart,
    handleUpdateChart,
    handleCreateConstantLine,
    handleDeleteConstantLine,
    constantLine,
    setConstantLine,
  } = useSetCurrentStyle({ dataChart, setChartEditDialog });

  const [currentSelectLine, setCurrentSelectLine] = useState(null);

  const bgColor = {
    id: "bgColor",
    beforeDraw: (chart, options) => {
      const { ctx, width, height } = chart;
      ctx.fillStyle = "white";
      ctx.fillRect(0, 0, width, height);
      ctx.restore();
    },
  };

  return (
    <ContainerPreviewChart heightChart={maxHeight} widthChart={maxWidth}>
      <div className="section-chart-button">
        <div className="preview-chart">
          <div className="chart">
            <CardBarPreview>
              <Line
                data={currentStyleLineDataChart.data}
                options={{
                  responsive: true,
                  plugins: {
                    datalabels: {
                      display: function (context) {
                        return context.dataset?.datalabels?.displayTotal;
                      },
                      align: function (context) {
                        return context.dataset?.datalabels?.positionTotal;
                      },
                      anchor: function (context) {
                        return context.dataset?.datalabels?.positionTotal;
                      },
                      formatter: (value, context) => {
                        const dataset = context.dataset;
                        const dataIndex = context.dataIndex;
                        const totalDataPoints = context.dataset.data.length;
                        if (
                          !currentStyleLineDataChart.currentDisplayShowCerosTotal &&
                          value === 0
                        ) {
                          return null;
                        }
                        if (dataset.constantValue !== null) {
                          if (dataIndex === totalDataPoints - 1) {
                            return dataset.constantValue === 0
                              ? null
                              : dataset.constantValue;
                          } else {
                            return null;
                          }
                        }
                        return fixedDecimalNumber({
                          number: value,
                          numDecimals: dataset.datalabels?.numDecimals,
                        });
                      },
                      color: function (context) {
                        return context.dataset?.datalabels?.colorTotal;
                      },
                      backgroundColor: function (context) {
                        return context.dataset?.datalabels
                          ?.backgroundColorTotal;
                      },
                      borderWidth: 1,
                      borderColor: function (context) {
                        return context.dataset?.datalabels
                          ?.backgroundColorTotal;
                      },
                      borderRadius: 3,
                      font: function (context) {
                        return {
                          size: context.dataset?.datalabels?.fontSizeTotal,
                        };
                      },
                      offset: function (context) {
                        return context.dataset?.datalabels?.offsetTotal;
                      },
                    },
                    title: {
                      display: true,
                      color: currentStyleLineDataChart.currentTitleColor,
                      text: currentStyleLineDataChart.currentNameLineChart,
                      font: {
                        size: currentStyleLineDataChart.currentTitleFontSize,
                        family: "Montserrat",
                      },
                      padding: {
                        top: 20,
                      },
                    },
                    legend: {
                      display: true,
                      onClick: function (e, legendItem, legend) {
                        const index = legendItem.datasetIndex;
                        const ci = legend.chart;
                        const meta = ci.getDatasetMeta(index);

                        // Toggle the visibility
                        meta.hidden =
                          meta.hidden === null
                            ? !ci.data.datasets[index].hidden
                            : null;

                        // We hid a dataset ... rerender the chart
                        ci.update();
                      },
                      labels: {
                        generateLabels: function (chart) {
                          return currentStyleLineDataChart.data.datasets.map(
                            (dataset, i) => {
                              const meta = chart.getDatasetMeta(i);
                              return {
                                text: dataset.alias,
                                fillStyle: dataset.borderColor,
                                hidden: meta.hidden,
                                lineCap: dataset.borderCapStyle,
                                lineDash: dataset.borderDash,
                                lineDashOffset: dataset.borderDashOffset,
                                lineJoin: dataset.borderJoinStyle,
                                lineWidth: dataset.borderWidth,
                                strokeStyle: dataset.borderColor,
                                pointStyle: dataset.pointStyle,
                                datasetIndex: i,
                                fontStyle: meta.hidden ? "italic" : "normal",
                                fontColor: meta.hidden ? "#999" : "#666",
                              };
                            }
                          );
                        },
                      },
                    },
                    tooltip: {
                      enabled: true,
                      callbacks: {
                        label: function (context) {
                          return `${dataChart.type}: ${context.raw}`;
                        },
                      },
                      titleFont: {
                        size: currentStyleLineDataChart.currentTitleTooltipFontSize,
                      },
                      bodyFont: {
                        size: currentStyleLineDataChart.currentBodyTooltipFontSize,
                      },
                      mode: "nearest",
                      intersect: true,
                      titleAlign: "center",
                      displayColors: false,
                    },
                  },
                  animation: {
                    duration: 1500,
                    easing: "easeInOutQuart",
                  },
                  scales: {
                    x: {
                      grid: {
                        display: currentStyleLineDataChart.currentDisplayAxisX,
                        color: createRgbaColor({
                          hexadecimalColor:
                            currentStyleLineDataChart?.currentAxisXColor,
                        }),
                      },
                      ticks: {
                        callback: function (value) {
                          let label = this.getLabelForValue(value);
                          return label.trim().split(" ");
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        font: {
                          size: currentStyleLineDataChart.currentLabelsXFontSize,
                        },
                        color: currentStyleLineDataChart.currentLabelsXColor,
                        padding: currentStyleLineDataChart.currentPaddingAxisX,
                      },
                      title: {
                        display: currentStyleLineDataChart.currentDisplayTitleX,
                        text: currentStyleLineDataChart.currentTitleAxisX,
                        font: {
                          size: currentStyleLineDataChart.currentTitleXFontSize,
                        },
                        color: currentStyleLineDataChart.currentTitleXColor,
                        padding: {
                          top: -currentStyleLineDataChart.currentPaddingAxisX,
                        },
                      },
                    },
                    y: {
                      beginAtZero:
                        currentStyleLineDataChart.currentStatusBeginAtZero,
                      min: currentStyleLineDataChart.currentMinValueAxis,
                      max: currentStyleLineDataChart.currentMaxValueAxis,
                      grid: {
                        display: currentStyleLineDataChart.currentDisplayAxisY,
                        color: createRgbaColor({
                          hexadecimalColor:
                            currentStyleLineDataChart?.currentAxisYColor,
                        }),
                      },
                      ticks: {
                        callback: function (value) {
                          let label = this.getLabelForValue(value);
                          return label.trim().split(" ");
                        },
                        maxRotation: 0,
                        minRotation: 0,
                        stepSize: currentStyleLineDataChart?.currentStepSizeY,
                        font: {
                          size: currentStyleLineDataChart.currentLabelsYFontSize,
                        },
                        color: currentStyleLineDataChart.currentLabelsYColor,
                        padding: currentStyleLineDataChart.currentPaddingAxisY,
                      },
                      title: {
                        display: currentStyleLineDataChart.currentDisplayTitleY,
                        text: currentStyleLineDataChart.currentTitleAxisY,
                        font: {
                          size: currentStyleLineDataChart.currentTitleYFontSize,
                        },
                        color: currentStyleLineDataChart.currentTitleYColor,
                        padding: {
                          bottom:
                            -currentStyleLineDataChart.currentPaddingAxisY,
                        },
                      },
                    },
                  },
                }}
                plugins={[bgColor]}
              />
            </CardBarPreview>
          </div>
        </div>
        <div className="container-update">
          <CustomButton
            text={"Update Chart"}
            margin={0}
            onClick={handleUpdateChart}
          />
        </div>
      </div>
      <div className="editing-tools">
        <div className="groupe-styles">
          <div className="sections">
            <h3>Title</h3>
            <div className="section-style">
              <input
                placeholder="Title Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentNameLineChart}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentNameLineChart: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-style">
              <p>Title Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentTitleColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentTitleFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentTitleFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={60}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentTitleFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Data Series</h3>
            <div className="section-style">
              <p>Select Data Series</p>
              <select
                value={currentSelectLine}
                onChange={(e) => setCurrentSelectLine(e.target.value)}
              >
                {[
                  { _id: "select", alias: "Select" },
                  ...currentStyleLineDataChart.data.datasets,
                ].map((elm, index) => {
                  return (
                    <>
                      <option key={index} value={elm._id}>
                        {elm.alias}
                      </option>
                    </>
                  );
                })}
              </select>
            </div>
            {constantLine.create && (
              <>
                <div className="section-style">
                  <p>Reference Line</p>
                  <br />
                  <br />
                  <input
                    placeholder="Reference Line Name"
                    className="input-title"
                    type="text"
                    value={constantLine.label}
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        label: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <p>Reference Line Color</p>
                  <input
                    className="alias"
                    type="color"
                    value={constantLine.borderColorHexadecimal}
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        borderColorHexadecimal: e.target.value,
                        borderColor: createRgbaColor({
                          hexadecimalColor: e.target.value,
                          opacity: 0.1,
                        }),
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <p>Reference Line Value</p>
                  <input
                    value={constantLine.constantValue}
                    type="number"
                    onChange={(e) => {
                      setConstantLine((current) => ({
                        ...current,
                        constantValue: parseFloat(e.target.value),
                      }));
                    }}
                  />
                </div>
                <div className="section-style">
                  <CustomButton
                    text={`Create ${constantLine?.label}`}
                    disabled={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? true
                        : false
                    }
                    primaryColor={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? VISIBILITY_OFF_ICON
                        : SECOND_COLOR
                    }
                    secondaryColor={
                      constantLine.label === "" ||
                      constantLine.constantValue === 0
                        ? VISIBILITY_OFF_ICON
                        : PRIMARY_COLOR
                    }
                    margin={0}
                    onClick={() => handleCreateConstantLine()}
                  />
                </div>
              </>
            )}

            <div className="section-style">
              <CustomButton
                text={constantLine.create ? "Cancel" : "Create Reference Line"}
                primaryColor={constantLine.create ? DELETE_COLOR : PRIMARY_COLOR}
                secondaryColor={
                  constantLine.create ? HOVER_DELETE_COLOR : SECOND_COLOR
                }
                margin={0}
                onClick={() =>
                  setConstantLine({
                    create: !constantLine.create,
                    label: "",
                    borderColor: PRIMARY_COLOR,
                    borderColorHexadecimal: PRIMARY_COLOR,
                    constantValue: 0,
                  })
                }
              />
            </div>
            {currentSelectLine &&
              currentSelectLine !== "select" &&
              currentStyleLineDataChart.data.datasets.map((dataset, index) => {
                if (dataset._id === currentSelectLine) {
                  return (
                    <>
                      <div key={index} className="section-style">
                        <p>Series Name</p>
                        <br />
                        <br />
                        <input
                          placeholder="Series Name"
                          className="input-title"
                          type="text"
                          value={dataset.alias}
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      alias: e.target.value,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <br />
                      <div className="divider">
                        <span className="divider-title">
                          {!dataset.constantValue
                            ? "Line Options"
                            : "Reference Line Options"}
                        </span>
                      </div>
                      <div className="section-style">
                        <p>Show Line</p>
                        <input
                          className="alias"
                          checked={dataset.showLine}
                          type="checkbox"
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      showLine: !dataset.showLine,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="section-style">
                        <p>Show Series</p>
                        <input
                          className="alias"
                          checked={!dataset.hidden}
                          type="checkbox"
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      hidden: !dataset.hidden,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>

                      <div className="section-style">
                        <p>Fill Out</p>
                        <input
                          className="alias"
                          checked={dataset.fill}
                          type="checkbox"
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      fill: !dataset.fill,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      {dataset.constantValue !== null && (
                        <div className="section-style">
                          <p>Reference Value</p>
                          <input
                            value={dataset.constantValue}
                            type="number"
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        data: new Array(elm.data.length).fill(
                                          !parseFloat(e.target.value)
                                            ? 0
                                            : parseFloat(e.target.value)
                                        ),
                                        constantValue: !parseFloat(
                                          e.target.value
                                        )
                                          ? 0
                                          : parseFloat(e.target.value),
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className="section-style">
                        <p>Border Color</p>
                        <input
                          className="alias"
                          type="color"
                          value={dataset.borderColor}
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      borderColor: e.target.value,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="section-style">
                        <p>Fill Color</p>
                        <input
                          className="alias"
                          type="color"
                          value={dataset.backgroundColorHexadecimal}
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      backgroundColorHexadecimal:
                                        e.target.value,
                                      backgroundColor: createRgbaColor({
                                        hexadecimalColor: e.target.value,
                                      }),
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        />
                      </div>
                      <div className="section-style">
                        <p>Line Width</p>
                        <div>
                          <h4>{dataset.borderWidth} px</h4>
                          <input
                            value={dataset.borderWidth}
                            type="range"
                            step={1}
                            min={1}
                            max={15}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderWidth: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="section-style">
                        <p>Line Dash Type</p>
                        <div>
                          <h4>{dataset.borderDash[0]} px</h4>
                          <input
                            value={dataset.borderDash[0]}
                            type="range"
                            step={1}
                            min={0}
                            max={50}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderDash:
                                          e.target.value === 0
                                            ? []
                                            : [e.target.value, e.target.value],
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="section-style">
                        <p>Line Dash Offset</p>
                        <div>
                          <h4>{dataset.borderDashOffset} px</h4>
                          <input
                            value={dataset.borderDashOffset}
                            type="range"
                            step={1}
                            min={-50}
                            max={50}
                            onChange={(e) => {
                              setCurrentStyleLineDataChart((current) => {
                                const newDatasets = current.data.datasets.map(
                                  (elm) => {
                                    if (elm._id === currentSelectLine) {
                                      return {
                                        ...elm,
                                        borderDashOffset: e.target.value,
                                      };
                                    }
                                    return elm;
                                  }
                                );
                                return {
                                  ...current,
                                  data: {
                                    ...current.data,
                                    datasets: newDatasets,
                                  },
                                };
                              });
                            }}
                          />
                        </div>
                      </div>
                      <div className="section-style">
                        <p>Line Dash End Style</p>
                        <select
                          value={dataset.borderCapStyle}
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      borderCapStyle: e.target.value,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        >
                          {["butt", "round", "square"].map((elm, index) => {
                            return (
                              <option key={index} value={elm}>
                                {elm}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="section-style">
                        <p>Border Join Style</p>
                        <select
                          value={dataset.borderJoinStyle}
                          onChange={(e) => {
                            setCurrentStyleLineDataChart((current) => {
                              const newDatasets = current.data.datasets.map(
                                (elm) => {
                                  if (elm._id === currentSelectLine) {
                                    return {
                                      ...elm,
                                      borderJoinStyle: e.target.value,
                                    };
                                  }
                                  return elm;
                                }
                              );
                              return {
                                ...current,
                                data: {
                                  ...current.data,
                                  datasets: newDatasets,
                                },
                              };
                            });
                          }}
                        >
                          {["miter", "round", "bevel"].map((elm, index) => {
                            return (
                              <option key={index} value={elm}>
                                {elm}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      {dataset.constantValue == null && (
                        <>
                          <div className="divider">
                            <span className="divider-title">Line Style</span>
                          </div>
                          <div className="section-style">
                            <p>Interpolation Mode</p>
                            <select
                              value={dataset.cubicInterpolationMode}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          cubicInterpolationMode:
                                            e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            >
                              {["default", , "monotone"].map((elm, index) => {
                                return (
                                  <option key={index} value={elm}>
                                    {elm}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                          <div className="section-style">
                            <p>Stepped</p>
                            <select
                              value={dataset.stepped}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          stepped:
                                            e.target.value === "false"
                                              ? false
                                              : e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            >
                              {["false", "after", "before"].map(
                                (elm, index) => {
                                  return (
                                    <option key={index} value={elm}>
                                      {elm}
                                    </option>
                                  );
                                }
                              )}
                            </select>
                          </div>
                          <div className="section-style">
                            <p>Curved Line</p>
                            <div>
                              <h4>{dataset.tension} px</h4>
                              <input
                                value={dataset.tension}
                                type="range"
                                step={0.1}
                                min={0}
                                max={1}
                                disabled={
                                  dataset.stepped === false &&
                                  dataset.cubicInterpolationMode === "default"
                                    ? false
                                    : true
                                }
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            tension: parseFloat(e.target.value),
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          {/*  MARKER OPTIONS*/}
                          <div className="divider">
                            <span className="divider-title">
                              Marker Options
                            </span>
                          </div>

                          <div className="section-style">
                            <p>Radius</p>
                            <div>
                              <h4>{dataset.pointRadius} px</h4>
                              <input
                                value={dataset.pointRadius}
                                type="range"
                                step={1}
                                min={1}
                                max={10}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            pointRadius: e.target.value,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="section-style">
                            <p>Border Width</p>
                            <div>
                              <h4>{dataset.pointBorderWidth} px</h4>
                              <input
                                value={dataset.pointBorderWidth}
                                type="range"
                                step={1}
                                min={0}
                                max={5}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            pointBorderWidth: e.target.value,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>

                          <div className="section-style">
                            <p>Fill Color</p>
                            <input
                              className="alias"
                              type="color"
                              value={dataset.pointBackgroundColor}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          pointBackgroundColor: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>

                          <div className="section-style">
                            <p>Border Color</p>
                            <input
                              className="alias"
                              type="color"
                              value={dataset.pointBorderColor}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          pointBorderColor: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>

                          <div className="section-style">
                            <p>Style</p>
                            <select
                              value={dataset.pointStyle}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          pointStyle: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            >
                              {[
                                "circle",
                                "cross",
                                "crossRot",
                                "dash",
                                "line",
                                "rect",
                                "rectRounded",
                                "rectRot",
                                "star",
                                "triangle",
                              ].map((elm, index) => {
                                return (
                                  <option key={index} value={elm}>
                                    {elm}
                                  </option>
                                );
                              })}
                            </select>
                          </div>

                          <div className="divider">
                            <span className="divider-title">
                              Hover Marker Options
                            </span>
                          </div>

                          <div className="section-style">
                            <p>Hover Fill Color</p>
                            <input
                              className="alias"
                              type="color"
                              value={dataset.pointHoverBackgroundColor}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          pointHoverBackgroundColor:
                                            e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="section-style">
                            <p>Hover Border Color</p>
                            <input
                              className="alias"
                              type="color"
                              value={dataset.pointHoverBorderColor}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          pointHoverBorderColor: e.target.value,
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                          <div className="section-style">
                            <p>Hover Border Width</p>
                            <div>
                              <h4>{dataset.pointHoverBorderWidth} px</h4>
                              <input
                                value={dataset.pointHoverBorderWidth}
                                type="range"
                                step={1}
                                min={1}
                                max={10}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            pointHoverBorderWidth:
                                              e.target.value,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="section-style">
                            <p>Hover Radius</p>
                            <div>
                              <h4>{dataset.pointHoverRadius} px</h4>
                              <input
                                value={dataset.pointHoverRadius}
                                type="range"
                                step={1}
                                min={1}
                                max={10}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            pointHoverRadius: e.target.value,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="section-style">
                            <p>Select Radius</p>
                            <div>
                              <h4>{dataset.pointHitRadius} px</h4>
                              <input
                                value={dataset.pointHitRadius}
                                type="range"
                                step={1}
                                min={1}
                                max={20}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            pointHitRadius: e.target.value,
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </>
                      )}

                      <div className="divider">
                        <span className="divider-title">Values Total</span>
                      </div>

                      <div className="sections">
                        <div className="section-color-conditional">
                          <p>View Total</p>
                          <div>
                            <input
                              checked={dataset.datalabels.displayTotal}
                              type="checkbox"
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          datalabels: {
                                            ...elm.datalabels,
                                            displayTotal:
                                              !elm.datalabels.displayTotal,
                                          },
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                            <input
                              className="alias"
                              type="color"
                              value={dataset.datalabels.colorTotal}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          datalabels: {
                                            ...elm.datalabels,
                                            displayTotal: true,
                                            colorTotal: e.target.value,
                                          },
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      {(currentStyleLineDataChart.currentDisplayTotal ||
                        dataset.datalabels.displayTotal) && (
                        <>
                          <div className="sections">
                            <div className="section-style">
                              <p>Total Size</p>
                              <div>
                                <h4>{dataset.datalabels.fontSizeTotal} px</h4>
                                <input
                                  value={dataset.datalabels.fontSizeTotal}
                                  type="range"
                                  step={1}
                                  min={10}
                                  max={50}
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                fontSizeTotal: e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="sections">
                            <div className="section-style">
                              <p>Position Total</p>
                              <select
                                value={dataset.datalabels.positionTotal}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              positionTotal: e.target.value,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              >
                                <option value="end">Top</option>
                                <option value="center">Center</option>
                                <option value="start">Down</option>
                              </select>
                            </div>
                          </div>
                          <div className="sections">
                            <div className="section-color-conditional">
                              <p>Total Background</p>
                              <div>
                                <input
                                  checked={
                                    dataset.datalabels.displayBackgroundTotal
                                  }
                                  type="checkbox"
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                displayBackgroundTotal:
                                                  !elm.datalabels
                                                    .displayBackgroundTotal,
                                                backgroundColorTotal: elm
                                                  .datalabels
                                                  .displayBackgroundTotal
                                                  ? "rgba(255, 255, 255, 0)"
                                                  : "#2df137",
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                                <input
                                  className="alias"
                                  type="color"
                                  value={
                                    dataset.datalabels.backgroundColorTotal
                                  }
                                  onChange={(e) => {
                                    setCurrentStyleLineDataChart((current) => {
                                      const newDatasets =
                                        current.data.datasets.map((elm) => {
                                          if (elm._id === currentSelectLine) {
                                            return {
                                              ...elm,
                                              datalabels: {
                                                ...elm.datalabels,
                                                displayBackgroundTotal: true,
                                                backgroundColorTotal:
                                                  e.target.value,
                                              },
                                            };
                                          }
                                          return elm;
                                        });
                                      return {
                                        ...current,
                                        data: {
                                          ...current.data,
                                          datasets: newDatasets,
                                        },
                                      };
                                    });
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="section-style">
                            <p>Offset</p>
                            <div>
                              <h4>{dataset.datalabels.offsetTotal} px</h4>
                              <input
                                value={dataset.datalabels.offsetTotal}
                                type="range"
                                step={1}
                                min={-100}
                                max={100}
                                onChange={(e) => {
                                  setCurrentStyleLineDataChart((current) => {
                                    const newDatasets =
                                      current.data.datasets.map((elm) => {
                                        if (elm._id === currentSelectLine) {
                                          return {
                                            ...elm,
                                            datalabels: {
                                              ...elm.datalabels,
                                              offsetTotal: e.target.value,
                                            },
                                          };
                                        }
                                        return elm;
                                      });
                                    return {
                                      ...current,
                                      data: {
                                        ...current.data,
                                        datasets: newDatasets,
                                      },
                                    };
                                  });
                                }}
                              />
                            </div>
                          </div>
                          <div className="section-style">
                            <p>Num Decimals</p>
                            <input
                              value={dataset.datalabels.numDecimals}
                              type="number"
                              min={0}
                              max={100}
                              onChange={(e) => {
                                setCurrentStyleLineDataChart((current) => {
                                  const newDatasets = current.data.datasets.map(
                                    (elm) => {
                                      if (elm._id === currentSelectLine) {
                                        return {
                                          ...elm,
                                          datalabels: {
                                            ...elm.datalabels,
                                            numDecimals: parseFloat(
                                              e.target.value
                                            ),
                                          },
                                        };
                                      }
                                      return elm;
                                    }
                                  );
                                  return {
                                    ...current,
                                    data: {
                                      ...current.data,
                                      datasets: newDatasets,
                                    },
                                  };
                                });
                              }}
                              placeholder={"Decimals"}
                            />
                          </div>
                        </>
                      )}
                      {dataset.constantValue !== null && (
                        <CustomButton
                          text={`Delete ${dataset.alias}`}
                          primaryColor={DELETE_COLOR}
                          secondaryColor={HOVER_DELETE_COLOR}
                          onClick={() =>
                            handleDeleteConstantLine({ id: dataset._id })
                          }
                          margin={0}
                        />
                      )}
                    </>
                  );
                }
              })}
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Total</h3>
            <div className="section-color-conditional">
              <p>View Total</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTotal}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTotal:
                        !currentStyleLineDataChart.currentDisplayTotal,
                      currentDisplayBackgroundTotal: e.target.checked
                        ? current.currentDisplayBackgroundTotal
                        : false,
                      currentTotalFontColor:
                        currentStyleLineDataChart.currentDisplayTotal
                          ? "#EEEEEE"
                          : "#000000",
                      data: {
                        ...current.data,
                        datasets: current.data.datasets.map((elm) => {
                          if (!elm.constantValue) {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                displayTotal: !elm.datalabels.displayTotal,
                                colorTotal: elm.datalabels.displayTotal
                                  ? "#EEEEEE"
                                  : "#000000",
                                displayBackgroundTotal: false,
                                backgroundColorTotal: "rgba(255, 255, 255, 0)",
                              },
                            };
                          }
                          return elm;
                        }),
                      },
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTotalFontColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTotal: true,
                      currentTotalFontColor: e.target.value,
                      data: {
                        ...current.data,
                        datasets: current.data.datasets.map((elm) => {
                          if (!elm.constantValue) {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                displayTotal: true,
                                colorTotal: e.target.value,
                              },
                            };
                          }
                          return elm;
                        }),
                      },
                    }))
                  }
                />
              </div>
            </div>

            {currentStyleLineDataChart.currentDisplayTotal && (
              <>
                <div className="section-style">
                  <p>Total Size</p>
                  <div>
                    <h4>{currentStyleLineDataChart.currentTotalFontSize} px</h4>
                    <input
                      value={currentStyleLineDataChart.currentTotalFontSize}
                      type="range"
                      step={1}
                      min={10}
                      max={50}
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentTotalFontSize: e.target.value,
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  fontSizeTotal: e.target.value,
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="section-style">
                  <p>Position Total</p>
                  <select
                    value={currentStyleLineDataChart.currentTotalPosition}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTotalPosition: e.target.value,
                        data: {
                          ...current.data,
                          datasets: current.data.datasets.map((elm) => {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                positionTotal: e.target.value,
                              },
                            };
                          }),
                        },
                      }))
                    }
                  >
                    <option value="end">Top</option>
                    <option value="center">Center</option>
                    <option value="start">Down</option>
                  </select>
                </div>
                <div className="section-color-conditional">
                  <p>Total Background</p>
                  <div>
                    <input
                      checked={
                        currentStyleLineDataChart.currentDisplayBackgroundTotal
                      }
                      type="checkbox"
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentDisplayBackgroundTotal:
                            !currentStyleLineDataChart.currentDisplayBackgroundTotal,
                          currentTotalBackground:
                            currentStyleLineDataChart.currentDisplayBackgroundTotal
                              ? "#EEEEEE"
                              : "#000000",
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  displayBackgroundTotal:
                                    !elm.datalabels.displayBackgroundTotal,
                                  backgroundColorTotal: elm.datalabels
                                    .displayBackgroundTotal
                                    ? "rgba(255, 255, 255, 0)"
                                    : "#2df137",
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                    <input
                      className="alias"
                      type="color"
                      value={currentStyleLineDataChart.currentTotalBackground}
                      onChange={(e) =>
                        setCurrentStyleLineDataChart((current) => ({
                          ...current,
                          currentDisplayBackgroundTotal: true,
                          currentTotalBackground: e.target.value,
                          data: {
                            ...current.data,
                            datasets: current.data.datasets.map((elm) => {
                              return {
                                ...elm,
                                datalabels: {
                                  ...elm.datalabels,
                                  displayBackgroundTotal: true,
                                  backgroundColorTotal: e.target.value,
                                },
                              };
                            }),
                          },
                        }))
                      }
                    />
                  </div>
                </div>
                <div className="section-style">
                  <p>Show Ceros</p>
                  <input
                    checked={
                      currentStyleLineDataChart.currentDisplayShowCerosTotal
                    }
                    type="checkbox"
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentDisplayShowCerosTotal:
                          !currentStyleLineDataChart.currentDisplayShowCerosTotal,
                      }))
                    }
                  />
                </div>
                <div className="section-style">
                  <p>Num Decimals</p>
                  <input
                    value={currentStyleLineDataChart.currentTotalNumDecimals}
                    type="number"
                    min={0}
                    max={100}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTotalNumDecimals: parseFloat(e.target.value),
                        data: {
                          ...current.data,
                          datasets: current.data.datasets.map((elm) => {
                            return {
                              ...elm,
                              datalabels: {
                                ...elm.datalabels,
                                numDecimals: parseFloat(e.target.value),
                              },
                            };
                          }),
                        },
                      }))
                    }
                    placeholder={"Number"}
                  />
                </div>
              </>
            )}
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Y Axis</h3>
            <div className="section-style">
              <input
                placeholder="Axis Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentTitleAxisY}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleAxisY: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-color-conditional">
              <p>View Title</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTitleY}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleY:
                        !currentStyleLineDataChart.currentDisplayTitleY,
                      currentTitleYColor:
                        currentStyleLineDataChart.currentDisplayTitleY
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />

                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTitleYColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleY: true,
                      currentTitleYColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {currentStyleLineDataChart.currentDisplayTitleY && (
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>{currentStyleLineDataChart.currentTitleYFontSize} px</h4>
                  <input
                    value={currentStyleLineDataChart.currentTitleYFontSize}
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTitleYFontSize: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            )}
            <div className="section-style">
              <p>Labels Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentLabelsYColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentLabelsYColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Labels Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentLabelsYFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentLabelsYFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentLabelsYFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Labels Offset</p>
              <div>
                <h4>{currentStyleLineDataChart.currentPaddingAxisY} px</h4>
                <input
                  value={currentStyleLineDataChart.currentPaddingAxisY}
                  type="range"
                  step={1}
                  min={5}
                  max={100}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentPaddingAxisY: parseFloat(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-color-conditional">
              <p>View Axis</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayAxisY}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisY:
                        !currentStyleLineDataChart.currentDisplayAxisY,
                      currentAxisYColor:
                        currentStyleLineDataChart.currentDisplayAxisY
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentAxisYColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisY: true,
                      currentAxisYColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Origin At Zero</p>
              <input
                checked={currentStyleLineDataChart.currentStatusBeginAtZero}
                type="checkbox"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentStatusBeginAtZero:
                      !currentStyleLineDataChart.currentStatusBeginAtZero,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Min Axis Value</p>
              <input
                value={currentStyleLineDataChart.currentMinValueAxis}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentMinValueAxis: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Max Axis Value</p>
              <input
                value={currentStyleLineDataChart.currentMaxValueAxis}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentMaxValueAxis: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Step Size</p>
              <input
                value={currentStyleLineDataChart.currentStepSizeY}
                type="number"
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentStepSizeY: parseFloat(e.target.value),
                  }))
                }
              />
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>X Axis</h3>
            <div className="section-style">
              <input
                placeholder="Axis Name"
                className="input-title"
                type="text"
                value={currentStyleLineDataChart.currentTitleAxisX}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentTitleAxisX: e.target.value,
                  }))
                }
              />
            </div>
            <br />
            <div className="section-color-conditional">
              <p>View Title</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayTitleX}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleX:
                        !currentStyleLineDataChart.currentDisplayTitleX,
                      currentTitleXColor:
                        currentStyleLineDataChart.currentDisplayTitleX
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentTitleXColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayTitleX: true,
                      currentTitleXColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            {currentStyleLineDataChart.currentDisplayTitleX && (
              <div className="section-style">
                <p>Title Size</p>
                <div>
                  <h4>{currentStyleLineDataChart.currentTitleXFontSize} px</h4>
                  <input
                    value={currentStyleLineDataChart.currentTitleXFontSize}
                    type="range"
                    step={1}
                    min={10}
                    max={40}
                    onChange={(e) =>
                      setCurrentStyleLineDataChart((current) => ({
                        ...current,
                        currentTitleXFontSize: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>
            )}
            <div className="section-style">
              <p>Labels Color</p>
              <input
                className="alias"
                type="color"
                value={currentStyleLineDataChart.currentLabelsXColor}
                onChange={(e) =>
                  setCurrentStyleLineDataChart((current) => ({
                    ...current,
                    currentLabelsXColor: e.target.value,
                  }))
                }
              />
            </div>
            <div className="section-style">
              <p>Labels Size</p>
              <div>
                <h4>{currentStyleLineDataChart.currentLabelsXFontSize} px</h4>
                <input
                  value={currentStyleLineDataChart.currentLabelsXFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentLabelsXFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Labels Offset</p>
              <div>
                <h4>{currentStyleLineDataChart.currentPaddingAxisX} px</h4>
                <input
                  value={currentStyleLineDataChart.currentPaddingAxisX}
                  type="range"
                  step={1}
                  min={5}
                  max={100}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentPaddingAxisX: parseFloat(e.target.value),
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-color-conditional">
              <p>View Axis X</p>
              <div>
                <input
                  checked={currentStyleLineDataChart.currentDisplayAxisX}
                  type="checkbox"
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisX:
                        !currentStyleLineDataChart.currentDisplayAxisX,
                      currentAxisXColor:
                        currentStyleLineDataChart.currentDisplayAxisX
                          ? "#EEEEEE"
                          : "#000000",
                    }))
                  }
                />
                <input
                  className="alias"
                  type="color"
                  value={currentStyleLineDataChart.currentAxisXColor}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentDisplayAxisX: true,
                      currentAxisXColor: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>

        <div className="groupe-styles">
          <div className="sections">
            <h3>Tooltip</h3>
            <div className="section-style">
              <p>Title Size</p>
              <div>
                <h4>
                  {currentStyleLineDataChart.currentTitleTooltipFontSize} px
                </h4>
                <input
                  value={currentStyleLineDataChart.currentTitleTooltipFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentTitleTooltipFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
            <div className="section-style">
              <p>Body Size</p>
              <div>
                <h4>
                  {currentStyleLineDataChart.currentBodyTooltipFontSize} px
                </h4>
                <input
                  value={currentStyleLineDataChart.currentBodyTooltipFontSize}
                  type="range"
                  step={1}
                  min={10}
                  max={40}
                  onChange={(e) =>
                    setCurrentStyleLineDataChart((current) => ({
                      ...current,
                      currentBodyTooltipFontSize: e.target.value,
                    }))
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ContainerPreviewChart>
  );
};

export default PreviewLineChart;
