import { useEffect } from "react";
import { ContainerSelect } from "./style";
import OperationInfo from "components/ElementDetails/OperationInfo";

function SelectInput({
  field,
  register,
  errors = {},
  warningGlobalOptions,
  disabledSelectGlobalMesurement = false,
}) {
  const {
    name,
    alias,
    required,
    options,
    possible,
    type,
    disableOptions,
    listTableRowId,
    setListTableRowId,
  } = field;

  const paramsRegister = () => {
    let params = {};
    if (required) {
      params.required = "this is required";
    }
    return params;
  };

  const handelOnChageMasterKey = (value) => {
    const unicRowId = listTableRowId.find(
      (elm, index) => index === options.indexOf(value)
    );
    setListTableRowId("listTableRowId", unicRowId);
  };

  useEffect(() => {
    if (listTableRowId && listTableRowId.length > 0) {
      setListTableRowId("listTableRowId", listTableRowId[0]);
    }
  }, [listTableRowId]);

  return (
    <ContainerSelect>
      <div className="label">
        <label htmlFor={name}>
          {type === "currency" ? "Currency:" : alias || name}
          {required ? <span className="required">*</span> : null}
        </label>
        {warningGlobalOptions && (
          <OperationInfo warningGlobalOptions={warningGlobalOptions} />
        )}
        {disabledSelectGlobalMesurement && (
          <OperationInfo
            disabledSelectGlobalMesurement={disabledSelectGlobalMesurement}
          />
        )}
      </div>
      <select
        id={name}
        className="select"
        {...register(type === "currency" ? "Currency" : name, {
          ...paramsRegister(),
          onChange: (e) => {
            if (listTableRowId) {
              handelOnChageMasterKey(e.target.value);
            }
          },
        })}
        disabled={disabledSelectGlobalMesurement}
      >
        {type === "currency"
          ? possible?.map((option, index) => {
              return (
                <option key={index} value={option}>
                  {option}
                </option>
              );
            })
          : options?.map((option, index) => {
              return (
                <option
                  key={index}
                  value={option}
                  disabled={disableOptions?.includes(option) ? true : false}
                >
                  {option}
                </option>
              );
            })}
      </select>
      {errors[name]?.message && (
        <p className="message">{errors[name]?.message}</p>
      )}
    </ContainerSelect>
  );
}

export default SelectInput;
