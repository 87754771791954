import { BiVideoRecording } from "react-icons/bi";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";
//ICONS
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ChangeCircleOutlinedIcon from "@mui/icons-material/ChangeCircleOutlined";
import { BiBrain } from "react-icons/bi";
import styled from "styled-components";
import TuneIcon from "@mui/icons-material/Tune";
import BackupTableIcon from "@mui/icons-material/BackupTable";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const userId = localStorage.getItem("userId");

export const ListItemTextStyle = {
  color: PRIMARY_COLOR,
};

export const iconStyle = {
  color: PRIMARY_COLOR,
  fontSize: "2.5rem",
};

export const iconStyle2 = {
  color: WHITE_COLOR,
  fontSize: "2.5rem",
};

export const iconStyle3 = {
  color: document.body.style.color,
  fontSize: "2.5rem",
};

export const VersionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 6rem 2rem;
  position: relative;

  h1 {
    font-size: 3.5rem;
  }

  h2 {
    font-size: 2.2rem;
    color: ${PRIMARY_COLOR};
  }

  p {
    text-align: right;
    margin-right: 2rem;
    padding: 3rem;
  }

  sup {
    font-size: 2.3rem;
  }
`;

export const LogoBar = styled.div`
  display: flex;
  background-size: cover;
  width: 220px;
  height: 220px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 40%;
  div {
    margin-bottom: 2rem;
    z-index: -1;
  }
`;

const dashboard = (url, name) => ({
  icon: <InsertChartOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const addUser = (url, name) => ({
  icon: (
    <ManageAccountsOutlinedIcon style={iconStyle} sx={iconStyle} data-tip />
  ),
  url,
});

const digitalTwin = (url, name) => ({
  icon: <ChangeCircleOutlinedIcon style={iconStyle} data-tip />,
  url,
});

const socrates = (url, name) => ({
  icon: <BiBrain style={iconStyle} data-tip />,
  url,
});
const preferences = (url, name) => ({
  icon: <TuneIcon style={iconStyle} data-tip />,
  url,
});

const video = (url, name) => ({
  icon: <BiVideoRecording style={iconStyle} data-tip />,
  url,
});

const aboutUs = (url, name) => ({
  icon: <InfoOutlinedIcon style={iconStyle} data-tip />,
  url: url,
});

const settingsWithSubcategories = {
  icon: <SettingsOutlinedIcon style={iconStyle} />,
  subcategories: {
    Objects: { icon: <SettingsOutlinedIcon />, url: "/object-configurations" },
    Components: {
      icon: <SettingsOutlinedIcon />,
      url: "/component-configurations",
    },
    Events: { icon: <SettingsOutlinedIcon />, url: "/config-actions-events" },
    Libraries: {
      icon: <SettingsOutlinedIcon />,
      url: "/library-configurations",
    },
  },
};

const platformSetUpWithSubcategories = {
  icon: <BackupTableIcon style={iconStyle} />,
  subcategories: {
    "Centralized Constants Management": {
      icon: <BackupTableIcon />,
      url: "/config-globalConstants",
    },
    "Unified Standards Catalog": {
      icon: <BackupTableIcon />,
      url: "/config-globalValues-Fields",
    },
    "Multi-Name Asset Configuration": {
      icon: <BackupTableIcon />,
      url: "/config-global-list-table",
    },
    "Measurement Data Integration": {
      icon: <BackupTableIcon />,
      url: "/config-global-measurement-table",
    },
  },
};

const preferencesWithSubcategories = {
  icon: <TuneIcon style={iconStyle} />,
  subcategories: {
    Tooltip: { icon: <TuneIcon />, url: "/config-preferences-tooltip" },
    Theme: { icon: <TuneIcon />, url: "/config-preferences-theme" },
    Font: { icon: <TuneIcon />, url: "/config-preferences-font" },
  },
};

export const SideBarItems = {
  "Administrador de decimetrix": {
    "Company managers": addUser("/admin-companies", "Company managers"),
    "Configuration No code development": settingsWithSubcategories,
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Administrador de compañía": {
    "KPIs & Dashboards": dashboard("/dashboard-admin", "Dashboard"),
    "Digital twin": digitalTwin("/digital-twin-admin", "Digital twin"),
    Users: addUser("/operators", "Operators"),
    "Configuration No code development": settingsWithSubcategories,
    "Platform set up": platformSetUpWithSubcategories,
    "UX/UI style preferences": preferencesWithSubcategories,
    "AI Socrates": socrates("/chatbot", "Socrates"),
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "IT Support": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    Users: addUser("/operators-user", "Operators"),
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Equipo de campo": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/upload-videos/${userId}`, "Video manager"),
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Equipo de oficina": {
    "KPIs & Dashboards": dashboard("/dashboard-user", "Dashboard"),
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "Video manager": video(`/field-video-manager`, "Video manager"),
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
  "Director de proyecto": {
    "Digital twin": digitalTwin("/digitalTwin", "Digital twin"),
    "About Us": aboutUs(
      "https://www.linkedin.com/company/decimetrix/",
      "About Us"
    ),
  },
};
