import { useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { config } from "config.js";
import {
  ContainerConfigGlobalValue,
  ContainerSelect,
  ContainerSelectGlobalList,
  ContainerValue,
  GlobalFieldValue,
} from "./CreateGlobalMeasurementSelectStyle";
import { Link } from "react-router-dom";
import { useFetchEventLibraries } from "hooks/fetchLibraries";
import { WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";

export const CreateGlobalMeasurementSelect = ({
  setField,
  setFields,
  field,
  setDialog,
  update,
}) => {
  const [dataGlobalMeasurementTables, setDataGlobalMeasurementTables] =
    useState([]);
  const [currentGlobalMeasurementTables, setCurrentGlobalMeasurementTables] =
    useState({});
  const [dataMeasurementCategories, setDataMeasurementCategories] = useState(
    []
  );
  const [currentMeasurementCategory, setCurrentMeasurementCategory] =
    useState(null);

  const [libraryEventsAssociate, setLibraryEventsAssociate] = useState([]);
  const [libraryEventSelected, setLibraryEventSelected] = useState(null);

  const [dataListTables, setDataListTables] = useState([]);

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const url = `${config.URL_BACKEND_MG}global-measurement-table?adminCompanyId=${adminCompanyId}`;
  const { data: globalMeasurementTables, error: errorGlobalMeasurementTables } =
    useSWR(url);
  const { data: dataLibrary, error: errorLibrary } = useFetchEventLibraries({
    id: null,
  });

  useEffect(() => {
    if (
      globalMeasurementTables &&
      !errorGlobalMeasurementTables &&
      globalMeasurementTables.length > 0
    ) {
      setDataMeasurementCategories(globalMeasurementTables[0]?.tableCategories);
      if (globalMeasurementTables[0]?.tableCategories.length > 0) {
        if (globalMeasurementTables[0]?.tableCategories.length > 0) {
          const existingTable =
            globalMeasurementTables[0]?.tableCategories.filter(
              (elm) => elm.groupTables.length > 0
            );
          if (existingTable.length > 0) {
            setDataGlobalMeasurementTables(existingTable[0]?.groupTables);
            setCurrentMeasurementCategory(existingTable[0]?._id);
            setCurrentGlobalMeasurementTables(
              existingTable[0]?.groupTables[0]._id
            );
            setField((current) => {
              if (
                !current.globalMeasurementTable.categoryId &&
                !current.globalMeasurementTable.tableId
              ) {
                return {
                  ...current,
                  options: [],
                  globalMeasurementTable: {
                    ...current.globalMeasurementTable,
                    categoryId: existingTable[0]?._id,
                    tableId: existingTable[0]?.groupTables[0]._id,
                  },
                };
              }
              return current;
            });
          }
          if (
            globalMeasurementTables[0]?.tableCategories[0]?.groupTables[0]?.rows
              .length !== 0
          ) {
            setDialog((current) => ({
              ...current,
              actions: {
                ...current.actions,
                agree: update ? "Update" : "Add",
              },
            }));
          } else {
            setDialog((current) => ({
              ...current,
              actions: {
                ...current.actions,
                agree: null,
              },
            }));
          }
        }
      }
    }
  }, [globalMeasurementTables, errorGlobalMeasurementTables, update]);

  useEffect(() => {
    if (dataLibrary && dataLibrary.length > 0) {
      const matchAssociateLibraries = dataLibrary.filter((lib) => {
        return (
          lib?.globalMeasurementTable?.measurementCategoryId ===
            currentMeasurementCategory &&
          lib?.globalMeasurementTable?.measurementTableId ===
            currentGlobalMeasurementTables
        );
      });
      setLibraryEventsAssociate(matchAssociateLibraries);
      if (matchAssociateLibraries.length > 0) {
        setDialog((current) => ({
          ...current,
          actions: {
            ...current.actions,
            agree: update ? "Update" : "Add",
          },
        }));
        setLibraryEventSelected(matchAssociateLibraries[0].id);
        setField((current) => {
          if (!current.globalMeasurementTable.associatedLibrary) {
            return {
              ...current,
              options: [],
              globalMeasurementTable: {
                categoryId: currentMeasurementCategory,
                tableId: currentGlobalMeasurementTables,
                associatedLibrary: matchAssociateLibraries[0].id,
                optionsSelectFromListTable: {
                  associationListCategoryId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListCategoryId,
                  associationListTableId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListTableId,
                },
              },
            };
          }
          return current;
        });
      } else {
        setDialog((current) => ({
          ...current,
          actions: {
            ...current.actions,
            agree: null,
          },
        }));
      }
    }
  }, [dataLibrary, errorLibrary, currentGlobalMeasurementTables, update]);

  const urlListTables = `${config.URL_BACKEND_MG}global-list-table?adminCompanyId=${adminCompanyId}`;
  const { data: globalListTables, error: errorGlobalListTables } =
    useSWR(urlListTables);

  useEffect(() => {
    if (
      !errorGlobalListTables &&
      globalListTables &&
      globalListTables.length > 0
    ) {
      setDataListTables(globalListTables[0].tableCategories);
    }
  }, [globalListTables, errorGlobalListTables]);

  const hasRunOnce = useRef(false);

  useEffect(() => {
    if (
      field.globalMeasurementTable &&
      Object.keys(field.globalMeasurementTable).length > 0 &&
      dataGlobalMeasurementTables?.length > 0 &&
      !hasRunOnce.current &&
      dataMeasurementCategories.length > 0
    ) {
      const dataCurrentCategory = dataMeasurementCategories?.find(
        (elm) => elm._id === field.globalMeasurementTable.categoryId
      );
      const dataTable = dataCurrentCategory?.groupTables?.find((item) => {
        return item._id === field.globalMeasurementTable.tableId;
      });
      if (dataTable) {
        setDataGlobalMeasurementTables(dataCurrentCategory?.groupTables);
        setCurrentMeasurementCategory(dataCurrentCategory._id);
        setCurrentGlobalMeasurementTables(dataTable._id);
      }
      hasRunOnce.current = true;
    }
  }, [dataGlobalMeasurementTables, field]);

  const handleSelectListCategory = (e) => {
    const categorySelected = dataMeasurementCategories.find(
      (elm) => elm._id === e.target.value
    );
    setCurrentMeasurementCategory(e.target.value);
    setDataGlobalMeasurementTables(categorySelected?.groupTables);
    setCurrentGlobalMeasurementTables(categorySelected?.groupTables[0]?._id);
    const matchAssociateLibraries = dataLibrary.filter((lib) => {
      return (
        lib?.globalMeasurementTable?.measurementCategoryId === e.target.value &&
        lib?.globalMeasurementTable?.measurementTableId ===
          categorySelected?.groupTables[0]._id
      );
    });
    if (matchAssociateLibraries.length > 0) {
      setField((current) => {
        return {
          ...current,
          options: [],
          globalMeasurementTable: {
            categoryId: e.target.value,
            tableId: categorySelected?.groupTables[0]._id,
            associatedLibrary: matchAssociateLibraries[0].id,
            optionsSelectFromListTable: {
              associationListCategoryId:
                matchAssociateLibraries[0].globalMeasurementTable
                  .associationListCategoryId,
              associationListTableId:
                matchAssociateLibraries[0].globalMeasurementTable
                  .associationListTableId,
            },
          },
        };
      });
      setFields((current) => {
        return current.map((elm) => {
          if (elm?.name === field.name) {
            return {
              ...elm,
              globalMeasurementTable: {
                categoryId: e.target.value,
                tableId: categorySelected?.groupTables[0]._id,
                associatedLibrary: matchAssociateLibraries[0].id,
                optionsSelectFromListTable: {
                  associationListCategoryId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListCategoryId,
                  associationListTableId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListTableId,
                },
              },
            };
          }
          return elm;
        });
      });
    }
  };

  const handelSelectGlobalTable = (e) => {
    e.preventDefault();
    setCurrentGlobalMeasurementTables(e.target.value);
    const currentSelectedTable = dataGlobalMeasurementTables.find(
      (elm) => elm._id === e.target.value
    );
    const matchAssociateLibraries = dataLibrary.filter((lib) => {
      return (
        lib?.globalMeasurementTable?.measurementCategoryId ===
          currentMeasurementCategory &&
        lib?.globalMeasurementTable?.measurementTableId ===
          currentSelectedTable._id
      );
    });
    if (matchAssociateLibraries.length > 0) {
      setField((current) => {
        return {
          ...current,
          options: [],
          globalMeasurementTable: {
            ...current.globalMeasurementTable,
            tableId: currentSelectedTable._id,
            associatedLibrary: matchAssociateLibraries[0].id,
            optionsSelectFromListTable: {
              associationListCategoryId:
                matchAssociateLibraries[0].globalMeasurementTable
                  .associationListCategoryId,
              associationListTableId:
                matchAssociateLibraries[0].globalMeasurementTable
                  .associationListTableId,
            },
          },
        };
      });
      setFields((current) => {
        return current.map((elm) => {
          if (elm?.name === field.name) {
            return {
              ...elm,
              globalMeasurementTable: {
                ...elm.globalMeasurementTable,
                tableId: currentSelectedTable._id,
                associatedLibrary: matchAssociateLibraries[0].id,
                optionsSelectFromListTable: {
                  associationListCategoryId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListCategoryId,
                  associationListTableId:
                    matchAssociateLibraries[0].globalMeasurementTable
                      .associationListTableId,
                },
              },
            };
          }
          return elm;
        });
      });
    }
  };

  const handelSelectEeventLibrary = (e) => {
    e.preventDefault();
    setLibraryEventSelected(e.target.value);
    const matchAssociateLibraries = dataLibrary.find((lib) => {
      return lib?.id === parseInt(e.target.value);
    });
    setField((current) => {
      return {
        ...current,
        options: [],
        globalMeasurementTable: {
          ...current.globalMeasurementTable,
          associatedLibrary: parseInt(e.target.value),
          optionsSelectFromListTable: {
            associationListCategoryId:
              matchAssociateLibraries.globalMeasurementTable
                .associationListCategoryId,
            associationListTableId:
              matchAssociateLibraries.globalMeasurementTable
                .associationListTableId,
          },
        },
      };
    });
    setFields((current) => {
      return current.map((elm) => {
        if (elm?.name === field.name) {
          return {
            ...elm,
            globalMeasurementTable: {
              ...elm.globalMeasurementTable,
              associatedLibrary: parseInt(e.target.value),
              optionsSelectFromListTable: {
                associationListCategoryId:
                  matchAssociateLibraries.globalMeasurementTable
                    .associationListCategoryId,
                associationListTableId:
                  matchAssociateLibraries.globalMeasurementTable
                    .associationListTableId,
              },
            },
          };
        }
        return elm;
      });
    });
  };

  return (
    <>
      <GlobalFieldValue>
        <ContainerConfigGlobalValue>
          <ContainerSelectGlobalList>
            <h2>Table of Global Measurement</h2>
            {dataMeasurementCategories &&
            dataMeasurementCategories.length > 0 ? (
              <>
                <label className="label">Select Category</label>
                <select
                  className="select"
                  value={currentMeasurementCategory}
                  onChange={(e) => handleSelectListCategory(e)}
                >
                  {dataMeasurementCategories?.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Category In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
            {dataGlobalMeasurementTables &&
            dataGlobalMeasurementTables.length > 0 ? (
              <>
                <label className="label">Global Measurement Table</label>
                <select
                  id={currentGlobalMeasurementTables?._id}
                  className="select"
                  value={currentGlobalMeasurementTables || ""}
                  onChange={handelSelectGlobalTable}
                >
                  {dataGlobalMeasurementTables.map((option, index) => {
                    return (
                      <option key={index} value={option._id}>
                        {option.name}
                      </option>
                    );
                  })}
                </select>
                {libraryEventsAssociate && libraryEventsAssociate.length > 0 ? (
                  <>
                    <label className="label">
                      {`Library Events created from ${
                        dataGlobalMeasurementTables.find(
                          (elm) => elm._id === currentGlobalMeasurementTables
                        ).name
                      }`}
                    </label>
                    <select
                      className="select"
                      value={libraryEventSelected}
                      onChange={handelSelectEeventLibrary}
                    >
                      {libraryEventsAssociate.map((option, index) => {
                        return (
                          <option key={index} value={option.id}>
                            {option.name}
                          </option>
                        );
                      })}
                    </select>
                  </>
                ) : (
                  <ContainerValue>
                    <label className="label">
                      First Create A New Event Library From Global Measurement
                      Table
                    </label>
                  </ContainerValue>
                )}
              </>
            ) : (
              <ContainerValue>
                <label className="label">
                  First Create Table In Module
                  <Link
                    to="/config-global-measurement-table"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      marginLeft: "5px",
                    }}
                  >
                    Global Measurement Table
                  </Link>
                </label>
              </ContainerValue>
            )}
          </ContainerSelectGlobalList>
          {dataLibrary && dataLibrary.length > 0 && libraryEventSelected && (
            <ContainerSelect>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label
                  className="label"
                  style={{
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  Selected Options
                </label>
              </div>

              {dataLibrary &&
                dataLibrary.length > 0 &&
                libraryEventSelected && (
                  <HeadersDisplay
                    libraryEventsAssociate={libraryEventsAssociate}
                    libraryEventSelected={libraryEventSelected}
                    dataListTables={dataListTables}
                    dataMeasurementCategories={dataMeasurementCategories}
                    dataLibrary={dataLibrary}
                  />
                )}
            </ContainerSelect>
          )}
        </ContainerConfigGlobalValue>
      </GlobalFieldValue>
    </>
  );
};

const HeadersDisplay = ({
  libraryEventsAssociate,
  libraryEventSelected,
  dataListTables,
  dataMeasurementCategories,
  dataLibrary,
}) => {
  const findAssociatedLibrary = () =>
    dataLibrary.find((lib) => lib?.id === parseInt(libraryEventSelected));

  const findTableAndGroup = () => {
    const libraryAssociate = findAssociatedLibrary();
    if (!libraryAssociate) return null;

    const category = dataListTables?.find(
      (elm) =>
        elm._id ===
        libraryAssociate.globalMeasurementTable.associationListCategoryId
    );
    if (!category) return null;

    const tableList = category.groupTables?.find(
      (elm) =>
        elm._id ===
        libraryAssociate.globalMeasurementTable.associationListTableId
    );
    const currentCategoryMeasurementTable = dataMeasurementCategories.find(
      (category) => {
        return (
          category._id ===
          libraryAssociate.globalMeasurementTable.measurementCategoryId
        );
      }
    );
    const selectedMeasurementTable =
      currentCategoryMeasurementTable.groupTables.find((table) => {
        return (
          table._id ===
          libraryAssociate.globalMeasurementTable.measurementTableId
        );
      });

    const masterKey = selectedMeasurementTable.dataColumns.find(
      (elm) => elm.masterKey
    );

    return tableList.rows.map((elm) => {
      return elm[masterKey.name];
    });
  };

  const group = findTableAndGroup();

  if (
    libraryEventsAssociate.length > 0 &&
    dataListTables?.length > 0 &&
    group
  ) {
    return group.map((elm, index) => {
      return (
        <div
          key={index}
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            margin: "5px",
            background: PRIMARY_COLOR,
            padding: "5px",
            color: WHITE_COLOR,
            borderRadius: "5px",
          }}
        >
          {elm}
        </div>
      );
    });
  } else {
    return (
      <ContainerValue>
        <label className="label">No Options</label>
      </ContainerValue>
    );
  }
};
