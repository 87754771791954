import ConfigIcon from "images/ConfigIcon";


const contentCardsSettingsAdminDecimetrix = [

  {
    id: 1,
    title: "CONFIGURATION: NO CODE DEVELOPMENT",
    image: <ConfigIcon />,
    description: "Create the data architecture, platform, configuration, customization without coding barriers",
    toNavigate: "/config-admin",
  }

];

export default contentCardsSettingsAdminDecimetrix;
