import axios from "axios";
import { config } from "config.js";

const updatePinView = async (body, idMongo) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}pinViews/${idMongo}`;
  const result = await axios.patch(url, body, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });
  const data = await result.data;
  return data;
};

export default updatePinView;
