import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import {
  CardContainer,
  HubCard,
  HubContainer,
  HubH2,
  HubP,
  HubWrapper,
} from "pages/AdminCompany/Hub/HubStyles";
import { infoHubAdminDeci } from "./infoHubAdmDeci";

function ModulesAdminDeci({ hideCards }) {
  const navigate = useNavigate();

  const [showContentCards, setShowContentCards] = useState(false);

  const handleCardClick = (item) => {
    if (item.title === "CONFIGURATION: NO CODE DEVELOPMENT") {
      setShowContentCards(!showContentCards);
      navigate("/config-admin");
      if (showContentCards) {
      }
    } else {
      navigate(item.navigate);
    }
  };

  return (
    <HubContainer className="background-2 border-top-1">
      <CardContainer>
        {!hideCards && (
          <>
            <HubWrapper style={{ justifyContent: "center" }}>
              {infoHubAdminDeci.map((item, index) => (
                <HubCard
                  className="background-card-color"
                  key={index}
                  onClick={() => handleCardClick(item)}
                >
                  <HubH2>{item.title}</HubH2>
                  {item.icon}
                  <HubP>{item.description}</HubP>
                </HubCard>
              ))}
            </HubWrapper>
          </>
        )}
      </CardContainer>
    </HubContainer>
  );
}

export default ModulesAdminDeci;
