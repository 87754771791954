import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import ClickToolTip from "components/ToolTips/ClickToolTip";
import {
  Container,
  Header,
  IconGroup,
  IconOption,
  IconOption2,
  FieldContent,
  FieldInfo,
  CheckboxContainer,
} from "./style";
import { WHITE_COLOR } from "utils/const";

export const FieldItems = ({ field, index, setState }) => {
  return (
    <Container>
      <Header>
        <IconGroup>
          <IconOption2>
            <p>Item #{index + 1}</p>
          </IconOption2>
        </IconGroup>

        <IconGroup>
          <IconOption
            onClick={() =>
              setState((current) => {
                return current.filter((elm) => elm.name !== field.name);
              })
            }
          >
            <CloseIcon />
          </IconOption>
        </IconGroup>
      </Header>

      <FieldContent background={WHITE_COLOR}>
        <label>Name</label>
        <p>{field.name}</p>
      </FieldContent>
      <FieldContent background={"#f2f2f2"}>
        <label>Display Name</label>
        <input
          type="text"
          value={field.alias}
          onChange={(e) =>
            setState((current) => {
              return current.map((elm) => {
                if (elm.name === field.name) {
                  return {
                    ...elm,
                    alias: e.target.value,
                  };
                }
                return elm;
              });
            })
          }
          placeholder={field.alias || field.name}
        />
      </FieldContent>

      <FieldInfo>
        <ClickToolTip
          title={
            <div>
              <p>
                <span>Name:</span> {field.name}
              </p>
              <p>
                <span>Alias:</span> {field.alias || field.name}
              </p>
              <p>
                <span>Type:</span> {field.dataType}
              </p>
              <p>
                <span>Required:</span> {field.require ? "Yes" : "No"}
              </p>
            </div>
          }
          placement="top"
        >
          <InfoIcon />
        </ClickToolTip>

        <CheckboxContainer>
          <p>
            <strong>Type: </strong>
            {field.dataType}
          </p>
          <div className="checkbox-group">
            <input
              type="checkbox"
              id={`required-checkbox-${index}`}
              checked={field.require}
              onChange={() =>
                setState((current) => {
                  return current.map((elm) => {
                    if (elm.name === field.name) {
                      return {
                        ...elm,
                        require: !elm.require,
                      };
                    }
                    return elm;
                  });
                })
              }
            />
            <label>Require</label>
          </div>
        </CheckboxContainer>
      </FieldInfo>
    </Container>
  );
};
