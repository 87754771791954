// Required modules
import axios from "axios";
import { format } from "date-fns/esm";
import uploadFileToS3 from "services/uploadFileToS3FromPg";
import { config } from "config.js";
import ShowLinePreview from "helpers/showLinePreview";
import { roles } from "utils/roles";
import { fromLibrary } from "./dataConfigAdmin";
import {
  findTypeObjects,
  updateTypePointObjects,
} from "services/TypePointObjects";
import {
  findTypePointEvents,
  updateTypePointEvents,
} from "services/typePointEvent";
import {
  findTypePointComponents,
  updateTypePointComponents,
} from "services/typePointComponent";
import { findTypeLineObjects, updateTypeLineObjects } from "services/typeLines";

// Class to use in any config admin file
class ConfigAdmin {
  constructor() {
    this.id = null;
    this.endpoint = "";
    this.endpointFetch = "";
    this.createdAt = "createdAt";
    this.libraryId = null;
    this.pointLibraryEventId = null;
    this.pointLibraryComponentId = null;
    this.data = [];
    this.rows = [];
    this.typeElementsSelect = [];
    this.adminCompanyId = null;
    this.headersTable = {};
    this.keyValuesTable = {};
    this.fieldForm = {};
    this.changeFormForSpecificValues = {};
    this.setRows = {};
    this.setButtons = {};
    this.setOpenDialog = () => {};
    this.setContentDialog = () => {};
    this.clearFields = {};
    this.onChangeFields = {};
    this.setSeeForm = {};
    this.formRef = {};
    this.createCallback = null;
    this.createTypeElements = async () => {};
    this.updateCallback = null;
    this.setLoadingCreate = () => {};
    this.setLoadingDelete = () => {};
    this.buttonCreate = {
      label: "Create",
      type: "create",
      handleClick: () => this.create(),
    };
    this.buttonUpdate = {
      label: "Update",
      type: "update",
      handleClick: () => this.update(),
    };
    this.buttonDelete = {
      label: "Delete",
      type: "delete",
      handleClick: () => this.delete(),
    };
    this.select = null;
    this.messageShow = "";
    this.getAlertStatus = () => ({
      open: false,
      severity: "",
    });
    this.fromGlobalMeasurementTable = {};
    this.fromLibrary = null;
  }

  isValidForm() {
    const valid = Object.values(this.fieldForm).every((field) => {
      const {
        regularExpression,
        name,
        validate: lastValidate,
        value: lastValue,
        type,
      } = field;
      let validate = lastValidate;
      let value = lastValue;

      if (type === "image" && typeof value !== "string") value = value?.name;

      if (regularExpression) {
        if (!regularExpression.test(value)) {
          this.onChangeFields({ target: { name, validate: "false" } });
          validate = "false";
        }
      }

      if (!validate) return true; // In case of id
      return validate === "true";
    });
    return valid;
  }

  async createBody() {
    const body = {};
    const exception = [
      "id",
      "created_at",
      "createdAt",
      undefined,
      "undefined",
      "adminCompanyId",
      "libraryId",
      "pointLibraryId",
      "lineLibraryId",
      "regionLibraryId",
      "pointLibrary",
      "pointLibraryEventId",
      "point_library_event_id",
      "pointLibraryEvent",
      "updatedAt",
      "typeLibrary",
    ];
    await Promise.all(
      Object.values(this.fieldForm).map(async (field) => {
        let { type, value, name } = field;
        if (type === "range") value = parseFloat(value);
        if (exception.includes(name)) return;
        if (type === "image" && typeof value !== "string") {
          const url = await uploadFileToS3(value, "8");
          body[name] = url;
          return;
        }
        body[name] = value;
      })
    );
    return body;
  }

  async create() {
    const valid = this.isValidForm();
    if (!valid) return;
    this.setLoadingCreate(true);
    let body = await this.createBody();
    if (this.libraryId) body = { ...body, libraryId: parseInt(this.libraryId) };
    if (this.pointLibraryEventId) {
      body = {
        ...body,
        pointLibraryEventId: parseInt(this.pointLibraryEventId),
      };
      this.setPointLibraryEventId(null);
    }
    if (this.pointLibraryComponentId) {
      body = {
        ...body,
        pointLibraryComponentId: parseInt(this.pointLibraryComponentId),
      };
      delete body.fields;
      this.setPointLibraryComponentId(null);
    }
    if (this.select) {
      body = {
        ...body,
        typeLibraryId: parseInt(this.select),
        globalMeasurementTable: this.fromGlobalMeasurementTable,
      };
    }
    try {
      const role = localStorage.getItem("role");
      const res = await axios.post(
        `${config.URL_BACKEND_PG}${this.endpoint}?libraryId=${
          body.pointLibraryEventId ||
          body.pointLibraryComponentId ||
          body.libraryId
        }`,
        { ...body },
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      const data = await res.data;
      this.getAlertStatus = () => ({
        open: true,
        severity: "success",
      });
      this.messageShow = "It has been successfully created";
      if (this.createCallback) this.createCallback(data);
      this.data = [...this.data, data];
      const newRows = this.addFormatToDateInArrayOfObjects(
        [...this.rows, data],
        this.createdAt,
        this.updatedAt
      );
      if (
        data &&
        this.typeElementsSelect.length > 0 &&
        role === roles.decimetrixAdmin
      ) {
        await Promise.all(
          this.typeElementsSelect.map(async (elm) => {
            await this.createTypeElements({
              body: {
                ...elm,
                libraryId: data.id,
              },
              adminCompanyId: parseInt(this.adminCompanyId),
            });
          })
        );
      }
      this.rows = [...newRows];
      this.setRows(() => [...newRows]);
      this.initialForm();
    } catch (error) {
      this.getAlertStatus = () => ({
        open: true,
        severity: "error",
      });
      this.messageShow = "Error creating";
      const errorMessage = error?.response?.data?.error;
      const status = error?.response?.status;
      this.setOpenDialog(true);
      this.setContentDialog((currentDialog) => ({
        ...currentDialog,
        title: `Error ${status}` || "Error",
        description: errorMessage || `Error ${status}`,
      }));
      this.initialForm();
    }
    this.setLoadingCreate(false);
  }

  async update() {
    const valid = this.isValidForm();
    if (!valid) return;
    this.setLoadingCreate(true);
    let body = await this.createBody();
    if (this.select) {
      body = {
        ...body,
        typeLibraryId: parseInt(this.select),
      };
    }
    try {
      if (this.fromLibrary === fromLibrary.pointObjects) {
        const typeObjects = await findTypeObjects({ libraryId: this.id });
        const promises = typeObjects.map(async (typeObject) => {
          // The date of the object types in the selected library needs to be updated, for this I send the edit request with the same name, this will update the date of each type.
          return await updateTypePointObjects({
            id: typeObject.id,
            body: {
              name: typeObject.name,
            },
          });
        });

        await Promise.all(promises);
      }

      if (this.fromLibrary === fromLibrary.pointEvents) {
        const typeEvents = await findTypePointEvents({ libraryId: this.id });

        const promises = typeEvents.map(async (typeEvent) => {
          return await updateTypePointEvents({
            id: typeEvent.id,
            body: {
              name: typeEvent.name,
            },
          });
        });

        await Promise.all(promises);
      }

      if (this.fromLibrary === fromLibrary.pointComponents) {
        const typeComponents = await findTypePointComponents({
          libraryId: this.id,
        });

        const promises = typeComponents.map(async (typeComponent) => {
          return await updateTypePointComponents({
            id: typeComponent.id,
            body: {
              name: typeComponent.name,
            },
          });
        });

        await Promise.all(promises);
      }

      if (this.fromLibrary === fromLibrary.lineObjects) {
        const typeLines = await findTypeLineObjects({ libraryId: this.id });

        const promises = typeLines.map(async (typeLine) => {
          return await updateTypeLineObjects({
            id: typeLine.id,
            body: {
              type: typeLine.type,
            },
          });
        });

        await Promise.all(promises);
      }

      const res = await axios.put(
        `${config.URL_BACKEND_PG}${this.endpoint}/${this.id}`,
        body,
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      // this.setLoadingCreate(true);
      const newData = res.data;
      this.getAlertStatus = () => ({
        open: true,
        severity: "success",
      });
      this.messageShow = "It has been successfully updated";
      this.data = this.data.map((data) => {
        if (data.id === this.id) {
          return newData;
        }
        return data;
      });
      if (this.updateCallback) this.updateCallback(newData);
      const newRows = this.addFormatToDateInArrayOfObjects(
        [...this.data],
        this.createdAt,
        this.updatedAt
      );
      this.rows = [...newRows];
      this.setRows(() => [...newRows]);
      // this.setLoadingCreate(false);
      this.initialForm();
    } catch (error) {
      this.getAlertStatus = () => ({
        open: true,
        severity: "error",
      });
      this.messageShow = "Error updating";
      const errorMessage = error?.response?.data?.message;
      const status = error?.response?.status;
      this.setOpenDialog(true);
      this.setContentDialog((currentDialog) => ({
        ...currentDialog,
        title: `Error ${status}` || "Error",
        content: errorMessage || `Error ${status}`,
      }));
      this.initialForm();
    }
    this.setLoadingCreate(false);
  }

  initialForm(dontSet) {
    this.clearFields();
    this.setButtons([this.buttonCreate]);
    this.id = null;
    if (dontSet) return;
    this.setSeeForm(() => false);
  }

  toggleForm() {
    this.initialForm(true);
    this.setSeeForm((current) => {
      return !current;
    });
  }

  async delete() {
    this.setLoadingDelete(true);

    try {
      await axios.delete(
        `${config.URL_BACKEND_PG}${this.endpoint}/${this.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.getToken()}`,
          },
        }
      );
      this.getAlertStatus = () => ({
        open: true,
        severity: "success",
      });
      this.messageShow = "It has been successfully deleted";
      const newRows = this.rows.filter((row) => row.id !== this.id);
      this.rows = [...newRows];
      this.setRows(() => [...newRows]);
      this.initialForm();
    } catch (error) {
      this.getAlertStatus = () => ({
        open: true,
        severity: "error",
      });
      this.messageShow = "Error while deleting";
      const errorMessage = error?.response?.data?.message;
      const status = error?.response?.status;
      this.setOpenDialog(true);
      this.setContentDialog((currentDialog) => ({
        ...currentDialog,
        title: `Error ${status}` || "Error",
        description: `${errorMessage}` || `Error ${status}`,
      }));
      this.initialForm();
    }
    this.setLoadingDelete(false);
  }

  async fetchData() {
    try {
      const { data } = await axios.get(
        `${config.URL_BACKEND_PG}${this.endpointFetch}`,
        { headers: { Authorization: `Bearer ${this.getToken()}` } }
      );
      this.data = [...data];
      this.rows = this.addFormatToDateInArrayOfObjects(
        [...data],
        this.createdAt,
        this.updatedAt
      );
      this.setRows(this.rows);
    } catch (error) {
      return error.message;
    }
  }

  handlerClickIntoTable(id) {
    const selected = this.data.find((data) => data.id === id);
    this.clearFields();
    this.changeFormForSpecificValues(selected);
    this.setButtons([this.buttonUpdate, this.buttonDelete]);
    this.id = id;
    this.setSeeForm(() => true);
  }

  async handlerClickToggleLibrary(id) {
    const selected = this.data.find((data) => data.id === id);
    const { name, icon, description, fields, typeLibraryId, available } =
      selected;
    this.isValidForm(true);
    this.id = id;
    this.fieldForm = {
      name: {
        value: name,
        name: "name",
      },
      icon: {
        value: icon,
        name: "icon",
      },
      description: {
        value: description,
        name: "description",
      },
      fields: {
        value: fields,
        name: "fields",
      },
      typeLibraryId: {
        value: typeLibraryId,
        name: "typeLibraryId",
      },
      available: {
        value: available ? false : true,
        name: "available",
      },
    };
    this.update();
  }

  formtSmallStringOfObject = ({ ...objec }) => {
    Object.keys(objec).map((key) => {
      if (!objec[key]) return false;
      let value = objec[key];
      // const lengthValue = value.length;
      const isImage =
        typeof value === "string" &&
        value.includes("https://") &&
        (value.includes(".jpg") || value.includes(".png"));
      if (isImage) {
        objec[key] = (
          <img
            src={value}
            alt={value}
            style={{ width: 35, height: 35, objectFit: "cover" }}
          />
        );
        return false;
      }
      // if (lengthValue > 10) {
      //   objec[key] = value.slice(0, Math.min(lengthValue, 50));
      //   return false;
      // }
      return false;
    });
    return objec;
  };

  addFormatToDateInArrayOfObjects(
    arrayOfObjects,
    createdAt = "createdAt",
    updatedAt = "updatedAt"
  ) {
    const newArrayOfObjects = arrayOfObjects
      .map((row) => {
        const createdAtRow = row[createdAt] || null;
        const updatedAtRow = row[updatedAt] || null;

        let newCreatedAt = format(new Date(createdAtRow), "MMMM dd',' yyyy");
        let newUpdatedAt = format(new Date(updatedAtRow), "MMMM dd',' yyyy");

        row = this.formtSmallStringOfObject(row);
        const getLinePreview = (line) => {
          return line.color &&
            line.dasharrayWidth &&
            line.dasharrayPixels !== undefined ? (
            <ShowLinePreview
              color={row.color}
              width={row.dasharrayWidth}
              separator={row.dasharrayPixels}
              text={false}
            />
          ) : null;
        };
        const newRow = {
          ...row,
          linePreview: getLinePreview(row),
          [createdAt]: newCreatedAt,
          [updatedAt]: newUpdatedAt,
        };
        return newRow;
      })
      .sort((a, b) => {
        return new Date(a[createdAt]) - new Date(b[createdAt]);
      })
      .reverse();

    return newArrayOfObjects;
  }

  setDialog(setOpenDialog, setContentDialog) {
    this.setOpenDialog = setOpenDialog;
    this.setContentDialog = setContentDialog;
  }

  // Set endpoint for fetch data
  setEndpoint(endpoint) {
    this.endpoint = endpoint;
  }

  // Set headers for table
  setHeadersTable(headers) {
    this.headersTable = headers;
  }

  // Set key values for table
  setKeyValuesTable(keyValues) {
    this.keyValuesTable = keyValues;
  }

  // Set field form for form
  setFieldForm(fieldForm) {
    this.fieldForm = fieldForm;
  }

  // Set change form for specific values
  setChangeFormForSpecificValues(changeFormForSpecificValues) {
    this.changeFormForSpecificValues = changeFormForSpecificValues;
  }

  // Set buttons
  setSetButtons(setButtons) {
    this.setButtons = setButtons;
  }

  setSetLoadingCreate(setLoadingCreate) {
    this.setLoadingCreate = setLoadingCreate;
  }

  setSetLoadingDelete(setLoadingDelete) {
    this.setLoadingDelete = setLoadingDelete;
  }

  // Set rows
  setSetRows(setRows) {
    this.setRows = setRows;
  }

  setsetTypeElementsSelect(typeElementsSelect) {
    this.typeElementsSelect = typeElementsSelect;
  }

  setsetAdminCompanyId(adminCompanyId) {
    this.adminCompanyId = adminCompanyId;
  }

  // Set clear field function
  setClearFields(clearFields) {
    this.clearFields = clearFields;
  }

  // Set created at field
  setCreatedAtField(createdAt) {
    this.createdAt = createdAt;
  }

  // Set on change fields function
  setOnChangeFields(onChangeFields) {
    this.onChangeFields = onChangeFields;
  }

  // Set setSeeForm function
  setSetSeeForm(setSeeForm) {
    this.setSeeForm = setSeeForm;
  }

  // Set form ref
  setFormRef(formRef) {
    this.formRef = formRef;
  }

  // Set end point fetch
  setEndpointFetch(endpointFetch) {
    this.endpointFetch = endpointFetch;
  }

  // Set library id
  setLibraryId(libraryId) {
    this.libraryId = libraryId;
  }

  // Set create callback
  setCreateCallback(createCallback) {
    this.createCallback = createCallback;
  }

  setCreateTypeElements(createTypeElements) {
    this.createTypeElements = createTypeElements;
  }

  // Set update callback
  setUpdateCallback(updateCallback) {
    this.updateCallback = updateCallback;
  }

  setPointLibraryEventId(pointLibraryEventId) {
    this.pointLibraryEventId = pointLibraryEventId;
  }

  setPointLibraryComponentId(pointLibraryComponentId) {
    this.pointLibraryComponentId = pointLibraryComponentId;
  }

  setSelect(select) {
    this.select = select;
  }

  // Get headers for table
  getHeadersTable() {
    return this.headersTable;
  }

  // Get key values for table
  getKeyValuesTable() {
    return this.keyValuesTable;
  }

  // Get field form for form
  getFieldForm() {
    return this.fieldForm;
  }

  // Get token
  getToken() {
    return localStorage.getItem("token");
  }

  // Get rows for table
  getRows() {
    return this.rows;
  }

  setSetFromGlobalMeasurementTable(currentGlobalMeasurementTable) {
    this.fromGlobalMeasurementTable = currentGlobalMeasurementTable;
  }
  setFromLibrary(library) {
    this.fromLibrary = library;
  }
}

export default ConfigAdmin;
