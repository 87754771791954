import { createGlobalStyle } from "styled-components";
import {
  BACKGROUND_CARD_DARK,
  BACKGROUND_DARK,
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  BORDER_DARK,
  BORDER_GREY,
  LOGO_DARK_TRANS,
  PRIMARY_COLOR,
  WHITE_BACKGROUND,
  WHITE_COLOR,
  SECOND_COLOR,
  BACKGROUND_THIRD_DARK,
  BLACK_COLOR,
  BACKGROUND_FOURTH_DARK,
  BACKGROUND_SECOND_GREY,
  WHITE_COLOR_THIRD,
} from "utils/const";

export const changeFontFamily = (fontFamily) => {
  document.body.style.fontFamily = fontFamily;
};

export const changeFontColor = (color) => {
  document.body.style.color = color;
};

export const fontFamilies = {
  default: "'Montserrat', 'Noto Sans'",
  courier: "'Courier New', Courier, monospace",
  verdana: "'Verdana', Geneva, Tahoma, sans-seriff",
  gill: "'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif'",
  aptos:
    "Aptos, Inter, system-ui, -apple-system, BlinkMacSystemFont, sans-serif",
};

export const Styles = createGlobalStyle`

//DEFAULT 

:root {
  --font-family: "Montserrat", "Noto Sans";
}

    * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;    
    }

    body {
  font-family: var(--font-family);
}

    html {
    font-size: 62.5%;
    }

    .background-default-color{
    background: ${BACKGROUND_DARK};   
    }

    .whiteColo1{
    color: ${WHITE_BACKGROUND};
    }



// LIGHT THEME
#light{
    --background-color1: ${WHITE_BACKGROUND};
    --background-color2: ${PRIMARY_COLOR};
    --background-color3: ${WHITE_BACKGROUND};
    --background-fill1: ${PRIMARY_COLOR};
    --background-fill2: ${WHITE_BACKGROUND};


    .background-1{
    background-color: ${WHITE_BACKGROUND};
    }

    .background-2{
    background-color: ${BACKGROUND_GREY};
    }

    .background-3{
    /* background-color: ${PRIMARY_COLOR}; */
    background-color: var(--background-primary_color);
    }
    
    .background-table-color{
    background-color: #9bc4cb;
    }

    .background-row-1{
    background-color: #eeeeee;
    }

    .background-row-2{
        background: ${WHITE_BACKGROUND};
    }

    .border-color-1{
    border: 1px solid ${BORDER_GREY};
    }

    .border-radius-1{
        border-radius: 5px;
    }

    .border-top-1{
    border-top: 1px solid ${BORDER_GREY};
    }

    .color2{
    color: ${WHITE_COLOR_THIRD};
    }

    .colorwhite{
    color: ${BACKGROUND_SECOND_GREY};
    }

    .colorblack{
    color: ${BLACK_COLOR};
    }

    .button-hover-1{
        background-color: ${BACKGROUND_GREY};

    &:hover {
    background-color: var(--background-trans_color);
        }
    }

    header {
        /* background-color: ${PRIMARY_COLOR}; */
        background-color: var(--background-primary_color);
        fill: var(--background-primary_color);
    }

    p {
      font-size: 22.4;
    }


    h1, h2 {
    /* color: ${PRIMARY_COLOR}; */
    color: var(--color-titles);
    }

    .no-shadow{
      box-shadow: none;
    }
}


// DARK THEME
#dark{
    --background-color1: ${BACKGROUND_DARK};
    --background-color2: ${BACKGROUND_SECOND_DARK};
    --background-color3: ${BACKGROUND_SECOND_DARK};
    --background-fill1: ${BACKGROUND_SECOND_DARK};
    --background-fill2: ${BACKGROUND_DARK};

    .background-1{
    background: ${BACKGROUND_DARK};
    }

    .background-2{
    background-color: ${BACKGROUND_SECOND_DARK};
    }

    .background-3{
    background-color: ${BACKGROUND_SECOND_DARK};
    }

    .border-top-1{
    border-top: 1px solid ${BORDER_DARK};
    }

    .border-color-1{
    border: 1px solid ${BORDER_DARK};
    }

    .border-radius-1{
        border-radius: 5px;
    }

    .color1{
    color: ${BACKGROUND_SECOND_GREY};
    }

    .color2{
    color: #555555;
    }

    .color3{
    color: ${SECOND_COLOR};
    }

    .colorimg{
    fill: ${BACKGROUND_SECOND_GREY};
    }

    .colorwhite{
    color: ${BACKGROUND_SECOND_GREY};
    }

    .colorblack{
    color: ${BLACK_COLOR};
    }

    .background-table-color{
    background-color: var(--background-secondary_color);
    }

    .background-row-1{
    background-color: ${BACKGROUND_THIRD_DARK}
    }

    .background-row-2{
    background: #464646;
    }

    .logoIcon-color{
    fill: ${LOGO_DARK_TRANS};
    }

    .background-card-color{
        background-color: ${BACKGROUND_CARD_DARK};
    border: 1px solid ${BORDER_DARK};
    }

    header{
    background-color: ${BACKGROUND_FOURTH_DARK};
    }

    .module-color{
        color: ${BORDER_DARK};
    }

    .button-hover-1{
        background-color: #464646;

    &:hover {
    background-color: #6e6e6e92;
        }
    }

    b, span {
    color: ${WHITE_COLOR};
    }
    
    p{
    color: white;
    /* font-family: "Noto Sans"; */
    font-size: 22.4;
    }

    h1, h2{
    color: white;
    }

    }


  p {
    font-size:1.4rem;
}

main {
    max-width: 1200px;
    width: 90%;
    margin: auto;
    padding: 40px;
    /* font-family: 'Montserrat', sans-serif; */
    }

body {
    background: ${WHITE_BACKGROUND};
    /* font-family: 'Montserrat', sans-serif; */
}

.ML__keyboard{
    --keyboard-zindex: 3000;
    --keycap-max-width: 200px;
    --keyboard-toolbar-font-size: 2.5rem;
    --keycap-gap: 5px;
    --keycap-border: 1px solid;
}
`;

export function StyleColor(theme) {
  return theme === "light" ? WHITE_BACKGROUND : BACKGROUND_SECOND_DARK;
}

export function StyleColor2(theme) {
  return theme === "light" ? document.body.style.color : WHITE_COLOR;
}

export function StyleColor3(theme) {
  return theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK;
}
