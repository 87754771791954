import uploadFileToS3 from "services/uploadFileToS3FromPg";
import uploadFieldVideo from "services/uploadFieldVideo";
import { updateAttributesEventMg } from "services/updateAttributesMg";
// helpers
import videosIsLoaded from "./videosIsLoaded";
import videosIsMatched from "./videosIsMatched";

const syncVideoWithEvent = async ({
  userId,
  videos,
  eventsWithoutVideo,
  isUpload,
  fieldName,
  fieldFile,
  videosDetails,
  setVideosDetails,
  setIsSyncVideos,
}) => {
  const videosWithoutEvent = [...videos];
  eventsWithoutVideo &&
    Promise.all(
      eventsWithoutVideo.map(async (pointEvent) => {
        Promise.all(
          videos.map(async (video) => {
            const { attributes } = pointEvent;
            const nameFile = attributes?.find(
              (attribute) => attribute.name === fieldName.name
            );
            const pointEventMongoId = pointEvent?.mongoId;
            if (video.name === nameFile.value) {
              const index = videosWithoutEvent.indexOf(video);
              videosWithoutEvent.splice(index, 1);
              if (isUpload) {
                const urlFile = await uploadFileToS3(video, "5");
                if (urlFile === null) alert("Couldn't isUpload'");
                await updateAttributesEventMg(pointEventMongoId, [
                  {
                    name: fieldFile?.name,
                    value: urlFile,
                    alias: fieldFile?.alias,
                  },
                ]);

                setVideosDetails((current) =>
                  videosIsLoaded(current, nameFile.value)
                );
              } else {
                setIsSyncVideos(true);
              }
            }
          })
        );
      })
    );
  Promise.all(
    videosWithoutEvent.map(async (video) => {
      if (isUpload) {
        const urlFile = await uploadFileToS3(video, "5");
        if (urlFile === null) alert("Couldn't isUpload'");
        await uploadFieldVideo({
          userId,
          name: video.name,
          url: urlFile,
          date: new Date(),
          size: parseFloat((video.size / 1000000).toFixed(3)),
          type: video.type,
          updatedDate: video.updatedDate,
        });
        setVideosDetails((current) => videosIsLoaded(current, video.name));
      } else {
        setIsSyncVideos(true);
      }
    })
  );
  const videosMatch = videosIsMatched(videosDetails, videosWithoutEvent);
  setVideosDetails(videosMatch);
};

export default syncVideoWithEvent;
