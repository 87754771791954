// React
import { useContext, useState } from "react";
// Components
import CustomButton from "components/Buttons/CustomButton";
// Style
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import BlockIcon from "@mui/icons-material/Block";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useTheme } from "@mui/material/styles";
import { HeadModal } from "./DialogsStyles";
import { AiFillCloseCircle } from "react-icons/ai";
import {
  BACKGROUND_GREY,
  BACKGROUND_SECOND_DARK,
  WHITE_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  SECOND_WARNING_COLOR,
  SECOND_HOVER_WARNING_COLOR,
} from "utils/const";
import { ThemeContext } from "App";

/**
 * Renders an open dialog component.
 *
 * @param {object} props - The properties for the component.
 * @param {boolean} props.openDialog - Determines if the dialog is open.
 * @param {function} props.setOpenDialog - Sets the open state of the dialog.
 * @param {function} props.execute - Executes a function.
 * @param {object} props.content - The content of the dialog.
 * @param {string} props.maxWidth - The maximum width of the dialog.
 * @param {string} props.minWidth - The minimum width of the dialog.
 * @return {JSX.Element} The rendered dialog component.
 */
const OpenDialog = (props) => {
  const {
    openDialog,
    setOpenDialog,
    execute,
    content,
    maxWidth,
    minWidth,
    height = "auto",
    toExecuteOnClose = undefined,
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    showCloseButton = !disableBackdropClick,
    isWarning = false,
    isBlock = false,
  } = props;

  const open = openDialog;
  const theme2 = useTheme();
  const fullScreen = useMediaQuery(theme2.breakpoints.down("md"));
  const [isLoadAgree, setIsLoadAgree] = useState(false);
  const { theme } = useContext(ThemeContext);

  const handleClose = (event, reason) => {
    if (disableBackdropClick && reason === "backdropClick") {
      return;
    }

    if (disableEscapeKeyDown && reason === "escapeKeyDown") {
      return;
    }

    setTimeout(() => {
      setOpenDialog(false);
    }, 50);
    if (toExecuteOnClose !== undefined) toExecuteOnClose();
  };

  const handleExecute = async () => {
    setIsLoadAgree(true);
    await execute();
    setIsLoadAgree(false);
  };

  return (
    <div
      style={{
        position: "relative",
        width: "auto",
      }}
    >
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown={disableEscapeKeyDown}
        aria-labelledby="responsive-dialog-title"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              maxWidth: maxWidth || "1900px",
              minWidth: minWidth || "1000px",
              height: height,
              overflowY: "hidden",
              background:
                theme === "light" ? BACKGROUND_GREY : BACKGROUND_SECOND_DARK,
            },
          },
        }}
      >
        <HeadModal isWarning={isWarning} isBlock={isBlock}>
          <div className="title">
            {isWarning ? (
              <WarningAmberIcon />
            ) : isBlock ? (
              <BlockIcon />
            ) : (
              <img
                src="https://icons-greendragon.s3.us-east-2.amazonaws.com/other/d-decimetrix.png"
                alt=""
                className="neurona"
              />
            )}
            <span className="titleHeadModal">{content.title}</span>
          </div>
          {showCloseButton && (
            <Button onClick={() => handleClose(null, "buttonClick")}>
              <AiFillCloseCircle
                style={{ color: WHITE_COLOR, fontSize: 21, marginBottom: -4 }}
              />
            </Button>
          )}
        </HeadModal>
        <DialogTitle id="responsive-dialog-title"></DialogTitle>
        <DialogContent
          sx={{
            overflowY: "none",
            color: theme === "light" ? document.body.style.color : WHITE_COLOR,
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              alignContent: "center",
              justifyContent: "center",
              justifyItems: "center",
              fontSize: "1.5rem",
              minWidth: `calc(${minWidth} - 20%)`,
            }}
          >
            {content.description}
          </div>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {content.disagree && (
            <CustomButton
              isLoad={false}
              text={content.disagree}
              onClick={() => handleClose(null, "buttonClick")}
              primaryColor={isWarning ? SECOND_WARNING_COLOR : "var(--background-primary_color)"}
              secondaryColor={
                isWarning ? SECOND_HOVER_WARNING_COLOR : "var(--background-secondary_color)"
              }
            />
          )}
          {content.agree && (
            <CustomButton
              isLoad={isLoadAgree}
              text={content.agree}
              onClick={handleExecute}
              primaryColor={isWarning ? SECOND_WARNING_COLOR : "var(--background-primary_color)"}
              secondaryColor={
                isWarning ? SECOND_HOVER_WARNING_COLOR : "var(--background-secondary_color)"
              }
            />
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default OpenDialog;
