import axios from "axios";
import { config } from "config.js";

const updateSyncGlobalMeasurementTable = async ({
  id,
  categoryId,
  tableId,
  body,
}) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.patch(
      `${config.URL_BACKEND_MG}global-measurement-table/update-sync/${id}?categoryId=${categoryId}&tableId=${tableId}`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default updateSyncGlobalMeasurementTable;
