import axios from "axios";
import { config } from "config.js";

const saveGlobalListTable = async (body) => {
  try {
    const token = localStorage.getItem("token");
    const res = await axios.post(
      `${config.URL_BACKEND_MG}global-list-table`,
      body,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    const data = await res.data;
    return { data, res };
  } catch (error) {
    return { error };
  }
};

export default saveGlobalListTable;
