import Layout from "components/Layout/AdminDecimetrix";
import {
  InformationSection,
  Content,
  ConfigurationSection,
  ConfigLibrary,
  ConfigConstant,
  ContentButton,
  ContentData,
  InformationShare,
  Form,
  Title,
  ContentTablesTags,
  TableTag,
  ContentDataTag,
  Columns,
  Column,
  Space,
  GlobalContent,
  Divider,
  SelectCategoriesStyles,
  ContainerSelect,
  customSelectStyle,
  ContainerInput,
} from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import CustomButton from "components/Buttons/CustomButton";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import TextInput from "components/Forms/GenericInput";
import useSWR from "swr";
import { useSWRConfig } from "swr";
import { config } from "config.js";
import {
  DELETE_COLOR,
  HOVER_DELETE_COLOR,
  VISIBILITY_OFF_ICON,
} from "utils/const";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import OpenDialog from "components/Dialogs/OpenDialog";
import { useContext } from "react";
import { ThemeContext } from "App";
import { StyleColor2, StyleColor3 } from "Styles";
import SelectInput from "components/Forms/SelectInput";
import GenericTable from "components/Tables/GenericTable/GenericTable";
import { FROM_TABLE } from "utils/constStrings";
import Select from "react-select/creatable";
import CancelIcon from "@mui/icons-material/Cancel";
import saveGlobalListTable from "services/GlobalListTable/saveGlobalListTablet";
import updateGlobalListTable from "services/GlobalListTable/updateGlobalListTable";
import { v4 as uuidv4 } from "uuid";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Tooltip from "@mui/material/Tooltip";
import uploadCSVGlobalListTable from "./uploadCSVGlobalListTable";
import Papa from "papaparse";
import {
  ModalContainer,
  ModalContent,
  ModalErrorList,
  ModalHeadeError,
  ModalHeader,
  SuccessMessage,
} from "../GlobalValuesFields/styles";
import { LinearProgress, Typography, Box } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { useNavigate } from "react-router-dom";
import { ShowAllButton2 } from "../Preferences/preferencesstyles";

const ProgressBar = ({ progress }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <LinearProgress variant="determinate" value={progress} />
      <Typography variant="body2" color="textSecondary">
        {`Progress: ${Math.round(progress)}%`}
      </Typography>
    </Box>
  );
};

const saveCSVTemplateFile = (data, fileType) => {
  try {
    // Verificamos que tengamos datos
    if (!data || !data.length) {
      throw new Error("No hay datos para procesar");
    }

    // Obtenemos los headers del primer objeto
    const headers = Object.keys(data[0]);

    // Creamos las filas del CSV
    const csvRows = [
      // Primera fila: nombres de las columnas (headers)
      headers.join(","),
      // Resto de filas: convertimos cada objeto a una fila de CSV
      ...data.map((row) =>
        headers
          .map((header) => {
            // Escapamos las comas y las comillas dobles si existen en el valor
            const value = row[header]?.toString() || "";
            if (value.includes(",") || value.includes('"')) {
              return `"${value.replace(/"/g, '""')}"`;
            }
            return value;
          })
          .join(",")
      ),
    ];

    // Unimos todas las filas con saltos de línea
    const csvString = csvRows.join("\n");

    // Agregamos BOM para mejor compatibilidad con Excel
    const BOM = "\uFEFF";
    const csvContentWithBOM = BOM + csvString;

    // Creamos el blob con el contenido
    const blob = new Blob([csvContentWithBOM], {
      type: "text/csv;charset=utf-8;",
    });

    // Creamos el link para descargar
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);

    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `Template-${new Date().toISOString()}.${fileType}`
    );
    link.style.visibility = "hidden";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error al generar el CSV:", error);
  }
};

const GlobalListTable = () => {
  const navigate = useNavigate();
  const { mutate } = useSWRConfig();
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogEditCategory, setOpenDialogEditCategory] = useState(false);
  const [openDialogCreateTable, setOpenDialogCreateTable] = useState(false);
  const [openDialogCreateGlobalValue, setOpenDialogCreateGlobalValue] =
    useState(false);
  const [openDialogUpdateGlobalValue, setOpenDialogUpdateGlobalValue] =
    useState(false);
  const [currentGlobalTable, setCurrentGlobalTable] = useState(null);
  const [deleteTable, setDeleteTable] = useState(false);
  const [deleteGlobalValue, setDeleteGlobalValue] = useState(false);
  const [deleteCategory, setDeleteCategory] = useState(false);
  const [dataDelete, setDataDelete] = useState({});
  const [selectDeleteOption, setSelectDeleteOption] = useState(null);
  const [contentDialog, setContentDialog] = useState({
    title: "",
    description: "",
    disagree: "Cancel",
    agree: "",
  });
  const [selectTableId, setSelectTableId] = useState(null);
  const [categoryToEdit, setCategoryToEdit] = useState({
    id: null,
    name: "",
  });
  const { theme } = useContext(ThemeContext);
  const [dataCategories, setDataCategories] = useState([]);
  const [dataGlobalTables, setDataGlobalTables] = useState([]);
  const [currentCategory, setCurrentCategory] = useState(null);
  const [dataFormColumnTable, setDataFormColumnTable] = useState({
    tableName: "",
  });
  const [errorMessage, setErrorMessage] = useState("");
  const inputFileRef = useRef(null);
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState([]); // Aquí guardaremos los datos parseados del CSV
  const [headerCSV, setHeaderCSV] = useState([]);
  const [errorMatchHeader, setErrorMatchHeader] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showConfirm, setShowConfirm] = useState(false); // Controla si mostramos el modal de confirmación
  const [showSummary, setShowSummary] = useState(false); // Controla si mostramos el modal de resumen
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [errorHeader, setErrorHeader] = useState("");
  const [errorMessagesCsv, setErrorMessagesCsv] = useState([]);
  const [columnsGlobalTable, setColumnsGlobalTable] = useState([
    {
      Columns: "Id",
      Type: "string",
      UniqueValues: "true",
      Tools: (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <CustomButton
            type="onClick"
            text={<DeleteIcon />}
            disabled={true}
            margin={5}
            primaryColor={VISIBILITY_OFF_ICON}
            secondaryColor={VISIBILITY_OFF_ICON}
          />
        </div>
      ),
    },
  ]);

  const {
    register: registerGlobalTable,
    handleSubmit: handleSubmitGlobalTable,
    formState: { errors: errorsGlobalTable },
    setValue: setValueGlobalTable,
    reset: resetInputsGlobalTable,
    setError: setErrorGlobalValue,
    clearErrors: clearErrorsGlobalValue,
    getValues: getValuesGlobalTable,
  } = useForm();

  const {
    register: registerGlobalValue,
    handleSubmit: handleSubmitGlobalValue,
    formState: { errors: errorsGlobalValue },
    setValue: setValueGlobalValue,
    reset: resetInputsGlobalValue,
    setError: setErrorCreateGlobalValue,
    clearErrors: clearErrorsCreateGlobalValue,
  } = useForm();

  const {
    register: registerUpdateGlobalValue,
    handleSubmit: handleSubmitUpdateGlobalValue,
    formState: { errors: errorsUpdateGlobalValue },
    setValue: setValueUpdateGlobalValue,
    setError: setErrorUpdateGlobalValue,
    clearErrors: clearErrorsUpdateGlobalValue,
  } = useForm();

  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const url = `${config.URL_BACKEND_MG}global-list-table?adminCompanyId=${adminCompanyId}`;
  const { data: globalListTables, error: errorGlobalTables } = useSWR(url);

  useEffect(() => {
    if (globalListTables && !errorGlobalTables && globalListTables.length > 0) {
      setDataCategories(globalListTables[0]?.tableCategories);
      if (globalListTables[0]?.tableCategories.length > 0) {
        setDataGlobalTables(
          globalListTables[0]?.tableCategories[0]?.groupTables
        );
        setCurrentCategory(globalListTables[0]?.tableCategories[0]._id);
      }
    }
  }, [globalListTables, errorGlobalTables]);

  const uniqueCategoryNames = [
    ...new Set(dataCategories?.map((elm) => elm?.name)),
  ];

  const options = uniqueCategoryNames.map((catgory) => ({
    value: catgory,
    label: <ContainerSelect>{catgory}</ContainerSelect>,
  }));

  const handleAddNewColumn = async (data) => {
    let optionExists = false;
    columnsGlobalTable.forEach((elm) => {
      if (elm.Columns === data["Column Name"]) {
        optionExists = true;
      }
    });

    if (!optionExists) {
      setDataFormColumnTable({
        tableName: data["Table Name"],
      });
      setColumnsGlobalTable((current) => [
        ...current,
        {
          Columns: data["Column Name"],
          Type: data["Data Type"],
          UniqueValues: data["Unique Values"].toString(),
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() =>
                  setColumnsGlobalTable((current) =>
                    current.filter((elm) => elm.Columns !== data["Column Name"])
                  )
                }
                margin={5}
                primaryColor={DELETE_COLOR}
                secondaryColor={HOVER_DELETE_COLOR}
              />
            </div>
          ),
        },
      ]);
      setValueGlobalTable("Column Name", "");
      setValueGlobalTable("Data Type", "string");
      setValueGlobalTable("Unique Values", false);
      clearErrorsGlobalValue("Column Name");
    } else {
      setErrorGlobalValue("Column Name", {
        type: "manual",
        message: `The "${data["Column Name"]}" column already exists.`,
      });
    }
  };
  const handelSaveTable = async () => {
    if (globalListTables.length === 0) {
      await saveGlobalListTable({
        adminCompanyId,
        tableCategories: [
          {
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                dataColumns: columnsGlobalTable.map((elm) => {
                  return {
                    name: elm.Columns,
                    dataType: elm.Type,
                    uniqueValues: JSON.parse(elm.UniqueValues),
                  };
                }),
                headers: columnsGlobalTable.map((elm) => elm.Columns),
                rows: [],
              },
            ],
          },
        ],
      });
    } else {
      if (selectTableId) {
        await updateGlobalListTable({
          id: globalListTables[0]._id,
          body: {
            adminCompanyId,
            tableCategories: dataCategories.map((elm) => {
              if (elm._id === currentCategory) {
                return {
                  ...elm,
                  groupTables: dataGlobalTables.map((item) => {
                    if (item._id === selectTableId) {
                      const currentHeaders = columnsGlobalTable.map(
                        (clm) => clm.Columns
                      );
                      return {
                        ...item,
                        name: getValuesGlobalTable("Table Name"),

                        dataColumns: columnsGlobalTable.map((data) => {
                          return {
                            name: data.Columns,
                            dataType: data.Type,
                            uniqueValues: JSON.parse(data.UniqueValues),
                          };
                        }),
                        headers: currentHeaders,
                        rows: item.rows.map((option) => {
                          const filteredOption = { ...option };
                          for (let key in filteredOption) {
                            if (!currentHeaders.includes(key)) {
                              delete filteredOption[key];
                            }
                          }
                          return filteredOption;
                        }),
                      };
                    }
                    return item;
                  }),
                };
              }
              return elm;
            }),
          },
        });
      } else {
        const newCategories = dataCategories.map((elm) => {
          if (elm.name === getValuesGlobalTable("selectCategory")) {
            return {
              ...elm,
              groupTables: [
                ...elm.groupTables,
                {
                  name: dataFormColumnTable.tableName,
                  dataColumns: columnsGlobalTable.map((column) => {
                    return {
                      name: column.Columns,
                      dataType: column.Type,
                      uniqueValues: JSON.parse(column.UniqueValues),
                    };
                  }),
                  headers: columnsGlobalTable.map((column) => column.Columns),
                  rows: [],
                },
              ],
            };
          } else {
            return elm;
          }
        });
        const categoryExists = dataCategories.some(
          (elm) => elm.name === getValuesGlobalTable("selectCategory")
        );
        if (!categoryExists) {
          newCategories.push({
            name: getValuesGlobalTable("selectCategory"),
            groupTables: [
              {
                name: dataFormColumnTable.tableName,
                dataColumns: columnsGlobalTable.map((column) => {
                  return {
                    name: column.Columns,
                    dataType: column.Type,
                    uniqueValues: JSON.parse(column.UniqueValues),
                  };
                }),
                headers: columnsGlobalTable.map((column) => column.Columns),
                rows: [],
              },
            ],
          });
        }
        await updateGlobalListTable({
          id: globalListTables[0]._id,
          body: {
            adminCompanyId,
            tableCategories: newCategories,
          },
        });
      }
    }
    setColumnsGlobalTable([
      {
        Columns: "Id",
        Type: "string",
        UniqueValues: "true",
        Tools: (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <CustomButton
              type="onClick"
              text={<DeleteIcon />}
              disabled={true}
              margin={5}
              primaryColor={VISIBILITY_OFF_ICON}
              secondaryColor={VISIBILITY_OFF_ICON}
            />
          </div>
        ),
      },
    ]);
    setSelectTableId(null);
    resetInputsGlobalTable();
    setOpenDialogCreateTable(false);
    mutate(url);
  };

  const handelCreateGlobalValue = async (data) => {
    const uniqueOptions = [];
    let optionExists = false;

    currentGlobalTable.dataColumns.forEach((elm) => {
      if (elm.name !== "Id" && elm.uniqueValues) {
        currentGlobalTable.rows.forEach((row) => {
          if (row[elm.name] === data[elm.name]) {
            uniqueOptions.push(elm.name);
            optionExists = true;
          }
        });
      }
    });
    const newId = uuidv4();
    if (!optionExists) {
      await updateGlobalListTable({
        id: globalListTables[0]._id,
        body: {
          adminCompanyId,
          tableCategories: dataCategories.map((elm) => {
            if (elm._id === currentCategory) {
              return {
                ...elm,
                groupTables: dataGlobalTables.map((elm) => {
                  if (elm._id === currentGlobalTable._id) {
                    return {
                      ...elm,
                      rows: [...elm.rows, { ...data, Id: newId.toString() }],
                    };
                  }
                  return elm;
                }),
              };
            }
            return elm;
          }),
        },
      });

      setCurrentGlobalTable((current) => ({
        ...current,
        rows: [...current.rows, { ...data, Id: newId.toString() }],
      }));

      setDataGlobalTables((current) =>
        current.map((elm) => {
          if (elm._id === currentGlobalTable._id) {
            return {
              ...elm,
              rows: [...elm.rows, { ...data, Id: newId.toString() }],
            };
          }
          return elm;
        })
      );
      setOpenDialogCreateGlobalValue(false);
      if (uniqueOptions.length > 0) {
        uniqueOptions.forEach((elm) => {
          clearErrorsCreateGlobalValue(elm);
        });
      }
      resetInputsGlobalValue();
    } else {
      if (uniqueOptions.length > 0) {
        uniqueOptions.forEach((elm) => {
          setErrorCreateGlobalValue(elm, {
            type: "manual",
            message: `The "${elm}" option already exists.`,
          });
        });
      }
    }
  };

  const handelUpdateGlobalValue = async (data) => {
    const uniqueOptions = [];
    let optionExists = false;
    let valueOption = data["Id"];

    currentGlobalTable.dataColumns.forEach((elm) => {
      if (elm.name !== "Id" && elm.uniqueValues) {
        currentGlobalTable.rows
          .filter((item) => item.Id !== valueOption)
          .forEach((row) => {
            if (row[elm.name] === data[elm.name]) {
              uniqueOptions.push(elm.name);
              optionExists = true;
            }
          });
      }
    });

    if (!optionExists) {
      setCurrentGlobalTable((current) => ({
        ...current,
        rows: current.rows.map((elm) => {
          if (elm.Id === valueOption) {
            return data;
          }
          return elm;
        }),
      }));
      setDataGlobalTables((current) =>
        current.map((elm) => {
          if (elm._id === currentGlobalTable._id) {
            return {
              ...elm,
              rows: elm.rows.map((item) => {
                if (item.Id === valueOption) {
                  return data;
                }
                return item;
              }),
            };
          }
          return elm;
        })
      );
      await updateGlobalListTable({
        id: globalListTables[0]._id,
        body: {
          adminCompanyId,
          tableCategories: dataCategories.map((elm) => {
            if (elm._id === currentCategory) {
              return {
                ...elm,
                groupTables: dataGlobalTables.map((elm) => {
                  if (elm._id === currentGlobalTable._id) {
                    return {
                      ...elm,
                      rows: elm.rows.map((item) => {
                        if (item.Id === valueOption) {
                          return data;
                        }
                        return item;
                      }),
                    };
                  }
                  return elm;
                }),
              };
            }
            return elm;
          }),
        },
      });
      setOpenDialogUpdateGlobalValue(false);
      if (uniqueOptions.length > 0) {
        uniqueOptions.forEach((elm) => {
          clearErrorsUpdateGlobalValue(elm);
        });
      }
    } else {
      if (uniqueOptions.length > 0) {
        uniqueOptions.forEach((elm) => {
          setErrorUpdateGlobalValue(elm, {
            type: "manual",
            message: `The "${elm}" option already exists.`,
          });
        });
      }
    }
  };

  const handelDeleteTables = async () => {
    await updateGlobalListTable({
      id: globalListTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.filter((item) => {
                return item._id !== dataDelete._id;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setCurrentGlobalTable(null);
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
    mutate(url);
  };

  const handelDeleteGlobalValues = async () => {
    setCurrentGlobalTable((current) => ({
      ...current,
      rows: current.rows
        .map((item) => {
          if (item.Id === selectDeleteOption) {
            return null;
          }
          return item;
        })
        .filter((it) => it !== null),
    }));
    setDataGlobalTables((current) =>
      current.map((elm) => {
        if (elm._id === currentGlobalTable._id) {
          return {
            ...elm,
            rows: elm.rows
              .map((item) => (item.Id === selectDeleteOption ? null : item))
              .filter((it) => it !== null),
          };
        }
        return elm;
      })
    );
    await updateGlobalListTable({
      id: globalListTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === currentCategory) {
            return {
              ...elm,
              groupTables: dataGlobalTables.map((elm) => {
                if (elm._id === currentGlobalTable._id) {
                  return {
                    ...elm,
                    rows: elm.rows
                      .map((item) => {
                        if (item.Id === selectDeleteOption) {
                          return null;
                        }
                        return item;
                      })
                      .filter((it) => it !== null),
                  };
                }
                return elm;
              }),
            };
          }
          return elm;
        }),
      },
    });
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setDataDelete({});
    setOpenDialog(false);
  };

  const handelDeleteCategory = async () => {
    await updateGlobalListTable({
      id: globalListTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.filter((elm) => {
          return elm._id !== categoryToEdit.id;
        }),
      },
    });
    setDataCategories((current) => {
      return current.filter((elm) => {
        return elm._id !== categoryToEdit.id;
      });
    });
    setDataGlobalTables([]);
    setCurrentGlobalTable(null);
    setCurrentGlobalTable(null);
    setContentDialog({
      title: "",
      description: "",
      disagree: "",
    });
    setCategoryToEdit({ id: null, name: "" });
    setOpenDialog(false);
    mutate(url);
  };

  const handelUpdateCategory = async () => {
    await updateGlobalListTable({
      id: globalListTables[0]._id,
      body: {
        adminCompanyId,
        tableCategories: dataCategories.map((elm) => {
          if (elm._id === categoryToEdit.id) {
            return {
              ...elm,
              name: categoryToEdit.name,
            };
          }
          return elm;
        }),
      },
    });
    mutate(url);
    setOpenDialogEditCategory(false);
  };

  const handleOpenDialogCreateGlobalValue = () => {
    currentGlobalTable.dataColumns.forEach((elm) => {
      if (elm.dataType === "string") {
        setValueGlobalValue(elm.name, "");
      }
      if (elm.dataType === "number") {
        setValueGlobalValue(elm.name, 0);
      }
    });
    setOpenDialogCreateGlobalValue(true);
  };

  const handleOpenDialogEditGlobalValue = ({ globalValue }) => {
    setOpenDialogUpdateGlobalValue(true);
    for (let key in globalValue) {
      setValueUpdateGlobalValue(key, globalValue[key]);
    }
  };

  const handleOpenDialogDeleteValue = ({ e, id }) => {
    e.preventDefault();
    setDeleteGlobalValue(true);
    setSelectDeleteOption(id);
    setContentDialog({
      title: "Delete Global Value",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the id <strong>{id}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteTable = ({ e, table }) => {
    e.preventDefault();
    setDeleteTable(true);
    setDataDelete(table);
    setContentDialog({
      title: "Delete Global List Table",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the table <strong>{table.name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogDeleteCategory = ({ categoryId, name }) => {
    setDeleteCategory(true);
    setCategoryToEdit({ id: categoryId, name: name });
    setContentDialog({
      title: "Delete Category",
      description: (
        <p style={{ fontSize: "2rem", color: StyleColor2(theme) }}>
          ⚠ Do you want delete the category <strong>{name}</strong>?
        </p>
      ),
      disagree: "Cancel",
      agree: "Delete",
    });
    setOpenDialog(true);
  };

  const handleOpenDialogUpdate = ({ e, table }) => {
    e.preventDefault();
    setSelectTableId(table._id);
    setValueGlobalTable("Table Name", table.name);
    setValueGlobalTable("Column Name", "");
    setValueGlobalTable("Data Type", "string");
    setValueGlobalTable("Unique Values", false);
    setDataFormColumnTable({
      tableName: table.name,
    });
    setColumnsGlobalTable(
      table.dataColumns.map((elm) => {
        return {
          Columns: elm.name,
          Type: elm.dataType,
          UniqueValues: elm.uniqueValues.toString(),
          Tools: (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <CustomButton
                type="onClick"
                text={<DeleteIcon />}
                onClick={() => {
                  setColumnsGlobalTable((current) => {
                    return current.filter((item) => item.Columns !== elm.name);
                  });
                }}
                margin={5}
                disabled={elm.name === "Id" ? true : false}
                primaryColor={
                  elm.name === "Id" ? VISIBILITY_OFF_ICON : DELETE_COLOR
                }
                secondaryColor={
                  elm.name === "Id" ? VISIBILITY_OFF_ICON : HOVER_DELETE_COLOR
                }
              />
            </div>
          ),
        };
      })
    );
    setOpenDialogCreateTable(true);
  };

  const handleInputChange = (newValue) => {
    if (getValuesGlobalTable("selectCategory") === null) {
      setValueGlobalTable("selectCategory", newValue);
    }
  };

  const handleSelectCategory = (e) => {
    const categorySelected = dataCategories.find((elm) => elm._id === e.value);
    setCurrentCategory(e.value);
    setDataGlobalTables(categorySelected?.groupTables);
    setCurrentGlobalTable(null);
  };

  const handleCreateNewTable = () => {
    const category = dataCategories?.find(
      (elm) => elm._id === currentCategory
    )?.name;
    setValueGlobalTable("selectCategory", category);
    setOpenDialogCreateTable(true);
  };

  const handleDownloadCSVTemplate = ({ e, table }) => {
    e.preventDefault();
    let csvHeaders = [];

    if (table?.dataColumns.length > 0) {
      const aux = {};
      const infoAux = {};

      for (let column of table.dataColumns) {
        if (column.name !== "Id") {
          infoAux[column.name] = `${
            column.uniqueValues ? "unique" : "not unique"
          }/${column.dataType}`;
          aux[column.name] = "";
        }
      }
      csvHeaders.push({ ...infoAux });
      csvHeaders.push({ ...aux });
    }
    saveCSVTemplateFile(csvHeaders, "csv");
  };

  const handleUploadCSV = (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onload = function (e) {
        const content = e.target.result;
        const firstNewline = content.indexOf("\n");
        const firstLine = content.slice(0, firstNewline);
        const delimiter = firstLine.includes(";") ? ";" : ",";

        // Dividir los encabezados basados en el delimitador detectado
        const csvHeaders = firstLine
          .split(delimiter)
          .map((header) => header.trim());

        Papa.parse(file, {
          header: true,
          delimiter: delimiter,
          complete: (result) => {
            const data = result.data;
            setHeaderCSV(csvHeaders);
            setFileData(data); // Save the data to show in the modal
            checkForDuplicates(data, csvHeaders); // Validate if there are duplicates
            setFileName(file.name);
            setShowConfirm(true);
          },
          error: (error) => {
            setErrorMessage(`Error reading file: ${error.message}`);
          },
        });
      };
      reader.readAsText(file.slice(0, 1024)); // Leer solo los primeros 1024 bytes para determinar el delimitador
    } else {
      setErrorMessage("Please select a file to upload");
    }
  };

  const checkForDuplicates = (data, csvHeaders) => {
    const validRows = [];
    const invalidRows = [];
    let foundInDatabase = false;
    let NoMatchHeader = false;
    let foundInDatabaseCount = 0;
    let emptyCount = 0;
    let duplicateCount = 0;
    let typeErrorCount = 0;

    //Base de datos dataTable
    // Base de datos dataTable
    const dataTable = dataGlobalTables?.find(
      (datas) => datas?._id === currentGlobalTable?._id
    );

    //extreaer los headers de la base de datos sin contar el id
    const dataBaseHeaders = dataTable?.headers.filter(
      (header) => header !== "Id"
    );

    const headerMatch =
      csvHeaders.every((header) => dataBaseHeaders.includes(header)) &&
      dataBaseHeaders.every((header) => csvHeaders.includes(header));

    if (!headerMatch) {
      NoMatchHeader = true;
      setErrorHeader(
        "The headers of the CSV do not match the headers of the database"
      );
      setErrorMatchHeader(true);
    } else {
      setErrorMatchHeader(false);
      setErrorHeader("");
    }

    const dbDataTypes = dataTable?.dataColumns.reduce((acc, column) => {
      acc[column.name] = column.dataType;
      return acc;
    }, {});

    // Extraer columnas únicas de la base de datos
    const uniqueColumnsDB = dataTable?.dataColumns
      ?.filter((column) => column.uniqueValues === true)
      .map((column) => column.name);

    const columnsDB = dataTable?.dataColumns.map((column) => column.name);

    // Extraer valores únicos de la base de datos
    let uniqueValuesFromDB = {};
    uniqueColumnsDB.forEach((column) => {
      uniqueValuesFromDB[column] = new Set(
        dataTable.rows.map((row) => row[column])
      );
    });

    // Recolectar valores únicos del CSV
    const seenCSVKeys = new Set(); // Para rastrear combinaciones únicas en el CSV

    data.slice(1).forEach((row) => {
      // Crear una clave compuesta para las columnas únicas
      const rowKey = uniqueColumnsDB.map((col) => row[col]).join("|");

      // Validar la fila
      let isRowValid = true;

      // Comprobar si la clave compuesta ya existe en el CSV o en la base de datos
      if (seenCSVKeys.has(rowKey)) {
        isRowValid = false; // Duplicado interno en el CSV
        duplicateCount++;
      } else {
        uniqueColumnsDB.forEach((key) => {
          if (uniqueValuesFromDB[key].has(row[key])) {
            isRowValid = false; // Valor ya existe en la base de datos
            foundInDatabase = true;
            foundInDatabaseCount++;
          }
        });
      }

      // Validar campos vacíos
      uniqueColumnsDB.forEach((key) => {
        if (row[key] == "") {
          isRowValid = false; // Campo único vacío
          emptyCount++;
        }
      });

      //validate each field type
      for (const [key, value] of Object.entries(row)) {
        if (value === "") {
          // Continuar si el campo está vacío y no es un campo único
          if (!uniqueColumnsDB.includes(key)) continue;
        }

        switch (dbDataTypes[key]) {
          case "number":
            if (isNaN(value)) {
              isRowValid = false;
              typeErrorCount++;
            }
            break;
          case "string":
            if (typeof value !== "string") {
              isRowValid = false;
              typeErrorCount++;
            }
            break;
        }
      }

      // Clasificar la fila como válida o inválida
      if (isRowValid) {
        validRows.push(row);
        seenCSVKeys.add(rowKey); // Marcar como visto en el CSV
      } else {
        invalidRows.push(row);
      }
    });

    const errors = [];

    if (foundInDatabaseCount > 0) {
      errors.push(
        `${foundInDatabaseCount} values already exist in the database`
      );
    }

    if (duplicateCount > 0) {
      errors.push(`${duplicateCount} duplicate values in the CSV`);
    }

    if (typeErrorCount > 0) {
      errors.push(`${typeErrorCount} values have the wrong data type`);
    }

    if (emptyCount > 0) {
      errors.push(`${emptyCount} fields are empty and must have a value`);
    }

    setErrorMessagesCsv(errors);

    setFileData({ validRows, invalidRows });
  };

  const confirmUpload = async () => {
    const tableId = globalListTables[0]?._id;
    const tableCategoriesId = currentCategory;
    const groupId = currentGlobalTable?._id;

    if (fileName && fileData?.validRows?.length > 0 && !errorMatchHeader) {
      setUploading(true);
      setProgress(0);
      //Agregar barra de progreso
      for (let i = 0; i <= fileData?.validRows?.length; i++) {
        await new Promise((resolve) =>
          setTimeout(() => {
            setProgress((i / fileData?.validRows?.length) * 100);
            resolve();
          }, 100)
        );
      }

      uploadCSVGlobalListTable(
        fileData?.validRows,
        tableId,
        tableCategoriesId,
        groupId,
        setErrorMessage,
        setCurrentGlobalTable
      );

      setSelectedFile(null);
      setShowConfirm(false);
      setUploading(false);
      setProgress(0);
      mutate(url);
      // Show the modal with the summary of uploaded data
      setShowSummary(true);
    } else {
      setSelectedFile(null);
      setErrorMessage("There are duplicate values or no file selected");
      mutate(url);
    }
  };

  const handleButtonClick = () => {
    inputFileRef.current.value = "";
    inputFileRef.current.click();
  };
  const navigation = (path) => {
    navigate(path);
  };

  const prepareTableData = (invalidRows) => {
    if (!invalidRows || invalidRows.length === 0) {
      return { headers: [], rows: [] };
    }

    const headers = Object.keys(invalidRows[0]);
    const rows = invalidRows.map(row => {
      const newRow = {};
      headers.forEach(header => {
        newRow[header] = row[header] || '—'; // Uso de '—' para datos faltantes o nulos
      });
      return newRow;
    });

    return { headers, rows };
  }


  return (
    <Layout>
      <OpenDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        execute={
          deleteTable
            ? handelDeleteTables
            : deleteGlobalValue
            ? handelDeleteGlobalValues
            : deleteCategory
            ? handelDeleteCategory
            : ""
        }
        content={contentDialog}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogEditCategory}
        setOpenDialog={setOpenDialogEditCategory}
        toExecuteOnClose={() => {}}
        execute={handelUpdateCategory}
        content={{
          title: "Edit Name Category",
          disagree: "Cancel",
          agree: "Update",
          description: (
            <ContainerInput>
              <label>Name Category</label>
              <input
                type="text"
                value={categoryToEdit.name}
                onChange={(e) =>
                  setCategoryToEdit((current) => ({
                    ...current,
                    name: e.target.value,
                  }))
                }
              />
            </ContainerInput>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogCreateTable}
        setOpenDialog={setOpenDialogCreateTable}
        toExecuteOnClose={() => {
          setColumnsGlobalTable([
            {
              Columns: "Id",
              Type: "string",
              UniqueValues: "true",
              Tools: (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                  }}
                >
                  <CustomButton
                    type="onClick"
                    text={<DeleteIcon />}
                    disabled={true}
                    margin={5}
                    primaryColor={VISIBILITY_OFF_ICON}
                    secondaryColor={VISIBILITY_OFF_ICON}
                  />
                </div>
              ),
            },
          ]);
          setSelectTableId(null);
          resetInputsGlobalTable();
          setOpenDialogCreateTable(false);
        }}
        content={{
          title: "Create Global List Table",
          description: (
            <div>
              <Form
                style={{ background: StyleColor3(theme) }}
                onSubmit={handleSubmitGlobalTable(handleAddNewColumn)}
              >
                <Divider>
                  <span className="divider-title">Table Information</span>
                </Divider>
                <label
                  style={{
                    marginBottom: "5px",
                    fontSize: "1.6rem",
                  }}
                >
                  Choose or create a category
                </label>
                <Select
                  {...registerGlobalTable("selectCategory")}
                  options={options}
                  isClearable
                  isSearchable
                  onChange={(selectedOption) => {
                    setValueGlobalTable(
                      "selectCategory",
                      selectedOption?.value
                    );
                  }}
                  onInputChange={handleInputChange}
                  placeholder="Type to add or select from list"
                  styles={SelectCategoriesStyles}
                  defaultInputValue={
                    dataCategories.find((elm) => elm._id === currentCategory)
                      ?.name || null
                  }
                />
                <TextInput
                  field={{
                    name: "Table Name",
                    type: "string",
                    required: true,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />
                <br />
                <Divider>
                  <span className="divider-title">Columns</span>
                </Divider>
                <TextInput
                  field={{
                    name: "Column Name",
                    type: "string",
                    required: false,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />
                <TextInput
                  field={{
                    name: "Unique Values",
                    type: "check",
                    required: false,
                  }}
                  register={registerGlobalTable}
                  errors={errorsGlobalTable}
                />
                <SelectInput
                  field={{
                    name: "Data Type",
                    options: ["string", "number"],
                    required: false,
                  }}
                  register={registerGlobalTable}
                />
                <br />
                <CustomButton type={"submit"} text={"Add Column"} margin={0} />
              </Form>
              <br />
              <Divider>
                <span className="divider-title">Table Preview</span>
              </Divider>
              <br />
              <GenericTable
                headers={Object.keys(columnsGlobalTable[0])}
                rows={columnsGlobalTable}
                keyValues={Object.keys(columnsGlobalTable[0])}
                handlerClick={() => {}}
                state={true}
                configBar={true}
                customTableWidth={"100%"}
                customHeaderWidth={"5%"}
                from={FROM_TABLE.details}
              />
              <br />
              <CustomButton
                type={"button"}
                text={"Save"}
                margin={10}
                onClick={handelSaveTable}
                primaryColor={
                  columnsGlobalTable.length > 1
                    ? PRIMARY_COLOR
                    : VISIBILITY_OFF_ICON
                }
                secondaryColor={
                  columnsGlobalTable.length > 1
                    ? SECOND_COLOR
                    : VISIBILITY_OFF_ICON
                }
                disabled={columnsGlobalTable.length > 1 ? false : true}
              />
            </div>
          ),
        }}
        maxWidth={"800px"}
        minWidth={"600px"}
      />
      <OpenDialog
        openDialog={openDialogCreateGlobalValue}
        setOpenDialog={setOpenDialogCreateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Create Global Value",
          description: (
            <Form onSubmit={handleSubmitGlobalValue(handelCreateGlobalValue)}>
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Create Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                if (elm.name !== "Id") {
                  return (
                    <TextInput
                      field={{
                        name: elm.name,
                        type: elm.dataType,
                        required: true,
                      }}
                      register={registerGlobalValue}
                      errors={errorsGlobalValue}
                    />
                  );
                }
              })}
              <CustomButton type={"submit"} text={"Create"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <OpenDialog
        openDialog={openDialogUpdateGlobalValue}
        setOpenDialog={setOpenDialogUpdateGlobalValue}
        toExecuteOnClose={() => {}}
        content={{
          title: "Update Global Value",
          description: (
            <Form
              onSubmit={handleSubmitUpdateGlobalValue(handelUpdateGlobalValue)}
            >
              <Title
                style={{
                  color: StyleColor2(theme),
                }}
              >
                Update Global Value
              </Title>
              {currentGlobalTable?.dataColumns?.map((elm) => {
                if (elm.name !== "Id") {
                  return (
                    <TextInput
                      field={{
                        name: elm.name,
                        type: elm.dataType,
                        required: true,
                        noTouch: elm.name === "Id" ? true : false,
                      }}
                      register={registerUpdateGlobalValue}
                      errors={errorsUpdateGlobalValue}
                    />
                  );
                }
              })}
              <CustomButton type={"submit"} text={"Update"} margin={0} />
            </Form>
          ),
        }}
        maxWidth={"600px"}
        minWidth={"400px"}
      />
      <Content>
        <InformationSection className="background-1" style={{ border: "none" }}>
          <div
            style={{
              width: "15%",
            }}
          >
            <h1 className="title-description">
              Multi-Name Asset
              <br /> Configuration
            </h1>
          </div>
          <div
            style={{
              width: "85%",
            }}
          >
            <p className="description">
              This module enables the creation of enterprise tables to
              standardize and manage multiple names for the same asset, device,
              or equipment across different departments and operations. By
              unifying these varied nomenclatures, the module enhances
              efficiency while maintaining flexibility.
              <br />
              For example, a facility known as "ABC Facility" might be referred
              to as "The A-B-C Facility" by operations staff, "FAC ABC" on field
              signage, and "ABC-Fac" in the procurement system.
              <br />
              This module allows you to assign all these names to a single
              asset, ensuring that all related data is linked to one entity,
              thereby streamlining configuration and data management across the
              digital twin module, measurements module, and the entire platform.
            </p>
          </div>
        </InformationSection>
        <ShowAllButton2
          style={{ marginTop: "10px" }}
          onClick={() => navigation("/setUp-admin")}
        >
          <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
        </ShowAllButton2>
        <GlobalContent className="background-2 border-top-1">
          <ConfigurationSection>
            <ConfigLibrary className="background-2">
              <ContentButton>
                <div className="select-categories">
                  <CustomButton
                    text={"+ Create New Global List Table"}
                    margin={0}
                    type={"onClick"}
                    onClick={() => handleCreateNewTable()}
                    primaryColor={PRIMARY_COLOR}
                    secondaryColor={SECOND_COLOR}
                  />
                </div>
                <div className="select-categories">
                  <label>Choose group category</label>
                  <div
                    style={{
                      width: "60%",
                    }}
                  >
                    <Select
                      onChange={handleSelectCategory}
                      isDisabled={
                        dataCategories && dataCategories.length > 0
                          ? false
                          : true
                      }
                      options={
                        dataCategories &&
                        dataCategories.length > 0 &&
                        dataCategories?.map((option) => {
                          return {
                            value: option._id,
                            label: (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "start",
                                    alignItems: "center",
                                    width: "100%",
                                    marginLeft: "-1px",
                                  }}
                                >
                                  {option.name}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "80px",
                                  }}
                                >
                                  <CancelIcon
                                    sx={{
                                      color: "red",
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: "darkred",
                                      },
                                    }}
                                    onClick={() =>
                                      handleOpenDialogDeleteCategory({
                                        categoryId: option._id,
                                        name: option.name,
                                      })
                                    }
                                  />
                                  <EditIcon
                                    sx={{
                                      color: PRIMARY_COLOR,
                                      width: "15px",
                                      height: "auto",
                                      cursor: "pointer",
                                      transition: "color 0.3s",
                                      "&:hover": {
                                        color: SECOND_COLOR,
                                      },
                                    }}
                                    onClick={() => {
                                      setCategoryToEdit({
                                        id: option._id,
                                        name: option.name,
                                      });
                                      setOpenDialogEditCategory(true);
                                    }}
                                  />
                                </div>
                              </div>
                            ),
                          };
                        })
                      }
                      value={currentCategory}
                      placeholder={
                        dataCategories && dataCategories.length > 0
                          ? dataCategories?.find(
                              (elm) => elm._id === currentCategory
                            )?.name
                          : "There are no categories"
                      }
                      styles={customSelectStyle}
                      menuPlacement="auto"
                    />
                  </div>
                </div>
              </ContentButton>
              <ContentData>
                {dataGlobalTables?.length !== 0 ? (
                  <Columns paddingLeft={30}>
                    <Column width={10} textAling={"start"}>
                      Id
                    </Column>
                    <Column width={90} textAling={"start"}>
                      Table Name
                    </Column>
                  </Columns>
                ) : (
                  <Space />
                )}
                {dataGlobalTables?.length === 0 ? (
                  <InformationShare>
                    <div className="head">Edit Existing List Table</div>
                    <div className="body">
                      To Edit Click on + New Global List Table
                    </div>
                  </InformationShare>
                ) : (
                  <ContentTablesTags>
                    {dataGlobalTables?.map((item, index) => (
                      <TableTag
                        className="background-row-1 border-color-1"
                        currentGlobalTableId={currentGlobalTable?._id}
                        item={item}
                        textAling={"start"}
                      >
                        <div>
                          <CustomButton
                            type="onClick"
                            text={index + 1}
                            onClick={() => {
                              setCurrentGlobalTable(item);
                              resetInputsGlobalValue();
                            }}
                            margin={0}
                            primaryColor={
                              currentGlobalTable?._id === item._id
                                ? SECOND_COLOR
                                : PRIMARY_COLOR
                            }
                          />
                        </div>
                        <ContentDataTag className="background-2" width={90}>
                          {item.name}
                        </ContentDataTag>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={<DeleteIcon />}
                            onClick={(e) =>
                              handleOpenDialogDeleteTable({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                            primaryColor={DELETE_COLOR}
                            secondaryColor={HOVER_DELETE_COLOR}
                          />
                        </div>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={
                              <Tooltip title="CSV TEMPLATE">
                                <FileDownloadIcon />
                              </Tooltip>
                            }
                            onClick={(e) =>
                              handleDownloadCSVTemplate({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                          />
                        </div>
                        <div>
                          <CustomButton
                            type="onClick"
                            text={<EditIcon />}
                            onClick={(e) =>
                              handleOpenDialogUpdate({
                                e: e,
                                table: item,
                              })
                            }
                            margin={0}
                          />
                        </div>
                      </TableTag>
                    ))}
                  </ContentTablesTags>
                )}
              </ContentData>
            </ConfigLibrary>
            <ConfigConstant className="background-2">
              <ContentButton className="background-row-2 border-color-1">
                <h1> {`Create new values for the table: `}</h1>{" "}
                <h1
                  style={{
                    color: PRIMARY_COLOR,
                    marginLeft: "5px",
                  }}
                >
                  {currentGlobalTable?.name || ""}
                </h1>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-around",
                  }}
                >
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    <CustomButton
                      text={"+ New Global Value"}
                      margin={0}
                      type={"onClick"}
                      onClick={handleOpenDialogCreateGlobalValue}
                      disabled={currentGlobalTable ? false : true}
                      primaryColor={
                        currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                      }
                      secondaryColor={
                        currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                      }
                    />
                  </div>
                  <div
                    style={{
                      width: "49%",
                    }}
                  >
                    <input
                      type="file"
                      accept=".csv"
                      ref={inputFileRef}
                      style={{ display: "none" }} // hidde input
                      onChange={handleUploadCSV}
                    />
                    <CustomButton
                      text={"+ Upload CSV"}
                      margin={0}
                      type={"onClick"}
                      onClick={handleButtonClick}
                      disabled={currentGlobalTable ? false : true}
                      primaryColor={
                        currentGlobalTable ? PRIMARY_COLOR : VISIBILITY_OFF_ICON
                      }
                      secondaryColor={
                        currentGlobalTable ? SECOND_COLOR : VISIBILITY_OFF_ICON
                      }
                    />
                  </div>
                </div>
              </ContentButton>

              {/* Modal de confirmación */}
              <OpenDialog
                openDialog={showConfirm}
                setOpenDialog={setShowConfirm}
                execute={() => confirmUpload()}
                content={{
                  title: "Confirm upload",
                  description: (
                    <>
                      <ModalContainer>
                        <ModalContent>
                          <ModalHeader>
                            Are you sure you want to upload this file?
                          </ModalHeader>
                          <p>
                            <strong>Filename:</strong> {fileName?.name}
                          </p>
                          <br />
                          <p>
                            <strong>Total Valid Rows:</strong>{" "}
                            {fileData?.validRows?.length}
                          </p>
                          <br />
                          <p>
                            <strong>Total Invalid Rows:</strong>{" "}
                            {fileData?.invalidRows?.length}
                          </p>
                          <ul style={{ listStyleType: "none", padding: 0 }}>
                            {/* Mostrar solo las filas que tienen valores duplicados */}
                            {/*fileData
                            ?.filter((row) => row?.Options == valueDuplicate)
                            .map((row, index) => (
                              <li key={index} style={{ marginBottom: "10px" }}>
                                {Object.entries(row).map(([key, value]) => (
                                  <span key={key}>
                                    <strong>{key}:</strong> {value}{" "}
                                  </span>
                                ))}
                              </li>
                            ))*/}
                          </ul>

                          {
                            <div>
                              {errorMessagesCsv?.length > 0 ? (
                                <>
                                  <ModalHeadeError>
                                    Some errors have been found with this file
                                  </ModalHeadeError>
                                  <ModalErrorList>
                                    <ul>
                                      {errorMessagesCsv?.map((error, index) => (
                                        <li
                                          style={{ fontSize: "1.4rem" }}
                                          key={index}
                                        >
                                          {error}
                                        </li>
                                      ))}
                                    </ul>
                                  </ModalErrorList>
                                </>
                              ) : (
                                <SuccessMessage>
                                  <strong style={{ fontSize: "1.8rem" }}>
                                    The file is ready to be uploaded!
                                  </strong>
                                </SuccessMessage>
                              )}

                              {/**Barra de progreso */}
                              <div style={{ marginTop: "10px" }}>
                                {uploading && (
                                  <ProgressBar progress={progress} />
                                )}
                              </div>
                            </div>
                          }
                          {errorHeader && (
                            <ModalErrorList>{errorHeader}</ModalErrorList>
                          )}
                        </ModalContent>
                      </ModalContainer>
                    </>
                  ),
                  agree: "Confirm Upload",
                  disagree: "Cancel",
                }}
              />

              <OpenDialog
                openDialog={showSummary}
                setOpenDialog={setShowSummary}
                content={{
                  title: "Upload Summary",
                  description: (
                    <>
                      <ModalContainer>
                        <ModalContent>
                          <ModalHeader>Summary of Uploaded Data</ModalHeader>
                          <p style={{ fontSize: "1.6rem", color: "#0e4d45" }}>
                            <strong>Total Rows Uploaded:</strong>{" "}
                            {fileData?.validRows?.length}
                          </p>

                          {fileData?.invalidRows?.length > 0 ? (
                            <>
                              <ModalHeadeError>
                                Rows Not Uploaded (Duplicates, Incorrect Data
                                Type or Empty):
                              </ModalHeadeError>
                              <GenericTable
                                headers={prepareTableData(fileData?.invalidRows).headers}
                                rows={prepareTableData(fileData?.invalidRows)?.rows}
                                keyValues={prepareTableData(fileData?.invalidRows).headers}
                                handlerClick={() => { }}
                                state={true}
                                customTableWidth={"100%"}
                                customHeaderWidth={"5%"}
                                from={FROM_TABLE.details}
                              />

                            </>
                          ) : (
                            <SuccessMessage>
                              <strong style={{ fontSize: "1.8rem" }}>
                                All rows were uploaded successfully!
                              </strong>
                            </SuccessMessage>
                          )}
                        </ModalContent>
                      </ModalContainer>
                    </>
                  ),
                  disagree: "Close",
                }}
              />
              <ContentData
                className="background-row-2 border-color-1"
                style={{ borderRadius: "5px", padding: "10px" }}
              >
                {currentGlobalTable ? (
                  <GenericTable
                    headers={currentGlobalTable.headers.concat(["Tools"])}
                    rows={currentGlobalTable.rows.map((elm, index) => {
                      return {
                        ...elm,
                        Tools: (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-around",
                            }}
                          >
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<EditIcon />}
                                onClick={(e) =>
                                  handleOpenDialogEditGlobalValue({
                                    globalValue: elm,
                                  })
                                }
                                margin={0}
                              />
                            </div>
                            <div
                              style={{
                                width: "20px",
                              }}
                            >
                              <CustomButton
                                type="onClick"
                                text={<DeleteIcon />}
                                onClick={(e) =>
                                  handleOpenDialogDeleteValue({
                                    e,
                                    id: elm.Id,
                                  })
                                }
                                margin={0}
                                primaryColor={DELETE_COLOR}
                                secondaryColor={HOVER_DELETE_COLOR}
                              />
                            </div>
                          </div>
                        ),
                      };
                    })}
                    keyValues={currentGlobalTable.headers.concat(["Tools"])}
                    handlerClick={() => {}}
                    state={true}
                    customTableWidth={"100%"}
                    customHeaderWidth={"5%"}
                    from={FROM_TABLE.details}
                  />
                ) : (
                  <InformationShare>
                    <div className="head color1">
                      Edit Existing values for the table
                    </div>
                    <div className="body color1">
                      To Edit Click on + New Global Value
                    </div>
                  </InformationShare>
                )}
                {errorMessage && (
                  <div style={{ color: "red", marginTop: "10px" }}>
                    {errorMessage}
                  </div>
                )}
              </ContentData>
            </ConfigConstant>
          </ConfigurationSection>
        </GlobalContent>
      </Content>
    </Layout>
  );
};

export default GlobalListTable;
