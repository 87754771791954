import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  HubContainer,
  HubWrapper,
  HubCard,
  HubH2,
  HubP,
  CardContainer,
  ArrowContainer,
} from "./HubStyles";
import { useNavigate } from "react-router-dom";
import contentCardsTutorials from "pages/PlatformSetUp/contentCardsTutorials";

function TutorialsHub({ hideCards, value }) {
  const navigate = useNavigate();
  const cardContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showContentCards, setShowContentCards] = useState(false);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleLeftArrowClick = () => {
    if (!cardContainerRef.current) return;
    cardContainerRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const handleRightArrowClick = () => {
    if (!cardContainerRef.current) return;
    cardContainerRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  const handleCardClick = (item) => {
    if (item.title === "CONFIGURATION") {
      setShowContentCards(!showContentCards);
      navigate("/config-admin");
      if (showContentCards) {
      }
    } else {
      navigate(item.navigate);
    }
  };

  return (
    <HubContainer className="background-2 border-top-1">
      <CardContainer
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {hideCards && value === 3 && (
          <HubWrapper ref={cardContainerRef} className="wrap-style">
            {contentCardsTutorials.map((item) => (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <HubCard
                  className="background-card-color"
                  key={item.id}
                  onClick={() => navigate(item.toNavigate)}
                >
                  <HubH2>{item.title}</HubH2>
                  {item.image}
                  <HubP
                    style={{
                      fontSize: item.description.length > 50 ? "13px" : "18px",
                    }}
                  >
                    {item.description}
                  </HubP>
                </HubCard>
              </div>
            ))}
          </HubWrapper>
        )}
      </CardContainer>
    </HubContainer>
  );
}

export default TutorialsHub;
