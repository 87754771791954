import { useCallback, useEffect, useState, useMemo } from "react";
import { Layer, Source } from "react-map-gl";
import useGet from "hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setGeometryLine,
  setGeometryPolygon,
  setFeaturesDetailsDT,
  setObjectDeleteLineDraws,
} from "redux/actions/admin";

import { handleExtendView } from "./handlerViewLines";

import { useSWRConfig } from "swr";
import useSWR from "swr";
import deleteLine from "services/lines/deleteLine";

import { config } from "config.js";

import PopUp from "./popUp";
import {
  setCancelDrawing,
  setCurrentUpdateLineId,
  setDataLines,
  setShowLinePopUp,
} from "redux/actions/digitalTwin";
import { getNumericValue } from "helpers/getNumericValue";
import getColorFromField from "helpers/getColorFromField";

export default function Lines({
  popUpLines,
  setPopUpLines,
  setLayerIds,
  allLines,
}) {
  // Constants
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const userId = parseInt(localStorage.getItem("userId"));

  // States
  const [linesFeatures, setLinesFeatures] = useState([]);

  const [popUp, setPopUp] = useState({});
  const [line, setLine] = useState({});
  const [lines, setLines] = useState([]);
  const [state, setState] = useState({});
  const [lineIdPg, setLineIdPg] = useState(0);
  const [showLineDetails, setShowLineDetails] = useState(false);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [mapSettings, setMapSettings] = useState({});

  const { mutate } = useSWRConfig();
  const dispatch = useDispatch();

  const updateGeometryLine = useSelector(
    (state) => state.adminReducer.updateGeometryLine
  );
  const showLinePopUp = useSelector(
    (state) => state.digitalTwinReducer.setShowLinePopUp
  );
  const objectDeleteLineDraws = useSelector(
    (state) => state.adminReducer.setObjectDeleteLineDraws
  );
  const mapSettingsState = useSelector(
    (state) => state.digitalTwinReducer.mapSettings
  );
  const linesFilters = useSelector(
    (state) => state.digitalTwinReducer.linesFilters
  );

  const allFilterTableRows = useSelector(
    (state) => state.digitalTwinReducer.filterTableRows
  );

  const currentMap = useSelector((state) => state.digitalTwinReducer.map);

  const showFilterLines = useSelector(
    (state) => state.digitalTwinReducer.showFilterLinesInMap
  );

  const [typesLine] = useGet(`api/v1/type-lines`);

  // obtain map settings for proccessing dynamicly lines
  const url = `${config.URL_BACKEND_MG}map-settings?adminCompanyId=${adminCompanyId}&userId=${userId}`;
  const { data: mapSettingsDB, error: errorMapSettingsDB } = useSWR(url);

  const urlQuery = new URLSearchParams();
  urlQuery.append("adminCompanyId", adminCompanyId);
  const [libraries, librariesError] = useGet(
    `api/v1/line-libraries?${urlQuery.toString()}`
  );

  // Filtra las líneas según las ids de allFilterTableRows
  useEffect(() => {
    if (
      allLines?.length > 0 &&
      allFilterTableRows?.length > 0 &&
      showFilterLines
    ) {
      const filteredLines = allLines?.filter((line) =>
        allFilterTableRows?.some((filterRow) => filterRow.id === line.id)
      );
      setLines(filteredLines);
    }
    if (!showFilterLines) {
      setLines(allLines);
    }
  }, [allFilterTableRows, allLines, showFilterLines]);

  useEffect(() => {
    if (linesFeatures.length > 0 && showFilterLines) {
      handleExtendView({
        array: linesFeatures,
        map: currentMap,
      });
    }
  }, [linesFeatures, showFilterLines, currentMap]);

  // setear configuracion inicial del mapSettings, de DB o de redux
  useEffect(() => {
    const setMapConfig = async () => {
      const aux = [];
      // set fields by libraries
      !librariesError &&
        libraries?.forEach((ln) => {
          aux.push(parseLibrary(ln, ["select", "number"]));
        });
      // map settings from DB
      if (!errorMapSettingsDB && mapSettingsDB && mapSettingsDB.length > 0) {
        setMapSettings(mapSettingsDB[0]);
      } else {
        // map settings from redux
        setMapSettings(mapSettingsState);
      }
      setMapSettings((current) => ({ ...current, fieldsByLibrary: aux }));
    };
    setMapConfig();
  }, [
    errorMapSettingsDB,
    libraries,
    librariesError,
    mapSettingsDB,
    mapSettingsState,
  ]);

  // useEffect for updating map settings on change
  useEffect(() => {
    if (mapSettingsState !== undefined) {
      if (
        mapSettingsState.dynamicColorsSelected !== undefined &&
        mapSettingsState?.dynamicColorsSelected?.length
      ) {
        setMapSettings((current) => ({
          ...current,
          dynamicColorsSelected: mapSettingsState.dynamicColorsSelected,
        }));
      }
    }
  }, [mapSettingsState]);

  // useEffect for appliying filters to lines
  useEffect(() => {
    if (!allLines || allLines.hasOwnProperty("statusCode")) return;
    const applyFilters = () => {
      let aux = [];
      const { linesTypesFilters, linesLibrariesFilters } = linesFilters || {};
      // Filtros por tipos y librerías
      const typesOff =
        linesTypesFilters?.filter((lt) => !lt?.state).map((it) => it?.typeId) ||
        [];
      const libsOff =
        linesLibrariesFilters
          ?.filter((ll) => !ll?.state)
          .map((it) => it.libraryId) || [];
      aux = allLines.filter((line) => !typesOff.includes(line.lineType.id));
      aux = aux.filter(
        (line) => !libsOff.includes(line.lineType.lineLibrary.id)
      );
      return aux;
    };

    const filteredLines =
      linesFilters &&
      (linesFilters.linesTypesFilters.some((lt) => !lt?.state) ||
        linesFilters.linesLibrariesFilters.some((ll) => !ll?.state))
        ? applyFilters()
        : allLines;
    setLines(filteredLines);
    dispatch(setDataLines(filteredLines));

    mutate(
      `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
    );
  }, [allLines, adminCompanyId, mutate, dispatch, linesFilters]);

  // function that finds a line from line array by id, Feature id and mongo id
  const findLine = useCallback(
    (id) => {
      if (lines === undefined || id === undefined) return undefined;
      if (typeof id === "string") {
        const lookByGeoJson = lines?.find(
          (line) => line?.geojsonLineId === id || line?._id === id
        );
        const lookByLineId = lines?.find((line) => line?.id === id);
        const lookByLineFeatureId = lines?.find(
          (line) => line?.feature?.[0]?.id === id
        );

        if (lookByGeoJson) return lookByGeoJson;
        if (lookByLineId) return lookByLineId;
        if (lookByLineFeatureId) return lookByLineFeatureId;
      }
      return lines?.find((line) => line.id === id);
    },
    [lines]
  );

  useEffect(() => {
    if (lines !== undefined && Object.keys(popUpLines).length > 0) {
      const featureId = popUpLines.popUp.id;
      const searchingLine = findLine(featureId);
      if (searchingLine) setLineIdPg(searchingLine.id);
    }
  }, [lines, popUpLines, findLine]);

  // redux selectors
  const drawerControl = useSelector(
    (state) => state.digitalTwinReducer.drawerControl
  );
  const geometryLine = useSelector(
    (state) => state.adminReducer.setGeometryLine
  );

  useEffect(() => {
    if (!lines || lines.length <= 0) return null;
    setLayerIds((current) => [...current, "line-id"]);
  }, [lines, setLayerIds]);

  // listener for cleaning useStates
  useEffect(() => {
    if (geometryLine) return null;
    setLine({});
    setState(() => ({ type: "normal" }));
  }, [geometryLine]);

  useEffect(() => {
    const findCompleteLine = popUpLines.line && findLine(popUpLines.line.id);
    if (findCompleteLine) setLine(findCompleteLine);
  }, [popUpLines, findLine]);

  const handleDeleteLine = useCallback(() => {
    setDeleteConfirmation(true);
  }, []);

  // arreglarlo! el deleteLine
  const deleteLineDraw = useCallback(async () => {
    const { geojsonLineId } = line;
    const completeLine = findLine(geojsonLineId);
    await deleteLine(completeLine.id);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
    dispatch(setGeometryLine({}));
    dispatch(setGeometryPolygon({}));
    dispatch(
      setFeaturesDetailsDT({
        geometry: {
          type: null,
        },
      })
    );
    setPopUpLines({});
    setLinesFeatures((current) =>
      current.filter((line) => line?.properties?.lineIdPg !== completeLine.id)
    );
    drawerControl.set({
      type: "FeatureCollection",
      features: [],
    });
    setDeleteConfirmation(false);
    mutate(
      `${config.URL_BACKEND_PG}api/v1/lines?adminCompanyId=${adminCompanyId}`
    );
  }, [
    line,
    dispatch,
    drawerControl,
    findLine,
    setPopUpLines,
    mutate,
    adminCompanyId,
  ]);

  //  Filter lineFeatures after deleting an object (delete lines related)
  useEffect(() => {
    if (objectDeleteLineDraws.state) {
      const lineIds = objectDeleteLineDraws.lineIds;
      setLinesFeatures((current) => {
        return current.filter(
          (line) =>
            line?.properties?.lineIdPg !==
            lineIds.find((it) => it === line?.properties?.lineIdPg)
        );
      });
      dispatch(
        setObjectDeleteLineDraws({
          state: false,
          lineIds: [],
        })
      );
    }
  }, [objectDeleteLineDraws, dispatch]);

  const handleUpdateLine = () => {
    const idUserUpdating = localStorage.getItem("userId");
    const { id } = line;
    dispatch(setCurrentUpdateLineId(lineIdPg));
    const completeLine = findLine(id);
    const { feature: features } = completeLine;
    const feature = features[0];
    drawerControl.set({
      type: "FeatureCollection",
      features: [
        {
          ...feature,
          properties: {
            ...line,
            idMongo: completeLine._id,
            userUpdatedBy: parseInt(idUserUpdating),
          },
        },
      ],
    });
    setLinesFeatures((current) => {
      return current.filter(
        (line) => line?.properties?.object?.id !== completeLine.feature[0].id
      );
    });
    drawerControl.changeMode("direct_select", {
      featureId: completeLine.feature[0].id,
    });
    dispatch(setShowLinePopUp({ id: lineIdPg, show: false, update: true }));
    setPopUp({});
    dispatch(setCancelDrawing(false));
  };

  useEffect(() => {
    setPopUp({ ...popUpLines?.popUp });
  }, [popUpLines?.popUp]);

  // set line properties
  useEffect(() => {
    delete popUpLines?.line?.id;
    setLine((current) => ({
      ...current,
      ...popUpLines.line,
    }));
  }, [popUpLines?.line]);

  useEffect(() => {
    if (line?.lineTypeId && typesLine) {
      const lineType = typesLine.find((type) => type.id === line.lineTypeId);
      if (lineType) {
        const lineLibraryName = lineType?.lineLibrary?.name;
        const { id, name } = lineType || {};
        setLine((currentLine) => ({
          ...currentLine,
          lineType: lineType.type,
          lineLibrary: lineLibraryName || "Unknown Library",
          lineLibraryId: id || null,
        }));
        setPopUpLines((currentPopUpLines) => ({
          ...currentPopUpLines,
          line: {
            ...currentPopUpLines.line,
            lineLibrary: lineLibraryName || true,
          },
        }));
      }
    }
  }, [line?.lineTypeId, typesLine]);

  useEffect(() => {
    const stop =
      !line.id || state.type !== "update" || linesFeatures.length <= 0;
    if (stop) return null;
    const existLineFeature = linesFeatures.findIndex(
      (lineFeature) => lineFeature.properties.object.id === line.id
    );
    if (!existLineFeature || existLineFeature < 0) return null;
    const newLinesFeatures = linesFeatures
      .slice(0, existLineFeature)
      .concat(linesFeatures.slice(existLineFeature + 1, linesFeatures.length));
    setLinesFeatures(newLinesFeatures);
    setPopUp(() => {});
    setState(() => ({ type: "normal" }));
  }, [line?.id, state.type, linesFeatures]);

  /*
    Use effect for filtering line features and set line color based
    on map settings
  */
  useEffect(() => {
    const colors = mapSettings?.dynamicColorsSelected ?? []; // [ [<libId>, "dinamicFieldName"]]
    const colorsUserConfig = mapSettings?.userConfigColors?.colorLines ?? [];
    const librariesFields = mapSettings?.fieldsByLibrary ?? [];
    const notDuplicatedIds = new Set();
    const aux = [];

    lines?.forEach((object) => {
      // name: "circuitouno", value: "c1" → de la linea
      const lineAttributesFiltered = object?.attributes?.filter((it) =>
        colors.find(
          (elm) =>
            elm[0] === object.lineType.lineLibraryId && elm[1] === it.name
        )
      );
      // filtra el feature de cada linea, si no tiene feature sale iteracion
      if (object.feature === undefined || object?.feature.length <= 0) return;

      const lineFeature = object?.feature?.[0]; // feature object of line
      const field = librariesFields
        ?.filter((lib) => lib.id === object?.lineType?.lineLibraryId)?.[0] //filtrar libs con libId igual a libId en linea
        ?.fields?.filter((fd) => fd.name === lineAttributesFiltered?.[0]?.name); //filtra campodinamico por nombre del campodinamico de la linea
      const fieldUserConfig = colorsUserConfig
        ?.filter((lib) => lib.id === object?.lineType?.lineLibraryId)?.[0] // filtrar libs con libId igual a libId de la linea
        ?.fields?.filter((fd) => fd.state); // sacar el field deacuerdo al arreglo de colores seleccionados en mapsettings (son fields de libreria)
      const type = typesLine?.filter(
        (type) => lineFeature?.properties?.lineTypeId === type.id
      )[0];
      let newColor = type?.color;
      //check user color configuration
      if (mapSettings?.userConfigColors?.checkedLines) {
        if (fieldUserConfig?.[0]?.type === "select") {
          const colorIndex = fieldUserConfig?.[0]?.options?.indexOf(
            object?.attributes?.filter(
              (it) => it.name === fieldUserConfig?.[0]?.name
            )?.[0]?.value
          );
          // const color = fieldUserConfig?.[0]?.optionsColors?.at(colorIndex)
          newColor = getColorFromField({
            field: fieldUserConfig?.[0],
            colorIndex,
          }); // #000000
        }
        if (fieldUserConfig?.[0]?.type === "number") {
          // valor del campo numerico seleccionado en el map settings
          const value = getNumericValue(
            object?.attributes?.filter(
              (it) => it.name === fieldUserConfig?.[0]?.name
            )?.[0]?.value
          ); // 20 ... 100  ... NaN
          newColor = getColorFromField({
            field: fieldUserConfig?.[0],
            value,
            typeColor: type?.color,
          }); // #000000
        }
      }
      // take default color configuration
      if (!mapSettings?.userConfigColors?.checkedLines) {
        if (field?.[0]?.type === "select") {
          const colorIndex = field?.[0]?.options?.indexOf(
            lineAttributesFiltered?.[0]?.value
          );
          newColor = getColorFromField({ field: field?.[0], colorIndex }); // #000000
        }
        if (field?.[0]?.type === "number") {
          // valor del campo numerico seleccionado en el map settings
          const value = getNumericValue(lineAttributesFiltered?.[0]?.value); // 20 ... 100  ... NaN
          newColor = getColorFromField({
            field: field?.[0],
            value,
            typeColor: type?.color,
          }); // #000000
        }
      }
      const updatedProperties = {
        cluster: false,
        object: lineFeature,
        lineIdPg: object.id,
        ...type,
      };
      const line = {
        type: "Feature",
        properties: updatedProperties,
        geometry: {
          type: "LineString",
          coordinates: lineFeature.geometry.coordinates,
        },
      };
      // setear el nuevo color que se encontró al buscar la opcion del campo dinamico seleccionado
      if (newColor !== undefined) updatedProperties.color = newColor;

      aux.push(line);
    });
    const noDuplicated = aux.filter((it) => {
      if (notDuplicatedIds.has(it.properties.lineIdPg)) return false;
      notDuplicatedIds.add(it.properties.lineIdPg);
      return true;
    });
    setLinesFeatures(noDuplicated);
  }, [
    lines,
    line.id,
    state.type,
    typesLine,
    updateGeometryLine,
    mapSettings?.dynamicColorsSelected,
    mapSettings?.fieldsByLibrary,
    mapSettings?.userConfigColors?.checkedLines,
    mapSettings,
  ]);

  useEffect(() => {
    setLinesFeatures((current) => {
      if (Object.keys(updateGeometryLine).length > 0) {
        current.forEach((elm) => {
          if (
            elm.properties.lineIdPg === updateGeometryLine.properties.lineIdPg
          ) {
            elm.geometry.coordinates = updateGeometryLine.geometry.coordinates;
          }
        });
      }
      if (Object.keys(updateGeometryLine).length > 0) {
        return [
          ...current.filter(
            (it) => it.properties.object.id !== updateGeometryLine.properties.id
          ),
          // ...current,
        ];
      } else {
        return [...current];
      }
    });
  }, [allLines, updateGeometryLine]);

  const handleOpenLineDetails = () => {
    const lineFound = findLine(line.id);
    setLine(lineFound);
    setShowLineDetails(true);
  };

  useEffect(() => {
    if (showLinePopUp.show && Object.keys(showLinePopUp.event).length) {
      const { lat, lng } = showLinePopUp.event.lngLat;
      const line = findLine(showLinePopUp.id);
      if (line) {
        const { id, properties } = line;
        setPopUpLines((current) => ({
          popUp: {
            id,
            latitude: lat,
            longitude: lng,
          },
          line: {
            ...current.line,
            ...properties,
            id,
          },
        }));
      }
    }
  }, [showLinePopUp, setPopUpLines, findLine]);

  return (
    <div>
      <Source
        type="geojson"
        data={{
          type: "FeatureCollection",
          features: linesFeatures,
        }}
      >
        <Layer
          key="line-id"
          id="line-id"
          type={"line"}
          paint={{
            "line-color": ["get", "color"],
            "line-width": ["get", "dasharrayWidth"],
          }}
          layout={{
            "line-cap": "round",
            "line-join": "round",
          }}
        />
      </Source>
      {popUp?.latitude && popUp?.longitude && showLinePopUp.show && (
        <PopUp
          {...{
            popUp,
            setPopUp,
            line,
            lineIdPg,
            handleUpdateLine,
            handleOpenLineDetails,
            handleDeleteLine,
            showLineDetails,
            setShowLineDetails,
            deleteConfirmation,
            setDeleteConfirmation,
            deletedLine: deleteLineDraw,
          }}
        />
      )}
    </div>
  );
}

function parseLibrary(item, types = []) {
  if (item === undefined || !types.length) return null;
  const fields =
    item.fields !== undefined
      ? item.fields.filter((fd) => types.includes(fd.type) && fd.circuit)
      : [];
  return {
    id: item.lineLibraryId || item.id,
    name: item.name,
    fields,
  };
}
