import GlobalConstantsImg from "images/GlobalConstantsImg";
import GlobalListTableImg from "images/GlobalListTableImg";
import GlobalMeasurementTableImg from "images/GlobalMeasurementTableImg";
import GlobalTablesImg from "images/GlobalTablesImg";
import NeuronaIconD from "images/NeuronaIconD";
import Settingsicon from "images/Settingsicon";

const contentCardsTools = [
  {
    id: 1,
    title: "CENTRALIZED CONSTANTS MANAGEMENT",
    image: <GlobalConstantsImg />,
    description: "Add or edit your global constants.",
    toNavigate: "/config-globalConstants",
  },
  {
    id: 2,
    title: "UNIFIED STANDARDS CATALOG",
    image: <GlobalTablesImg />,
    description: "Add or Edit Equipment with Standard parameters.",
    toNavigate: "/config-globalValues-Fields",
  },
  {
    id: 3,
    title: "MULTI-NAME ASSET CONFIGURATION",
    image: <GlobalListTableImg />,
    description: "Add or Edit  Assets & their different names.",
    toNavigate: "/config-global-list-table",
  },
  {
    id: 4,
    title: "MEASUREMENT DATA INTEGRATION",
    image: <GlobalMeasurementTableImg />,
    description: "Add, Edit & Integrate dynamic data.",
    toNavigate: "/config-global-measurement-table",
  },
];

export default contentCardsTools;
