import React from "react";
import {
  ContainerIntro,
  ContainerTitle,
  HubH1,
  HubD,
  LogoCont,
} from "./HubStyles";
import LastLoginInfo from "./LastLoginInfo";
import NeuronaIconD from "images/NeuronaIconD";
// hooks
import { useColorHostName } from "hooks/colorHostName";
import { useContentData } from "hooks/useContentData";

const DescriptionHub = ({
  adminCompany,
  adminDecimetrix,
  operatorDecimetrix,
}) => {
  const { isRedDragon } = useColorHostName();
  const contentData = useContentData({ isRedDragon, section: "mainHub" });
  // Extract data from contentByHost
  const { title, description, source } = contentData;
  return (
    <>
      <ContainerIntro className="background-1">
        <LogoCont className="miniLogo">
          <NeuronaIconD width={60} height={60} />
        </LogoCont>
        <ContainerTitle>
          <HubH1>
            Decimetrix<sup style={{ fontSize: 25 }}>®</sup>
            {source} <br />
            {title}
          </HubH1>
          <HubD>{description}</HubD>
        </ContainerTitle>
        {/* <LastLoginInfo
          adminCompany={adminCompany}
          adminDecimetrix={adminDecimetrix}
          operatorDecimetrix={operatorDecimetrix}
        /> */}
      </ContainerIntro>
    </>
  );
};

export default DescriptionHub;
