import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

const ConfigIcon = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      id="Artwork"
      xmlns="http://www.w3.org/2000/svg"
      fill="#000000"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <path
          d="M16.39,14V9.47l-4-2.29V3.78h-.86v3.4l-4,2.29V14l-3,1.75.43.74,3-1.75H8l4,2.28,4-2.28,2.93,1.69.43-.74Zm-2.33.88a.83.83,0,0,1-.69-.39H9.13v-.85h4.24a.83.83,0,0,1,.69-.39.82.82,0,0,1,0,1.63Zm.81-2.49H12.7a.81.81,0,0,1-1.4,0H9.13v-.86h2.19a.81.81,0,0,1,1.36,0h2.19Zm0-2.11H10.63a.83.83,0,0,1-.69.39.82.82,0,0,1,0-1.63.83.83,0,0,1,.69.39h4.24Z"
          style={{
            fill: PRIMARY_COLOR, // Color dinámico desde el tema
          }}
        ></path>
        <path
          d="M20.56,7.56v8.87a.87.87,0,0,1-.44.76l-7.68,4.44a.88.88,0,0,1-.44.12V20.16l7.07-4.08V7.92L12,3.84V2.25a1,1,0,0,1,.44.11L20.12,6.8A.89.89,0,0,1,20.56,7.56Z"
          style={{
            fill: SECOND_COLOR, // Segundo color dinámico
          }}
        ></path>
        <path
          d="M3.44,7.56v8.87a.87.87,0,0,0,.44.76l7.68,4.44a.88.88,0,0,0,.44.12V20.16L4.93,16.08V7.92L12,3.84V2.25a1,1,0,0,0-.44.11L3.88,6.8A.89.89,0,0,0,3.44,7.56Z"
          style={{
            fill: PRIMARY_COLOR, // Color dinámico desde el tema
          }}
        ></path>
      </g>
    </svg>
  );
};

export default ConfigIcon;
