import React from "react";
import {
  ContainerExample,
  ContentCardDetail,
  DefaultGrid,
  Img,
  OptionsDefault,
  Titleh1,
  ConfigContent,
  ContentPref,
  ShowAllButton2
} from "./preferencesstyles";
import {
  PRIMARY_COLOR,
  SECOND_COLOR,
  SECOND_COLOR_TRANS,
  PRIMARY_COLOR_RD,
  SECOND_COLOR_RD,
  TRANS_COLOR_RD,
  COLOR_TEXT_RD,
  COLOR_TEXT_GD,
} from "utils/const";
//icons
import logoWhite from "images/Digital-Transformation.png";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import { useTheme } from "./ThemeContext";
import BrushIcon from "@mui/icons-material/Brush";
import Layout from "components/Layout/AdminDecimetrix";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
function ThemeConfig({ title }) {
  const { handleThemeColor } = useTheme();
  const navigate = useNavigate();
  const navigation = (path) => {
    navigate(path);
  };
  return (
    <>
      <Layout>
        <ShowAllButton2 onClick={() => navigation("/config-preferences")} style={{ marginTop: "15px" }}>
          <KeyboardArrowLeftIcon style={{ fontSize: "2rem" }} />
        </ShowAllButton2>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
          }}
        >

        </div>
        <ConfigContent className="background-2 border-top-1">
          <ContentPref
            isSingleColumn={true}
            className="background-row-1 border-color-1"
          >
            <Titleh1>{title}</Titleh1>
            <ContentCardDetail className="background-2 border-color-1">
              <div className="contentitle">
                <div
                  className="contentitle2"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div>
                    {" "}
                    <h2>
                      <ColorLensIcon style={{ marginRight: "1rem" }} />
                      Select Default Theme Color
                    </h2>
                    <p>Edit Default Theme Color</p>
                    <DefaultGrid>
                      <OptionsDefault>
                        <h2 style={{ color: PRIMARY_COLOR }}>Green Dragon</h2>
                        <ContainerExample
                          style={{ background: PRIMARY_COLOR }}
                          onClick={() =>
                            handleThemeColor(
                              PRIMARY_COLOR,
                              SECOND_COLOR,
                              SECOND_COLOR_TRANS,
                              COLOR_TEXT_GD
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>

                      <OptionsDefault>
                        <h2 style={{ color: "#f7a21b" }}>Orange Dragon</h2>
                        <ContainerExample
                          style={{ background: "#f7a21b" }}
                          onClick={() =>
                            handleThemeColor(
                              "#f7a21b",
                              "#d96f0e",
                              "#f7d68da7",
                              "#f7a21b"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                      <OptionsDefault>
                        <h2 style={{ color: "#cf1616" }}>Red Dragon</h2>
                        <ContainerExample
                          style={{ background: "#cf1616" }}
                          onClick={() =>
                            handleThemeColor(
                              "#d43b3b",
                              "#911111",
                              "#fc00006e",
                              "#d43b3b"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                      <OptionsDefault>
                        <h2 style={{ color: SECOND_COLOR_RD }}>Red Dragon II</h2>
                        <ContainerExample
                          style={{ background: PRIMARY_COLOR_RD }}
                          onClick={() =>
                            handleThemeColor(
                              PRIMARY_COLOR_RD,
                              SECOND_COLOR_RD,
                              TRANS_COLOR_RD,
                              COLOR_TEXT_RD
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                      <OptionsDefault>
                        {" "}
                        <h2 style={{ color: "#575757" }}>White Dragon</h2>
                        <ContainerExample
                          style={{ background: "#575757" }}
                          onClick={() =>
                            handleThemeColor(
                              "#575757",
                              "#10b7bd",
                              "#10b7bd7b",
                              "#575757"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                    </DefaultGrid>
                  </div>
                  <div>
                    {" "}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <h2>
                        <BrushIcon style={{ marginRight: "1rem" }} />
                        Select Silver Duo Color
                      </h2>
                      <p>Edit Silver Theme Color</p>
                    </div>
                    <DefaultGrid>
                      {" "}
                      <OptionsDefault>
                        <h2 style={{ color: "#575757" }}>Green Silver Dragon </h2>
                        <ContainerExample
                          style={{
                            background: `linear-gradient(to right, ${SECOND_COLOR} 50%, #575757 50%)`,
                          }}
                          onClick={() =>
                            handleThemeColor(
                              "#575757",
                              SECOND_COLOR,
                              SECOND_COLOR_TRANS,
                              "#575757"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                      <OptionsDefault>
                        {" "}
                        <h2 style={{ color: "#575757" }}>Red Silver Dragon </h2>
                        <ContainerExample
                          style={{
                            background:
                              "linear-gradient(to right, #b11212 50%, #575757 50%)",
                          }}
                          onClick={() =>
                            handleThemeColor(
                              "#575757",
                              "#b11212",
                              "#b112129d",
                              "#575757"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                      <OptionsDefault>
                        <h2 style={{ color: "#575757" }}>Orange Silver Dragon </h2>
                        <ContainerExample
                          style={{
                            background:
                              "linear-gradient(to right, #d96f0e 50%, #575757 50%)",
                          }}
                          onClick={() =>
                            handleThemeColor(
                              "#575757",
                              "#d96f0e",
                              "#f7d68da7",
                              "#575757"
                            )
                          }
                        >
                          <Img src={logoWhite} />
                        </ContainerExample>
                      </OptionsDefault>
                    </DefaultGrid>
                  </div>
                  {/* </DefaultGrid> */}
                </div>
              </div>
            </ContentCardDetail>
          </ContentPref>
        </ConfigContent>
      </Layout>
    </>
  );
}

export default ThemeConfig;
