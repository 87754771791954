import React, { useRef, useState, useEffect, useCallback } from "react";
import {
  HubContainer,
  HubWrapper,
  HubCard,
  HubH2,
  HubP,
  CardContainer,
  ArrowContainer,
} from "./HubStyles";
import { infoHub } from "./infoHub";
import { useNavigate } from "react-router-dom";
import LeftArrowIcon from "images/LeftArrowIcon";
import RigthArrowIcon from "images/RigthArrowIcon";
import { useSelector } from "react-redux";
import * as turf from "@turf/turf";
import { isValidCoordinate } from "pages/Dashboard/helpers/isValidCoordinates";
import useSWR, { useSWRConfig } from "swr";
import { config } from "config.js";

function AvailableModules({ hideCards, mapRef }) {
  const navigate = useNavigate();
  const cardContainerRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showContentCards, setShowContentCards] = useState(false);
  const [itemDigitalTwin, setItemDigitalTwin] = useState("DIGITAL TWIN");
  const allObjects = useSelector((state) => state.adminReducer.dataObjects);

  const userId = localStorage.getItem("userId");
  const { data: dataPinView, error: errorPinView } = useSWR(
    `${config.URL_BACKEND_MG}pinViews?userid=${userId}`
  );

  const pinneeView = dataPinView?.filter((item) => item?.activatePin === true);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event?.pageX - cardContainerRef?.current?.offsetLeft);
    setScrollLeft(cardContainerRef?.current?.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event?.pageX - cardContainerRef?.current?.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleLeftArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const handleRightArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };

  const handleCardClick = (item) => {
    if (item.title === "DIGITAL TWIN") {
      setItemDigitalTwin("DIGITAL TWIN");
    }

    if (item.title === "CONFIGURATION") {
      setShowContentCards(!showContentCards);
      navigate("/config-admin");
      if (showContentCards) {
      }
    } else {
      navigate(item.navigate);
    }
  };

  useEffect(() => {
    if (itemDigitalTwin === "DIGITAL TWIN" && mapRef?.current && pinneeView?.length === 0) {
      handleExtendView(itemDigitalTwin);
      setItemDigitalTwin(null); // Reset para evitar múltiples ejecuciones
    }
  }, [itemDigitalTwin, mapRef, handleCardClick, pinneeView]);

  const handleExtendView = useCallback(
    (item) => {
      if (!mapRef?.current || item !== "DIGITAL TWIN") return;
      const features = allObjects.length
        ? allObjects.map((obj) => {
          delete obj.location.id;
          return [obj.location.longitude, obj.location.latitude];
        })
        : [];

      const validCoordinates = features.filter(isValidCoordinate);
      const { lng, lat } = mapRef.current.getCenter();
      const zoom = mapRef.current.getZoom();
      const defaultExtended = Math.round(zoom - 1);

      if (features.length === 0) {
        mapRef.current.flyTo({
          center: [lng, lat],
          duration: 1000,
          zoom: defaultExtended,
        });
        return;
      }
      const featuresCollection = turf.points(validCoordinates);
      const envelopeFeatures = turf.envelope(featuresCollection);

      mapRef.current.fitBounds(envelopeFeatures.bbox, {
        padding: 50,
      });
    },
    [mapRef, allObjects]
  );

  return (
    <HubContainer className="background-2 border-top-1">
      <CardContainer
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        {!hideCards && (
          <>
            <HubWrapper ref={cardContainerRef} className="wrap-style">
              {infoHub.filter(item => item.title === 'DASHBOARD' || item.title === 'DIGITAL TWIN' || item.title === 'DECIMETRIX® SOCRATES' || item.title === "USERS").map((item, index) => (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <HubCard
                    className="background-card-color"
                    key={index}
                    onClick={() => handleCardClick(item)}
                  >
                    <HubH2>{item.title}</HubH2>
                    {item.icon}
                    <HubP
                      style={{
                        fontSize:
                          item.description.length > 50 ? "13px" : "18px",
                      }}
                    >
                      {item.description}
                    </HubP>
                  </HubCard>
                </div>
              ))}
            </HubWrapper>
          </>
        )}
      </CardContainer>
    </HubContainer>

  );
}

export default AvailableModules;
