import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";

const GlobalTablesImg = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      enable-background="new 0 0 52 52"
      width="150"
      height="205"
    >
      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        stroke-linecap="round"
        stroke-linejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M46.2,38.9L27.4,49.6c-1,0.6-2.3,0.6-3.3,0L5.4,38.9c-0.8-0.4-0.8-1.4,0-1.8l4.4-2.5c0.3-0.2,0.7-0.2,1,0 l11.4,6.5c1.1,0.6,2.3,0.9,3.6,0.9s2.5-0.3,3.6-0.9l11.4-6.5c0.3-0.2,0.7-0.2,1,0l4.4,2.5C47,37.5,47,38.5,46.2,38.9z"
          stroke={SECOND_COLOR}
          fill={PRIMARY_COLOR}
        ></path>{" "}
        <path
          d="M46.2,26.9L27.4,37.6c-1,0.6-2.3,0.6-3.3,0L5.4,26.9c-0.8-0.4-0.8-1.4,0-1.8l4.4-2.5c0.3-0.2,0.7-0.2,1,0 l11.4,6.5c1.1,0.6,2.3,0.9,3.6,0.9s2.5-0.3,3.6-0.9l11.4-6.5c0.3-0.2,0.7-0.2,1,0l4.4,2.5C47,25.5,47,26.5,46.2,26.9z"
          stroke={SECOND_COLOR}
          fill={PRIMARY_COLOR}
        ></path>{" "}
        <path
          d="M24.3,25.6L5.5,14.9c-0.8-0.4-0.8-1.4,0-1.8L24.3,2.4c1-0.6,2.3-0.6,3.3,0l18.8,10.7c0.8,0.4,0.8,1.4,0,1.8 L27.6,25.6C26.6,26.1,25.3,26.1,24.3,25.6z"
          stroke={SECOND_COLOR}
          fill={PRIMARY_COLOR}
        ></path>{" "}
      </g>
    </svg>
  );
};

export default GlobalTablesImg;
