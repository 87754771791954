import CustomTabs from "pages/AdminCompany/Hub/CustomTabs";
import React, { useEffect } from "react";
import ModulesAdminDeci from "./ModulesAdminDeci";
import { useNavigate } from "react-router-dom";

const AdminTabsDeci = () => {
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (value === 1) {
      navigate("/settings-app");
    }
  }, [value]);

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange} />

      {value === 0 && <ModulesAdminDeci />}
      {value === 1 && <ModulesAdminDeci hideCards={true} />}
      {value === 2 && <ModulesAdminDeci hideCards={true} />}
    </div>
  );
};

export default AdminTabsDeci;
