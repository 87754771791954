import React, { useEffect } from "react";
import AvailableModules from "./AvailableModules";
import CustomTabs from "./CustomTabs";
import SettingsHub from "./SettingsHub";
import { useNavigate } from "react-router-dom";
import TutorialsHub from "./TutotialsHub";

const AdminTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const navigate = useNavigate();

  useEffect(() => {
    if (value === 1) {
      navigate("/settings-app");
    }
    if (value === 3) {
      navigate("/tutorials-hub");
    }
  }, [value]);

  return (
    <div>
      <CustomTabs value={value} onChange={handleChange} />

      {value === 0 && <AvailableModules />}
      {value === 1 && <SettingsHub hideCards={true} value={1} />}
      {value === 3 && <TutorialsHub hideCards={true} value={3} />}
    </div>
  );
};

export default AdminTabs;
