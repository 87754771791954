import axios from "axios";
import Grid from "@material-ui/core/Grid";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { SummaryFeature } from "components/ElementDetails/OperationDetails/OperationDetailsElements";
import { useState } from "react";
import CustomButton from "components/Buttons/CustomButton";
import OpenDialog from "components/Dialogs/OpenDialog";
import CustomSelect from "components/Buttons/CustomSelect";
import UseGet from "hooks/useFetch";
import uploadFileToS3 from "services/uploadFileToS3FromPg";
import { config } from "config.js";

const DialogUploadVideoProcessed = ({
  openModalUploadVideoProcessed,
  setOpenModalUploadVideoProcessed,
  videos,
  flirVideoId,
}) => {
  const [videosUpload, setVideosUpload] = useState([]);
  const [video, setVideo] = useState(null);
  const [nameFlirVideoUpdateProcessed, setNameFlirVideoUpdateProcessed] =
    useState();
  const [pdf, setPdf] = useState(null);
  const [videoPlume, setVideoPlume] = useState(null);
  const [outputFile, setOutputFile] = useState(null);

  const [namePdf, setNamePdf] = useState();
  const [nameVideoPlume, setNameVideoPlume] = useState();
  const [nameOutputFile, setNameOutputFile] = useState();
  const [ValueQuantification, setValueQuantification] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectType, setSelectType] = useState(1);

  const [typesVideosProcessed, errorTypesVideosProcessed] = UseGet(
    "api/v1/type-video-processed"
  );

  const token = localStorage.getItem("token");

  const uploadVideoProcessed = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setVideosUpload([reader.result]);
    };
    setVideo(file);
    setNameFlirVideoUpdateProcessed(e.target.files[0].name);
  };

  const uploadPdfProcessed = async (e) => {
    const file = e.target.files[0];
    setPdf(file);
    setNamePdf(file.name);
  };

  const uploadVideoPlume = async (e) => {
    const file = e.target.files[0];
    setVideoPlume(file);
    setNameVideoPlume(file.name);
  };

  const uploadPdfOutputFile = async (e) => {
    const file = e.target.files[0];
    setOutputFile(file);
    setNameOutputFile(file.name);
  };

  const saveVideoProcessed = async () => {
    setLoading(true);

    const videoSave = await uploadFileToS3(video, "7");
    const urlReport = await uploadFileToS3(pdf, "7");

    let urlVideoPlume = null;
    if (videoPlume !== null) {
      urlVideoPlume = await uploadFileToS3(videoPlume, "7");
    }

    let urlOutputFile = null;
    if (outputFile !== null) {
      urlOutputFile = await uploadFileToS3(outputFile, "7");
    }

    await axios.post(
      `${config.URL_BACKEND_PG}api/v1/flir-video-processed`,
      {
        urlVideo: videoSave,
        value: ValueQuantification,
        flirVideoId: flirVideoId,
        urlReport: urlReport,
        urlVideoPlume: urlVideoPlume,
        dataOutputFile: urlOutputFile,
        typeId: selectType,
      },
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );
    setLoading(false);
    setOpenModalUploadVideoProcessed(false);
  };

  return (
    <OpenDialog
      openDialog={openModalUploadVideoProcessed}
      setOpenDialog={setOpenModalUploadVideoProcessed}
      content={{
        title: "Upload reports",
        description: (
          <div>
            {typesVideosProcessed && !errorTypesVideosProcessed && (
              <SummaryFeature
                style={{
                  marginTop: videosUpload ? "0" : "50px",
                }}
              >
                <div style={{ margin: "0 60px" }}>
                  <CustomSelect
                    label={"Select type FLIR video processed"}
                    options={typesVideosProcessed.map((item) => {
                      return {
                        id: item.id,
                        name: item.type,
                      };
                    })}
                    setSelect={setSelectType}
                  />
                </div>
                <div>
                  <input
                    id="uploadVideoProcessed"
                    type="file"
                    name="file"
                    style={{ visibility: "hidden" }}
                    onChange={uploadVideoProcessed}
                    accept="video/*"
                  ></input>
                  <input
                    id="uploadPdf"
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={uploadPdfProcessed}
                    accept=".pdf"
                  ></input>
                  <input
                    id="uploadVideoPlume"
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={uploadVideoPlume}
                    // accept=".pdf"
                  ></input>
                  <input
                    id="uploadOutputFile"
                    type="file"
                    name="file"
                    style={{ display: "none" }}
                    onChange={uploadPdfOutputFile}
                    accept=".dat"
                  ></input>
                  <p class="geometry-type">
                    <b>Upload video raw (*):</b>
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={10} md={10} lg={11} xl={11}>
                      {videos.length > 0 ? (
                        <input
                          type="text"
                          value={nameFlirVideoUpdateProcessed}
                        />
                      ) : (
                        <input type="text" />
                      )}
                    </Grid>
                    <Grid item xs={2} md={2} lg={1} xl={1}>
                      <label htmlFor="uploadVideoProcessed">
                        <CloudUploadIcon
                          sx={{
                            cursor: "pointer",
                            mt: 1,
                          }}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </div>
                <p class="geometry-type">
                  <b>Upload report (*):</b>
                </p>

                <Grid container spacing={2}>
                  <Grid item xs={10} md={10} lg={11} xl={11}>
                    <input type="text" value={namePdf} />
                  </Grid>
                  <Grid item xs={2} md={2} lg={1} xl={1}>
                    <label htmlFor="uploadPdf">
                      <PictureAsPdfIcon
                        sx={{
                          cursor: "pointer",
                          mt: 1,
                        }}
                      />
                    </label>
                  </Grid>
                </Grid>

                <div>
                  <p class="geometry-type">
                    <b>Upload data output:</b>
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={10} md={10} lg={11} xl={11}>
                      <input type="text" value={nameOutputFile} />
                    </Grid>
                    <Grid item xs={2} md={2} lg={1} xl={1}>
                      <label htmlFor="uploadOutputFile">
                        <CloudUploadIcon
                          sx={{
                            cursor: "pointer",
                            mt: 1,
                          }}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <p class="geometry-type">
                    <b>Upload video plume:</b>
                  </p>
                  <Grid container spacing={2}>
                    <Grid item xs={10} md={10} lg={11} xl={11}>
                      <input type="text" value={nameVideoPlume} />
                    </Grid>
                    <Grid item xs={2} md={2} lg={1} xl={1}>
                      <label htmlFor="uploadVideoPlume">
                        <CloudUploadIcon
                          sx={{
                            cursor: "pointer",
                            mt: 1,
                          }}
                        />
                      </label>
                    </Grid>
                  </Grid>
                </div>

                <div>
                  <p class="geometry-type">
                    <b>Quantification value (*):</b>
                  </p>
                  <input
                    type="number"
                    onChange={(e) => setValueQuantification(e.target.value)}
                  />
                </div>
              </SummaryFeature>
            )}

            {nameFlirVideoUpdateProcessed &&
              ValueQuantification.length > 0 &&
              namePdf !== undefined && (
                <CustomButton
                  isLoad={loading}
                  onClick={() => {
                    saveVideoProcessed();
                  }}
                  text={"Save"}
                />
              )}
          </div>
        ),
      }}
    />
  );
};

export default DialogUploadVideoProcessed;
