import axios from "axios";
import { config } from "config.js";

const getPinView = async (pin) => {
  const token = localStorage.getItem("token");
  let url = `${config.URL_BACKEND_MG}pinViews?pinViewId=${pin}`;
  const result = await axios.get(url, {
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  });

  const data = await result.data;
  return data;
};

export default getPinView;
