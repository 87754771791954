import {
  CustomizedAccordion,
  FilterTabStyles,
  ContentFilterItem,
  useStyles,
  ContentFilterItemLabel,
} from "./AccordionStyles";
import DatePickerMap from "components/DatePicker";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { addDays, addMonths, format, parse, set } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { setFilterObjectsByData } from "redux/actions";
import DatePicker from "./DatePicker";
import { PRIMARY_COLOR, RED_COLOR, WHITE_COLOR } from "utils/const";
import dayjs from "dayjs";
import CustomButton from "components/Buttons/CustomButton";
import AddIcon from "@mui/icons-material/Add";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
//save dates in database
import { AddNewViewContainer } from "./AccordionBookmarksViewsStyle";
import SimpleToolTip from "components/ToolTips/SimpleToolTip";
import BookmarksViews from "./BookmarksViews";
import { List } from "@mui/material";
import AccordionFilterDate from "./AccordionFilterDate";
import findFilterDate from "services/DatesFilter/findFilterDate";
import BookmarksFilterDate from "./BookmarksFilterDate";
import {
  ContainerDataPickers,
  SelectFilterDate,
  SelectRangeDateStyle,
} from "pages/Dashboard/components/style";

export default function FilterByDate() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { endDate, startDate } = useSelector(
    (state) => state.digitalTwinReducer.setFilterObjectsByData
  );
  const infoDatBase = useSelector(
    (state) => state.digitalTwinReducer.infoFilterDate
  ); //////////////////
  const objectDates = useSelector((state) => state.adminReducer.dataObjects);
  //New dates
  const timeZone = "America/Bogota";
  const newToday = dayjs().tz(timeZone);
  const lastMonth = dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD");
  const [startDateSelected, setStartDateSelected] = useState(dayjs(startDate));
  const [endDateSelected, setEndDateSelected] = useState(dayjs(newToday));

  //////////////////
  const today = format(new Date(), "yyyy-MM-dd");
  const [localEndDate, setLocalEndDate] = useState("");
  const [localStartDate, setLocalStartDate] = useState(startDate);
  const [useFilterCalendar, setUseFilterCalendar] = useState(false);
  //Open modal for save date
  const [openModal, setOpenModal] = useState(false);

  const userId = localStorage.getItem("userId");
  const adminCompanyId = localStorage.getItem("adminCompanyId");
  const rol = localStorage.getItem("role");

  //formatear por año, mes y dia a startDateSelected y endDateSelected
  const startDateSelectedFormated = dayjs(startDateSelected).format("YYYY-MM-DD");
  const endDateSelectedFormated = dayjs(endDateSelected)
    .add(1, "day")
    .format("YYYY-MM-DD");

  const handleChangeStartDate = (date) => {
    setLocalStartDate(date);
  };

  const handleChangeEndDate = (date) => {
    setLocalEndDate(date);
  };

  const handleRestartDates = () => {
    objectDates.sort(
      (a, b) => new Date(a?.date).getTime() - new Date(b?.date).getTime()
    );
    setLocalEndDate(today);

    setLocalStartDate(() =>
      dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD")
    );
    setEndDateSelected(dayjs(today));
    setStartDateSelected(() =>
      dayjs(dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD"))
    );
    //const endDatePlusOne = dayjs(today).add(1, 'day').format("YYYY-MM-DD"); //add a day more to the end date
    dispatch(
      setFilterObjectsByData({
        endDate: today,
        startDate: dayjs(objectDates?.at(0)?.date).format("YYYY-MM-DD"),
      })
    );
  };

  const updateDates = async () => {
    try {
      const data = await findFilterDate({ userId: userId });
      return data;
    } catch (error) {
      console.error("Error fetching related objects:", error);
    }
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (
      (endDate && localEndDate === "") ||
      (endDate && endDateSelected === "")
    ) {
      const endDateParsed = parse(endDate, "yyyy-MM-dd", new Date());
      const currentEndDate = addDays(endDateParsed, -1);
      setLocalEndDate(() => format(currentEndDate, "yyyy-MM-dd"));
      setEndDateSelected(dayjs(endDate));
    } else if (localEndDate === "" || endDateSelected === "") {
      setLocalEndDate(() => today);
      setEndDateSelected(dayjs(today));
    }
  }, [endDate, localEndDate, today, endDateSelected]);

  useEffect(() => {
    if (!localStartDate || !startDateSelected) {
      const parseLocalToday = parse(today, "yyyy-MM-dd", new Date());
      const currentStartDate = addMonths(parseLocalToday, -1);
      setLocalStartDate(() => format(currentStartDate, "yyyy-MM-dd"));
      setStartDateSelected(dayjs(currentStartDate));
    }
  }, [today, localStartDate, startDateSelected]);

  useEffect(() => {
    if (localEndDate === "" || endDateSelected === "") return;
    const globalEndData = addDays(
      parse(localEndDate, "yyyy-MM-dd", new Date()),
      1
    );

    dispatch(
      setFilterObjectsByData({
        endDate: endDateSelectedFormated,
        startDate: startDateSelectedFormated,
      })
    );
  }, [
    localEndDate,
    localStartDate,
    endDateSelected,
    startDateSelected,
    dispatch,
  ]);

  return (
    <>
      <CustomizedAccordion
        className="background-1 color1 border-color-1 border-radius-1"
        classes={{
          content: classes.content,
          expanded: classes.expanded,
        }}
        defaultExpanded={true}
        style={{ boxShadow: "none" }}
      >
        <ContentFilterItem>
          <Typography
            sx={{
              fontFamily: document.body.style.fontFamily,
              color: document.body.style.color,
            }}
          >
            Filter For Date
          </Typography>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="color1" />}
          ></AccordionSummary>
        </ContentFilterItem>
        <List
          sx={{
            height: "100%",
            position: "bottom",
          }}
          subheader={<li />}
        >
          <AddNewViewContainer>
            <button className="button-add" onClick={handleOpenModal}>
              <SimpleToolTip title="Add this Date" placement="right">
                <AddIcon sx={{ fontSize: "3rem", color: WHITE_COLOR }} />
              </SimpleToolTip>
            </button>
          </AddNewViewContainer>
        </List>
        <AccordionSummary>
          <FilterTabStyles>
            <ContentFilterItem className="date">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ContentFilterItemLabel className="color1">
                  <DatePickerMap
                    label={"start date"}
                    value={startDateSelected}
                    setValue={setStartDateSelected}
                    maxDate={endDateSelected}
                    setUseFilterCalendar={setUseFilterCalendar}
                  />
                  <DatePickerMap
                    label={"end date"}
                    value={endDateSelected}
                    setValue={setEndDateSelected}
                    minDate={startDateSelected}
                    setUseFilterCalendar={setUseFilterCalendar}
                  />
                </ContentFilterItemLabel>
              </LocalizationProvider>
              <ContentFilterItemLabel>
                <CustomButton
                  primaryColor={"var(--background-primary_color)"}
                  text={"Show all"}
                  onClick={handleRestartDates}
                />
              </ContentFilterItemLabel>

              {openModal && (
                <AccordionFilterDate
                  handleCloseModal={handleCloseModal}
                  localStartDate={startDateSelectedFormated}
                  localEndDate={endDateSelectedFormated}
                  updateDates={updateDates}
                />
              )}
            </ContentFilterItem>
          </FilterTabStyles>
        </AccordionSummary>
      </CustomizedAccordion>
      <div
        style={{
          backgroundColor: "white",
          padding: "18px",
          borderRadius: "5px",
          marginTop: "10px",
          border: "1px solid #d3d3d3",
        }}
      >
        <BookmarksFilterDate
          setLocalStartDate={setStartDateSelected}
          setLocalEndDate={setEndDateSelected}
          updateDates={updateDates}
          handleRestartDates={handleRestartDates}
        />
      </div>
    </>
  );
}
