import styled from "styled-components";
import {
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";

import { PRIMARY_COLOR , SECOND_COLOR} from "utils/globalColorsTheme";

export const InfoRelation = styled("div")`
  //display: flex;
  flex-direction: column;
  margin: 20px 0px;
  margin-left: 100mm;
  padding: 10px 10px;
  width: 20%;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid #bbbbbb;

  .title {
    font-size: 1.3rem;
    font-weight: 600;
    text-transform: uppercase;
    display: flex;
    flex: 1;
    color: ${PRIMARY_COLOR};
    /* justify-self: center; */
  }

  .itemContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    border: 1px solid #d1d1d1;
    background-color: #f2f2f2;
    flex-wrap: wrap;
  }

  .textContent {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .iconContainer {
    display: flex;
    gap: 8px; /* Espacio entre iconos */
    justify-content: flex-end;
  }

  .location-details {
    margin: 5px 0;
    font-weight: 500;
    font-size: 1.6rem;
  }

  span {
    font-size: 1.3rem;
    font-weight: 400;
    font-family: ${document.body.style.fontFamily};
  }

  .spanIcons {
    font-size: 1.3rem;
    font-weight: 400;
    flex: 1;
    display: flex;
  }

  .spanList {
    list-style-type: none;
    padding: 0; /* Eliminar padding para listas */
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0px 15px;
    margin-top: 7px;

    //margin-bottom: 0px;
  }

  .spanNumberObjects {
    font-size: 1.3rem;
    font-weight: 400;
    padding: 0px 15px;
    //margin-top: 7px;
    margin-bottom: 7px;
  }

  .custom-select {
    margin: 5px 0;
    padding: 7px;
    width: 60%;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: ${WHITE_COLOR};
    font-size: 1.6rem;
  }
  textarea {
    height: 60px;
  }

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    margin: 10px auto;
  }
  .currency-table--container {
    width: 100%;
    min-width: 230px;
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    background-color: ${WHITE_SECOND_COLOR};
  }
  .currency-table--container .table__top-left {
    border-radius: 5px 0 0 0;
  }
  .currency-table--container .table__top-right {
    border-radius: 0 5px 0 0;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 0 0 5px 0;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 0 0 0 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    background-color: ${WHITE_SECOND_COLOR};
    color: #525252;
  }

  .input-location {
    border: 0px;
    padding: 0 5px;
    width: 100%;
    background-color: ${WHITE_SECOND_COLOR};
  }

  .button-copy {
    border: none;
    cursor: pointer;
  }
`;

export const FeatureDetails = styled("div")`
  display: flex;
  flex-direction: column;
  margin: 20px 0;
  padding: 15px 10px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: transparent;
  border-radius: 4px;

  .title {
    font-size: 2rem;
    align-self: center;
  }

  .location-details {
    margin: 5px 0;
    font-weight: 500;
    font-size: 1.6rem;
  }

  .custom-select {
    margin: 5px 0;
    padding: 7px;
    width: 100%;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }

  .geometry-type {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1.4rem;
  }

  .geometry-type p {
    margin-left: 5px;
  }

  input,
  textarea {
    margin: 5px 0;
    width: 100%;
    border: 1px solid ${PRIMARY_COLOR};
    border-radius: 5px;
    padding: 5px;
    background-color: transparent;

    font-size: 1.6rem;
  }
  textarea {
    height: 60px;
  }

  .main-currency-table {
    width: 100%;
    min-width: 235px;
    margin: 10px auto;
  }
  .currency-table--container {
    width: 100%;
    min-width: 230px;
    margin: 0 auto;
  }
  .currency-table--container table {
    width: 100%;
    height: 100%;
  }

  .currency-table--container table td {
    width: 50%;
    height: 40px;
    font-size: 1.6rem;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
  }
  .currency-table--container .table__top-left {
    border-radius: 5px;
  }
  .currency-table--container .table__top-right {
    border-radius: 5px;
  }
  .currency-table--container .table__bottom-right {
    border-radius: 5px;
  }
  .currency-table--container .table__bottom-left {
    border-radius: 5px;
  }
  .currency-table--container .table__right {
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    text-align: right;
    background-color: ${WHITE_SECOND_COLOR};

    color: #525252;
  }

  .input-location {
    border: 0px;
    padding: 0 5px;
    width: 100%;
    background-color: ${WHITE_SECOND_COLOR};
  }

  .button-copy {
    border: none;
    cursor: pointer;
    background-color: transparent;
  }
`;

export const ButtonCard = styled.button`
  width: 40%;
  height: 40px;
  margin: 10px 0;
  border-radius: 5px;
  border: none;
  background-color: ${PRIMARY_COLOR};
  color: ${WHITE_COLOR};
  font-size: 1.4rem;
  font-weight: bold;
  align-self: end;

  &:hover {
    background-color: ${SECOND_COLOR};
    cursor: pointer;
  }
`;
