import styled from "styled-components";
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  border: solid 1px ${PRIMARY_COLOR};
  border-radius: 5px;
  margin-bottom: 16px;
  background: #f2f2f2;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const IconGroup = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

export const IconOption = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  &.coloricon {
    color: #44598d;
  }

  &.select {
    opacity: ${(props) => (props.disabled ? 0.2 : 1)};
  }
`;

export const IconOption2 = styled(IconOption)`
  &.select {
    cursor: pointer;
  }
`;

export const FieldContent = styled.div`
  display: flex;
  flex-direction: row;
  margin: 1rem 0;
  background: ${({ background }) => background};
  border: 1px solid #bebebe;
  padding: 8px;
  color: black;
  border-radius: 5px;
  height: 50px;
  align-items: center;

  input {
    border: none;
    border-radius: 5px;
    width: 50%;
    text-align: center;
    height: 100%;
  }
  input:focus {
    outline: none;
  }

  p {
    width: 50%;
    text-align: center;
  }
`;

export const OptionsContainer = styled.div`
  margin-top: 1rem;

  > p {
    margin-bottom: 1rem;
  }
`;

export const Field = styled.div`
  background: ${(props) => (props.isRightColumn ? "#f5f5f5" : "transparent")};
  padding: 0.5rem;
  border-radius: 4px;

  &.empty {
    color: #666;
    font-style: italic;
  }
`;

export const FieldInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
  width: 100%;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;

  .checkbox-group {
    display: flex;
    align-items: center;
    gap: 3px;
    margin: 0 1rem;
  }
`;
