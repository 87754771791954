import styled, { keyframes } from "styled-components";
import {
  WHITE_COLOR,
  BORDER_GREY,
  SECOND_COLOR,
  BLACK_COLOR,
  BACKGROUND_SECOND_GREY,
  GRAY_COLOR,
  PRIMARY_COLOR,
  BACKGROUD_GREY_PREVIEW,
} from "utils/const";

export const CardContainer = styled.div`
  position: relative;
  border-radius: 8px;
  width: ${(props) => props.width};
  height: ${(props) => props.height};
`;

export const DeleteButton = styled.div`
  position: absolute;
  top: 3px;
  right: ${(props) => `${props.right}px`};
  border: none;
  cursor: pointer;
  z-index: 2;
`;

export const RestoreButton = styled.div`
  position: absolute;
  top: 3px;
  right: 3px;
  border: none;
  cursor: pointer;
  z-index: 2;
`;

export const ResizeRight = styled.div`
  .hover-area {
    width: 25px;
    height: 100%;
    position: absolute;
    right: 0px;
    margin-top: 20px;
    padding-bottom: 20px;
  }

  .hidden-div {
    display: none;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 100%;
    width: 25px;
    border-bottom-right-radius: 10px;
    background: #03030326;
    border-left: dashed 2px #0000002e;
    border-top: dashed 2px #0000002e;
    margin-top: 5px;
    padding-bottom: 24px;
    align-items: center;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 50%;
      border-bottom: dashed 2px #0000002e;
    }

    span:hover {
      transform: scale(1);
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const ResizeBottom = styled.div`
  .hover-area {
    width: 100%;
    height: 25px;
    position: absolute;
    bottom: 0px;
  }

  .hidden-div {
    display: none;
    flex-wrap: wrap;
    justify-content: space-around;
    position: absolute;
    align-items: center;
    text-align: center;
    border: none;
    cursor: pointer;
    z-index: 2;
    height: 25px;
    width: 100%;
    background: #03030326;
    border-top: dashed 2px #0000002e;

    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50%;
      height: 100%;
      border-right: dashed 2px #0000002e;
    }
  }

  .hover-area:hover .hidden-div {
    display: flex;
  }
`;

export const CountGoal = styled.p`
  font-size: ${(props) => (props.fontSize ? `${props?.fontSize}px` : "2.2rem")};
  color: ${(props) => props.color || BLACK_COLOR};
  font-weight: 600;
`;

export const CountValue = styled.p`
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "3rem")};
  color: ${(props) => props.color || BLACK_COLOR};
  font-weight: 600;
  text-align: center;
`;

export const Description = styled.p`
  font-size: ${(props) => `${props.fontSize}px` || "1.5rem"};
  color: ${(props) => props.color || BLACK_COLOR};
`;

export const CardKpiGauge = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  border: 1px solid ${(props) => props.borderColor || SECOND_COLOR};
  background-color: ${(props) => props.backgroundColor || WHITE_COLOR};
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;

export const CardKpi = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  height: 100%;
  border: 1px solid ${(props) => props.borderColor || SECOND_COLOR};
  /* box-shadow: 1px 3px 6px ${BORDER_GREY}; */
  background-color: ${(props) => props.backgroundColor || WHITE_COLOR};
  border-radius: 8px;
  padding: 10px;
  text-align: center;
`;

// ---------------------- Preview Kpi Styles -----------------------------

const rainbowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const ContainerPreviewChart = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px;
  /* background: ${GRAY_COLOR}; */
  background: ${WHITE_COLOR};

  border-radius: 10px;

  .section-chart-button {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    width: 70%;
    margin-right: 10px;
  }

  .preview-chart {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${BACKGROUD_GREY_PREVIEW};
    width: 100%;
    height: 90%;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
  }

  .chart {
    width: ${({ widthChart }) => `${widthChart}px`};
    height: ${({ heightChart }) => `${heightChart}px`};
    border-radius: 8px;
  }

  .container-update {
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${WHITE_COLOR};
    width: 100%;
    height: 5%;
    margin-right: 10px;
    border-radius: 10px;
  }

  .editing-tools {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 30%;
    background: ${BACKGROUD_GREY_PREVIEW};
    border-radius: 10px;
    padding: 20px;
  }

  .groupe-styles {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    background: ${WHITE_COLOR};
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }

  .sections {
    display: flex;
    flex-direction: column;
    width: 100%;

    h3 {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border-radius: 5px;
      color: ${WHITE_COLOR};
      background: var(--background-primary_color);
      margin-bottom: 15px;
      height: 40px;
    }
  }

  .section-style {
    display: flex;
    flex-wrap: wrap;
    margin: 2px;
    width: 100%;
    height: 37px;
    justify-content: space-between;
    text-align: center;
    align-items: center;

    .alias {
      width: 100%;
      align-self: center;
      padding: 10px;
    }
    p {
      font-size: 15px;
      font-weight: 900;
    }

    h4 {
      color: ${GRAY_COLOR};
    }

    div {
      display: flex;
      align-items: center;
      justify-content: end;
    }

    .dynamic_goal {
      display: flex;
      align-items: center;
      justify-content: start;
      width: 55%;

      input[type="checkbox"] {
        width: 8%;
        height: 20px;
        margin-right: 5px;
      }
    }

    .input-title {
      width: 100%;
      padding: 5px 5px 0px 5px;
      margin: 0px 5px 0px 5px;
      border-top: none;
      border-right: none;
      border-left: none;
      text-align: center;
      border-bottom-color: ${BACKGROUND_SECOND_GREY};
      border-bottom-width: 3px;
      font-weight: 900;
    }

    .input-title:focus {
      outline: none;
    }

    input[type="number"] {
      width: 38%;
      height: 18px;
      margin: 10px;
      text-align: center;
      border: none;
      background-color: ${BACKGROUND_SECOND_GREY};
      font-weight: 600;
    }

    input[type="checkbox"] {
      width: 45%;
      height: 20px;
    }

    input[type="range"] {
      margin: 10px;
      width: 59%;
    }

    input[type="color"] {
      border: none;
      width: 45%;
      height: 37px;
      background-color: ${WHITE_COLOR};
      cursor: pointer;
    }
    input[type="color"]::-webkit-color-swatch-wrapper {
      padding: 0;
    }
    input[type="color"]::-webkit-color-swatch {
      border: 2px solid ${BACKGROUND_SECOND_GREY};
      border-radius: 5px;
    }

    select {
      border: none;
      width: 40%;
      margin: 7px;
      height: 25px;
      border-radius: 5px;
      text-align: center;
      color: ${WHITE_COLOR};
      background-color: var(--background-primary_color);
      cursor: pointer;
    }
  }
`;
