import { styled } from "@mui/material/styles";
import {
  GRAY_COLOR,
  PRIMARY_COLOR,
  WHITE_COLOR,
  WHITE_SECOND_COLOR,
} from "utils/const";
import Accordion from "@mui/material/Accordion";

// import {
//   PRIMARY_COLOR,
//   BLACK_COLOR,
//   WHITE_COLOR,
//   WHITE_SECOND_COLOR,
// } from "../../../../utils/const";

export const DynamicColorsContainer = styled("div")`
  width: 70%;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  padding: 10px;
  padding-right: 10px;
  margin-top: 1rem;
  gap: 1rem;
`;

export const DynamicColorsTitle = styled("div")`
  width: 100%;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 0px;
`;

export const DynamicColorsFields = styled("div")`
  height: 400px;
  padding-left: 20px;

  .library-name-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    gap: 5%;
  }
`;

export const MappingLibraryContainer = styled("div")`
  width: 600px;
  height: auto;
  overflow-y: auto;
  margin-bottom: 20px;
`;

export const LibraryField = styled("div")`
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .library-names {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .color-container {
    height: auto;
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }
  input[type="checkbox"] {
    width: 1.25rem;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid ${PRIMARY_COLOR};
    text-align: center;
    background-color: white;
    margin-left: 20px;
  }
  .color-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    p {
      width: fit-content;
      margin-right: 10px;
      /* min-width: 200px; */
    }
    input[type="color"] {
      height: 20px;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
      background-color: white;
      margin-left: 1rem;
      margin-bottom: 5px;
    }
  }
  .color-item-container {
    display:flex;
    width: 120px;
  }
  .label-restriction-container {
    width: 80%;
    padding-right: 10px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .input-number-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 50%;

    input[type="number"] {
      max-width: 80px;
      margin-right: 10px;
    }
  }
  .input-number-container-doble {
    display: flex;
    flex-direction: row;
  }
`;

export const CustomizedAccordion = styled(Accordion)`
  box-shadow: none;
  margin-bottom: 1rem;

  .accordion-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 600px;
    background-color: ${WHITE_SECOND_COLOR};
    margin-top: 0px;
    border: 1px solid ${GRAY_COLOR};
    border-radius: 4px;
    padding: 5px;
    box-shadow: none;
  }
`;

export const WrapperTypeObjectTitle = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin: 0 0 10px 0px; */
  p {
    font-size: 1.4rem;
    color: ${(props) =>
    props.theme === "light" ? WHITE_COLOR : document.body.style.color};
  }
`;
