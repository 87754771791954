import { useState, useEffect } from "react";
import { useSWRConfig } from "swr";
// Config
import { config } from "config.js";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Services
import uploadFileToS3 from "services/uploadFileToS3FromPg";
import uploadFileComponent, {
  saveCaptureMedia,
} from "services/components/uploadFilePointComponents";
// Styles
import {
  ContentButtonGallery,
  Label,
} from "components/ElementDetails/EventDetails/EventDetailsElements";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { setUpdateAtComponent } from "services/savePointComponent";

const AddPicture = ({ pointComponent, uploadImages }) => {
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeComponentMedia, setTypeComponentMedia] = useState(null);
  const { mutate } = useSWRConfig();

  useEffect(() => {
    const typeMedia = pointComponent?.fieldsCaptureMedia[0]?.name;
    setTypeComponentMedia(typeMedia);
  }, [pointComponent]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
  };

  const savePhotos = async () => {
    return Promise.all(
      photosFile.map(async (file) => {
        const urlFile = await uploadFileToS3(file, 8);
        if (typeComponentMedia === null || typeComponentMedia === undefined) {
          await uploadFileComponent({
            pointComponentId: pointComponent?.id,
            componentFileTypeId: 1,
            url: urlFile,
          });
        } else {
          const aliasFieldMedia = pointComponent?.fieldsCaptureMedia.find(
            (item) => item.name === typeComponentMedia
          )?.alias;

          await saveCaptureMedia({
            pointComponentMgId: pointComponent?.mongoId,
            media: [
              {
                type: typeComponentMedia,
                alias: aliasFieldMedia,
                url: urlFile,
              },
            ],
          });
        }
      })
    ).then(() => {
      setOpenModal(false);
      setPhotosFile([]);
      setPhotos([]);
      setUpdateAtComponent(pointComponent?.id);
      uploadImages();
      const urlData = `${config.URL_BACKEND_PG}api/v1/point-components?id=${pointComponent?.id}`;
      mutate(urlData);
    });
  };

  return (
    <>
      <ContentButtonGallery>
        <Label htmlFor="files">
          <AddAPhotoIcon />
          <p>Add Pictures</p>
        </Label>
        <input
          id="files"
          style={{ visibility: "hidden" }}
          accept="image/*"
          type="file"
          name="file"
          multiple
          onChange={uploadFile}
        />
      </ContentButtonGallery>
      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <ContentDialog
              pictures={photos}
              pointComponent={pointComponent}
              typeComponentMedia={typeComponentMedia}
              setTypeComponentMedia={setTypeComponentMedia}
            />
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disagree={() => {
          setPhotos([]);
          setPhotosFile([]);
        }}
      />
    </>
  );
};

export default AddPicture;
