import { styled } from "@mui/material/styles";
import { PRIMARY_COLOR, WHITE_COLOR } from "utils/const";

export const DynamicColorsContainer = styled("div")`
  width: 100%;
  display: flex;
  flex-direction: column;
  font-size: 1.3rem;
  padding: 10px;
  padding-right: 10px;
  margin-top: 1rem;
  gap: 1rem;

  .body {
    margin-top: 1rem;
    font-size: 1.3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .items {
    display: flex;
    flex-wrap: wrap;
  }

  .body-item {
    flex: 1;

    input {
      width: 40%;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
      background-color: white;
      margin-left: 1rem;
    }

    label {
      display: flex;
      gap: 0.2rem;
      font-weight: 500;
    }
  }

  .object-clusters-title {
    display: flex;
    align-items: center;
  }

  .select-input {
    width: 250px;
  }

  .input-field {
    width: 100%;
  }

  select {
    border: none;
    width: 40%;
    margin: 7px 0;
    height: 25px;
    border-radius: 5px;
    text-align: center;
    color: ${WHITE_COLOR};
    background-color: var(--background-primary_color);
  }
`;

export const DynamicColorsTitle = styled("div")`
  width: 100%;
  font-size: 1.3rem;
  font-weight: bold;
  margin: 10px 0px;
`;

export const DynamicColorsFields = styled("div")`
  width: 100%;
  height: 400px;
  padding-left: 20px;

  .library-name-container {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid black;
    margin-bottom: 10px;
    gap: 5%;
  }
`;

export const LibraryField = styled("div")`
  width: 100%;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;

  .library-names {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .color-container {
    display: flex;
    flex-direction: column;
    padding-left: 50px;
  }
  input[type="checkbox"] {
    width: 1.25rem;
    background: transparent;
    border: none;
    outline: none;
    border-bottom: 1px solid ${PRIMARY_COLOR};
    text-align: center;
    background-color: white;
    margin-left: 20px;
  }
  .color-item {
    display: flex;
    flex-direction: row;
    gap: 10px;
    p {
      width: fit-content;
      margin-right: 10px;
      /* min-width: 200px; */
    }
    input[type="color"] {
      height: 20px;
      background: transparent;
      border: none;
      outline: none;
      border-bottom: 1px solid ${PRIMARY_COLOR};
      text-align: center;
      background-color: white;
      margin-left: 1rem;
      margin-bottom: 5px;
    }
  }
  .color-item-container {
    display:flex;
    width: 120px;
  }
  .label-restriction-container {
    width: 80%;
    padding-right: 10px;
    margin-right: 15px;
    margin-left: 15px;
  }
  .input-number-container {
    display: flex;
    justify-content: center;
    gap: 5px;
    width: 50%;

    input[type="number"] {
      max-width: 80px;
      margin-right: 10px;
    }
  }
  .input-number-container-doble {
    display: flex;
    flex-direction: row;
  }
`;
