import { useState } from "react";
// Components
import Grid from "pages/Dashboard/components/Grid";
import CustomButton from "components/Buttons/CustomButton";
// Style
import { InputTextSection, Divider } from "./style";
// Services
import { createChartText } from "services/Dashboard/createChart";
import uploadFileToS3 from "services/uploadFileToS3FromPg";
import { config } from "config";
import { useSWRConfig } from "swr";
import { addChartInAllViews } from "services/Dashboard/orderCards";
import useStoreDashboard from "../Store";

const Inputs = ({ from, setOpenDialog }) => {
  const [loadingAddChart, setLoadingAddChart] = useState(false);
  const [dataChart, setDataChart] = useState({
    chartType: "textChart",
    backgroundColor: "#fff",
    borderColor: "#ececec",
    sections: [
      {
        title: "",
        subtitle: "",
        body: "",
        image: "",
        imageTitle: "",
        style: {
          titleBackgroundColor: "#fff",
          titleFontColor: "#404040",
          titleFontSize: 40,
          subtitleFontColor: "#404040",
          subtitleFontSize: 28,
          subtitleBackgroundColor: "#fff",
          bodyFontColor: "#404040",
          bodyFontSize: 18,
          bodyBackgroundColor: "#fff",
          imageFontColor: "#404040",
          imageFontSize: 16,
        },
      },
    ],
  });

  const { sections } = dataChart;
  const { mutate } = useSWRConfig();
  const setNewCreateChart = useStoreDashboard(
    (state) => state.setNewCreateChart
  );
  const newCreateChart = useStoreDashboard((state) => state.newCreateChart);
  const handleInputChange = (sectionIndex, field, value) => {
    const updatedChartData = { ...dataChart };
    updatedChartData.sections[sectionIndex][field] = value;
    setDataChart(updatedChartData);
  };

  const handleStyleChange = (sectionIndex, styleField, value) => {
    const updatedChartData = { ...dataChart };
    updatedChartData.sections[sectionIndex].style[styleField] = value;
    setDataChart(updatedChartData);
  };

  const handlerAddSection = () => {
    setDataChart((current) => ({
      chartType: "textChart",
      backgroundColor: "#fff",
      borderColor: "#ececec",
      sections: [
        ...current.sections,
        {
          title: "",
          subtitle: "",
          body: "",
          image: "",
          imageTitle: "",
          style: {
            titleBackgroundColor: "#fff",
            titleFontColor: "#404040",
            titleFontSize: 40,
            subtitleFontColor: "#404040",
            subtitleFontSize: 28,
            subtitleBackgroundColor: "#fff",
            bodyFontColor: "#404040",
            bodyFontSize: 18,
            bodyBackgroundColor: "#fff",
            imageFontColor: "#404040",
            imageFontSize: 16,
          },
        },
      ],
    }));
  };

  const handleSaveImageChartText = async () => {
    // Send  all images to s3 and get URLs
    const updatedChartData = { ...dataChart };

    for (let i = 0; i < updatedChartData.sections.length; i++) {
      const section = updatedChartData.sections[i];
      const tempImage = section.tempImage;

      if (tempImage) {
        // Send the image to S3 and get the URL
        const imageUrl = await uploadFileToS3(tempImage);

        // Update the state with the URL of the image in S3
        updatedChartData.sections[i].image = imageUrl;
        updatedChartData.sections[i].tempImage = null; // Clear the temporary image
      }
    }

    // Actualiza el estado final
    setDataChart(updatedChartData);
  };

  const handlerAddChartText = async () => {
    const adminCompanyId = localStorage.getItem("adminCompanyId");
    const userId = localStorage.getItem("userId");
    const urlChartText = `${config.URL_BACKEND_MG}charts-text?adminCompanyId=${adminCompanyId}&from=${from}`;
    const urlOrders = `${config.URL_BACKEND_MG}order-cards-dashboard?userId=${userId}&adminCompanyId=${adminCompanyId}`;

    setLoadingAddChart(true);

    await handleSaveImageChartText();

    const data = {
      ...dataChart,
      adminCompanyId: parseInt(adminCompanyId),
      from: from,
    };

    const filteredData = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => value)
    );

    const { data: responseData } = await createChartText({
      body: filteredData,
    });
    if (responseData) {
      mutate(urlChartText);
      await addChartInAllViews({
        userId,
        type: from,
        body: {
          newChart: {
            id: responseData._id,
          },
        },
      });
      mutate(urlOrders);
      setNewCreateChart(!newCreateChart);
    }
    setLoadingAddChart(false);
    setOpenDialog(false);
  };

  const handleImageUpload = (sectionIndex, e) => {
    const updatedChartData = { ...dataChart };
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (readerEvent) => {
        updatedChartData.sections[sectionIndex].tempImage = file;
        setDataChart(updatedChartData);
      };

      reader.readAsDataURL(file);
    }
  };

  const handleRemoveSection = (sectionIndex) => {
    const updatedChartData = { ...dataChart };
    updatedChartData.sections.splice(sectionIndex, 1);
    setDataChart(updatedChartData);
  };

  return (
    <>
      {sections?.map((section, index) => (
        <InputTextSection>
          <button
            className="button-remove"
            onClick={() => handleRemoveSection(index)}
          >
            ✖️
          </button>
          <h2>Section {index + 1}</h2>
          <Divider />
          {/* Sections style */}
          <Grid minWidth={300} gap={"8px"}>
            {/* Title */}
            <div>
              <h2 className="subtitle">Title</h2>
              <input
                className="alias"
                type="text"
                value={section.title}
                onChange={(e) =>
                  handleInputChange(index, "title", e.target.value)
                }
              />
            </div>
            <div>
              <h2 className="subtitle">Title background color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.titleBackgroundColor}
                onChange={(e) =>
                  handleStyleChange(
                    index,
                    "titleBackgroundColor",
                    e.target.value
                  )
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Title font color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.titleFontColor}
                onChange={(e) =>
                  handleStyleChange(index, "titleFontColor", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Title font size (px)</h2>
              <input
                min={10}
                className="alias"
                type="number"
                value={section?.style?.titleFontSize ?? 30}
                onChange={(e) =>
                  handleStyleChange(index, "titleFontSize", e.target.value)
                }
              />
            </div>
            {/* Subtitle */}
            <div>
              <h2 className="subtitle">Subtitle</h2>
              <input
                className="alias"
                type="text"
                value={section.subtitle}
                onChange={(e) =>
                  handleInputChange(index, "subtitle", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Subtitle background color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.subtitleBackgroundColor}
                onChange={(e) =>
                  handleStyleChange(
                    index,
                    "subtitleBackgroundColor",
                    e.target.value
                  )
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Subtitle font color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.subtitleFontColor}
                onChange={(e) =>
                  handleStyleChange(index, "subtitleFontColor", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Subtitle font size (px)</h2>
              <input
                min={10}
                className="alias"
                type="number"
                value={section?.style?.subtitleFontSize ?? 22}
                onChange={(e) =>
                  handleStyleChange(index, "subtitleFontSize", e.target.value)
                }
              />
            </div>
            {/* Body */}
            <div>
              <h2 className="subtitle">Body</h2>
              <textarea
                className="alias"
                type="text"
                value={section.body}
                onChange={(e) =>
                  handleInputChange(index, "body", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Body background color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.bodyBackgroundColor}
                onChange={(e) =>
                  handleStyleChange(
                    index,
                    "bodyBackgroundColor",
                    e.target.value
                  )
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Body font color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.bodyFontColor}
                onChange={(e) =>
                  handleStyleChange(index, "bodyFontColor", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Body font size (px)</h2>
              <input
                min={10}
                className="alias"
                type="number"
                value={section?.style?.bodyFontSize ?? 16}
                onChange={(e) =>
                  handleStyleChange(index, "bodyFontSize", e.target.value)
                }
              />
            </div>

            {/* Image */}
            <div>
              <h2 className="subtitle">Image title</h2>
              <input
                className="alias"
                type="text"
                value={section.imageTitle}
                onChange={(e) =>
                  handleInputChange(index, "imageTitle", e.target.value)
                }
              />
            </div>
            <div>
              <h2 className="subtitle">Image font color</h2>
              <input
                className="alias"
                type="color"
                value={section?.style?.imageFontColor}
                onChange={(e) =>
                  handleStyleChange(index, "imageFontColor", e.target.value)
                }
              />
            </div>

            <div>
              <h2 className="subtitle">Image font size (px)</h2>
              <input
                min={10}
                className="alias"
                type="number"
                value={section?.style?.imageFontSize ?? 16}
                onChange={(e) =>
                  handleStyleChange(index, "imageFontSize", e.target.value)
                }
              />
            </div>
            <div>
              <h2 className="subtitle">Upload image</h2>
              <input
                type="file"
                accept="image/*"
                onChange={(e) => handleImageUpload(index, e)}
              />
            </div>
          </Grid>
        </InputTextSection>
      ))}
      {/* Button to add Section */}
      <CustomButton
        onClick={handlerAddSection}
        text={"Add section"}
        margin={"10px 0"}
      />
      {/* Chart style */}
      <div>
        <h2 className="subtitle">Chart background color</h2>
        <input
          className="alias"
          type="color"
          value={dataChart?.backgroundColor}
          onChange={(e) =>
            setDataChart((current) => ({
              ...current,
              backgroundColor: e.target.value,
            }))
          }
        />
      </div>
      <div>
        <h2 className="subtitle">Chart border color</h2>
        <input
          className="alias"
          type="color"
          value={dataChart?.borderColor}
          onChange={(e) =>
            setDataChart((current) => ({
              ...current,
              borderColor: e.target.value,
            }))
          }
        />
      </div>

      {/* Add text chart */}

      <CustomButton
        className="btn-add-chart"
        onClick={() => handlerAddChartText()}
        isLoad={loadingAddChart}
        text={"Add text chart"}
        margin={"15px 0"}
      />
    </>
  );
};

export default Inputs;
