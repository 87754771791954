import axios from "axios";
import { config } from "config.js";

const uploadCSV = async (tableId, tableCategoriesId, groupId, validRows) => {
  try {
    const token = localStorage.getItem("token");
    /* const formData = new FormData();
     //formData.append("file", validRows); // Agregar el archivo CSV
     formData.append("file", new Blob([JSON.stringify(validRows)], { type: "application/json" }));  // Cambia a JSON con las filas válidas
     formData.append("tableId", tableId); // Agregar el ID de la tabla
     formData.append("tableCategoriesId", tableCategoriesId); // Agregar el ID de la categoría
     formData.append("groupId", groupId);*/
    const payload = {
      tableId,
      tableCategoriesId,
      groupId,
      validRows: JSON.stringify(validRows), // Enviar como JSON en el cuerpo
    };

    const res = await axios.post(
      `${config.URL_BACKEND_MG}global-values-fields/upload-csv`,
      payload,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "application/json", // El encabezado necesario para enviar archivos
        },
      }
    );

    return { data: res.data, res };
  } catch (error) {
    return { error };
  }
};

export default uploadCSV;
