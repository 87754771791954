import { fontFamilies } from "Styles";
import styled, { css, keyframes } from "styled-components";
import { Styles } from "styles";
import {
  BACKGROUND_GREY,
  BLACK_GRAY_COLOR,
  BORDER_GREY,
  GRAY_COLOR,
  PRIMARY_COLOR,
  SECOND_COLOR,
  SECOND_COLOR_TRANS,
  WHITE_COLOR,
} from "utils/const";

export const ConfigContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(100vh - 28rem);
  height: auto;
  width: auto;
  padding: 10rem 0;
  background-color: ${BACKGROUND_GREY};
`;

export const ContentPref = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  border-radius: 10px;
  height: auto;
  width: auto;
  /* max-width: 1150px; */
  border: solid 1px ${BORDER_GREY};
  background-color: ${WHITE_COLOR};
  padding: 2rem;
  margin: 4rem;

  display: grid;
  grid-template-columns: ${({ isSingleColumn }) =>
    isSingleColumn ? "1fr" : "repeat(2, 1fr)"};
  gap: 10px;
`;

export const ContentCardP = styled.div`
  /* ${Styles} */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 5px;
  min-height: 120px;
  cursor: pointer;
  border-radius: 10px;
  background-color: ${BACKGROUND_GREY};

  :hover {
    /* background-color: ${SECOND_COLOR_TRANS}; */
  }

  h2 {
    font-size: medium;
    color: ${PRIMARY_COLOR};
  }

  p {
    white-space: pre-line; /* Esta propiedad permite saltos de línea */
  }

  .icon {
    display: flex;
    justify-self: center;
    margin-right: 2rem;
    color: ${BLACK_GRAY_COLOR};
  }
`;
export const ModalClose = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const ColorGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0rem 1rem;
  /* display: grid; */
  /* grid-template-columns: repeat(5, 1fr); */
  /* gap: 10px; */
  width: 100%;

  .iconButton {
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    :hover {
      color: ${SECOND_COLOR};
    }
  }
`;

export const DescriptionBack = styled.div`
  display: flex;
  flex-direction: row;
`;

export const DefaultGrid = styled.div`
  display: flex;
  flex-direction: row;
  margin: 2rem 1rem;
  width: 100%;
  justify-content: space-between;

  .iconButton {
    color: ${PRIMARY_COLOR};
    cursor: pointer;
    :hover {
      color: ${SECOND_COLOR};
    }
  }
`;

export const ColorSquare = styled.div`
  width: 40px;
  height: 40px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  cursor: pointer;
  /* background-color: #ff6262; */

  :hover {
    border: solid 3px #c7c7c793;
  }
`;

const rainbowAnimation = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

export const ColorRainBow = styled.div`
  width: 40px;
  height: 40px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4158d0;
  background-image: linear-gradient(
    43deg,
    #4158d0 0%,
    #c850c0 46%,
    #ffcc70 100%
  );

  background-size: 200% auto;
  animation: ${rainbowAnimation} 3s linear infinite;

  :hover {
    border: solid 3px #c7c7c793;
    animation-play-state: paused;
  }
`;

export const ColorSquare2 = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 70px;
  height: 40px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  color: ${WHITE_COLOR};
  font-size: small;
  cursor: pointer;
  background-color: ${BLACK_GRAY_COLOR};

  :hover {
    border: solid 3px #c7c7c793;
  }
`;

export const ColorCircle = styled.div`
  margin: 1rem;
  border-radius: 50%;
  position: relative;
  bottom: -25px;

  width: 10px;
  height: 10px;
  .small {
    bottom: -55px;
  }

  .medium {
    width: 40px;
    height: 40px;
  }
`;

export const ContentButtonPref = styled.div`
  display: flex;
  flex-direction: row;
`;
export const ColorSquarePar = styled.div`
  width: 40px;
  height: 40px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  cursor: pointer;
  background: linear-gradient(to right, ${PRIMARY_COLOR} 50%, #ffffff18 50%);

  &:hover {
    border: solid 3px #c7c7c793;
  }
`;

export const OptionsDefault = styled.div`
  display: "flex";
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ContainerExample = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 120px;
  margin: 1rem 1rem 1rem 0rem;
  border-radius: 5px;
  border: solid 1px ${BORDER_GREY};
  cursor: pointer;
  background-color: ${WHITE_COLOR};
  font-size: 30px;

  border: ${({ type, currentFont }) =>
    type === "Noto" && currentFont === "'Noto Sans', 'Montserrat'"
      ? `solid 4px var(--background-primary_color)`
      : type === "Courier" &&
        currentFont === "'Courier New', Courier, monospace"
      ? `solid 4px var(--background-primary_color)`
      : type === "Verdana" &&
        currentFont === "'Verdana', Geneva, Tahoma, sans-seriff"
      ? `solid 4px var(--background-primary_color)`
      : type === "Gill" &&
        currentFont ===
          "'Gill Sans', 'Gill Sans MT', 'Calibri', 'Trebuchet MS', 'sans-serif'"
      ? `solid 4px var(--background-primary_color)`
      : "none"};

  &:hover {
    border: solid 3px #c7c7c793;
  }

  .fontsize {
    font-size: 12px;
  }

  .Noto {
    font-family: "Noto Sans", "Montserrat";
  }
  .Courier {
    font-family: "Courier New", Courier, monospace;
  }

  .Verdana {
    font-family: "'Verdana', Geneva, Tahoma, sans-seriff";
  }

  .Gill {
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  .Aptos {
    font-family: "Aptos, Inter, system-ui, -apple-system, BlinkMacSystemFont, sans-serif";
  }
`;

export const ToolTipExample = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 150px;
  margin: 1rem;
  border-radius: 5px;
  border: solid 1px ${BORDER_GREY};
  background-color: ${GRAY_COLOR};
`;

export const Titleh1 = styled.h1`
  color: ${PRIMARY_COLOR};
  font-size: 2.3rem;
  margin-left: 1rem;
`;

export const ContentCardDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 2rem;
  margin: 5px;
  min-height: 120px;
  border-radius: 10px;
  background-color: ${BACKGROUND_GREY};
  /* border: solid 1px ${BORDER_GREY}; */

  p {
    margin: 0rem 1rem;
  }

  h2 {
    margin: 1.4rem 0rem;
    font-size: 1.7rem;
  }

  .optionsContainer {
    display: flex;
    flex-direction: row;
    width: 100%;

    justify-content: space-between;
    /* border: solid 1px ${BORDER_GREY}; */
  }

  .previewContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 0rem 0rem 0rem 4rem;
    padding: 1rem;
    background-color: ${WHITE_COLOR};
    /* border: solid 1px ${BORDER_GREY}; */
    border-radius: 10px;
  }

  .gridPreviewContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, auto);
    /* gap: 1rem; */
    justify-content: center;
    align-items: center;
    width: 60%;
    margin: 2rem 0rem 0rem 4rem;
    padding: 1rem;
    background-color: ${WHITE_COLOR};
    border-radius: 10px;
  }
  .contentitle {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 1rem;
  }

  .contentitle2 {
    margin-bottom: 3rem;
  }

  .centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
`;

export const ShowAllButton = styled.div`
  cursor: pointer;
  padding: 10px;
  background-color: var(--background-primary_color);
  border-radius: 50%;
  height: 4rem;
  color: #fff;
  position: absolute;
  /* bottom: 20px; */
  /* top: 18%; */
  left: 30px;
  z-index: 999;
`;

export const ShowAllButton2 = styled.div`
  cursor: pointer;
  padding: 10px;
  background-color: var(--background-primary_color);
  border-radius: 50%;
  height: 4rem;
  color: #fff;
  /* top: 18%; */
  /* transform: translateY(-50%); */
  left: 30px;
  z-index: 999;
  position: absolute;

  &:hover {
    background-color: var(--background-secondary_color);
  }
`;

export const Img = styled.img`
  width: 150px;
  height: auto;
  margin: 0;
  padding: 0;
  /* @media screen and (max-width: 960px) {
    width: 150px;
  }
  @media screen and (max-width: 768px) {
    width: 140px;
  } */
`;
