import React from "react";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
const Socratesicon = () => {
  return (
    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="#000000">
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g
        id="SVGRepo_tracerCarrier"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></g>
      <g id="SVGRepo_iconCarrier">
        <defs>
          <style>
            {`.cls-3 { fill: ${PRIMARY_COLOR}; }
              .cls-4 { fill: ${SECOND_COLOR}; }`}
          </style>
        </defs>
        <title>Icon_24px_Dialogflow_Color</title>
        <g data-name="Product Icons">
          <g data-name="colored-32/dialogflow-enterprise">
            <g data-name="32px_Dialogflow-Favicon">
              <path
                className="cls-3"
                d="M12,12,4,8v6.76a.49.49,0,0,0,.19.39L8.83,17.9a.32.32,0,0,1,.17.29V21.7a.17.17,0,0,0,.26.14l10.51-6.69a.5.5,0,0,0,.23-.42V8Z"
              ></path>
            </g>
          </g>
          <path
            className="cls-4"
            d="M12,10l8-4L12.12,2.06a.25.25,0,0,0-.24,0L4,6Z"
          ></path>
        </g>
      </g>
    </svg>
  );
};

export default Socratesicon;
