import { useEffect, useState } from "react";
import { GRAY_COLOR } from "utils/const";

function SwitchSquared({ label = [], checked, onChange, className = "" }) {
  const [isChecked, setIsChecked] = useState(checked);

  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  const handleChange = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div
      className={`flex items-center space-x-2 cursor-pointer ${className}`}
      onClick={handleChange}
      style={{
        ...styles.container,
        backgroundColor: isChecked ? "#11ee24" : "#5c5c5c",
      }}
    >
      <div
        style={{
          ...styles.switch,
          color: isChecked ? "#000000" : "#000",
          transform: isChecked ? "translateX(75px)" : "translateX(0)",
          transition: "transform 0.3s ease-in-out",
        }}
      >
        {isChecked ? label?.[1] ?? "" : label?.[0] ?? ""}
      </div>
    </div>
  );
}

const styles = {
  container: {
    width: "150px",
    minWidth: "100px",
    height: "auto",
    border: `1px solid ${GRAY_COLOR}`,
    padding: "1px 2px",
    borderRadius: "4px",
  },
  switch: {
    width: "70px",
    height: "25px",
    paddingLeft: "5px",
    paddingRight: "5px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "4px",
  },
};

export default SwitchSquared;
