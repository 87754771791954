import axios from "axios";
import { config } from "config.js";

const uploadCSV = async (tableId, tableCategoriesId, groupId, validRows) => {
  try {
    const token = localStorage.getItem("token");

    const payload = {
      tableId,
      tableCategoriesId,
      groupId,
      validRows: JSON.stringify(validRows), // Enviar como JSON en el cuerpo
    };

    const res = await axios.post(
      `${config.URL_BACKEND_MG}global-list-table/upload-csv`,
      payload,
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : "",
          "Content-Type": "application/json", // El encabezado necesario para enviar archivos
        },
      }
    );
    console.log("res", res);
    return { data: res.data, res };
  } catch (error) {
    return { error };
  }
};

export default uploadCSV;
