import styled from "styled-components";
import { RED_COLOR, WHITE_COLOR } from "utils/const";
import { PRIMARY_COLOR } from "utils/globalColorsTheme";

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.6rem;
  font-weight: 500;
  margin: 10px 0;

  .required {
    margin: 0 3px;
    color: ${RED_COLOR};
  }

  .label {
    width: 100%;
    margin: 5px 0;
    display: flex;
    align-items: center;
  }

  .select {
    font-size: 1.4rem;
    width: 100%;
    padding: 0 5px;
    height: 30px;
    border: none;
    border-radius: 5px;
    background-color: ${PRIMARY_COLOR};
    color: ${WHITE_COLOR};
  }

  .message {
    font-weight: 400;
    color: #d60000;
    margin: 0;
  }
`;
