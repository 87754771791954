import React, { useEffect, useRef, useState } from "react";
import {
  ArrowContainer,
  CardContainer,
  HubCard,
  HubContainer,
  HubH2,
  HubP,
  HubWrapper,
  LeftArrow,
  RightArrow,
} from "./HubStyles";
import useGet from "hooks/useFetch";
import { useNavigate } from "react-router-dom";
import CustomTabs from "./CustomTabs";
import DescriptionHub from "./DescriptionHub";
import Layout from "components/Layout/AdminDecimetrix";
import AvailableModules from "./AvailableModules";
import contentCardsSettings from "pages/PlatformSetUp/contentCardsSettings";
import contentCardsSettingsAdminDecimetrix from "pages/PlatformSetUp/contentCardsSettingsAdminDecimetrix";
import TutorialsHub from "./TutotialsHub";

function ConfigCardsSettings({ hideCards }) {
  const cardContainerRef = useRef(null);
  const [value, setValue] = React.useState(1);
  const role = localStorage.getItem("role");

  const handleChange = (event, newValue) => {
    //event.preventDefault();
    setValue(newValue);
  };

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const navigate = useNavigate();
  const [currentRole, setCurrentRole] = useState(role);

  useEffect(() => {
    setCurrentRole(role);
  }, [currentRole]);

  useEffect(() => {
    if (value === 0) {
      navigate("/admin");
    }
    if (value === 3) {
      navigate("/tutorials-hub");
    }
  }, [value]);

  const handleMouseDown = (event) => {
    if (!cardContainerRef.current) return;
    setIsDragging(true);
    setStartX(event.pageX - cardContainerRef.current.offsetLeft);
    setScrollLeft(cardContainerRef.current.scrollLeft);
  };

  const handleMouseMove = (event) => {
    if (!cardContainerRef.current) return;
    if (!isDragging) return;
    const x = event.pageX - cardContainerRef.current.offsetLeft;
    const walk = (x - startX) * 3;
    cardContainerRef.current.scrollLeft = scrollLeft - walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleLeftArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: -350, behavior: "smooth" });
  };

  const handleRightArrowClick = () => {
    cardContainerRef.current.scrollBy({ left: 350, behavior: "smooth" });
  };
  const adminCompanyId = localStorage.getItem("adminCompanyId");

  const [adminCompany, errorAdminCompany] = useGet(
    `api/v1/admin-company/${adminCompanyId}`
  );

  const adminDecimetrixId = localStorage.getItem("adminDecimetrixId");
  const [adminDecimetrix, errorAdminDecimetrix] = useGet(
    `api/v1/admin-decimetrix/${adminDecimetrixId}`
  );

  return (
    <>
      <Layout>
        <DescriptionHub
          adminCompany={adminCompany}
          adminDecimetrix={adminDecimetrix}
        />
        <CustomTabs value={value} onChange={handleChange} />
        <HubContainer className="background-2 border-top-1">
          <CardContainer
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseUp}
          >
            {!hideCards &&
            value === 1 &&
            currentRole === "Administrador de compañía" ? (
              <>
                <HubWrapper ref={cardContainerRef} className="wrap-style">
                  {contentCardsSettings.map((item) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <HubCard
                        className="background-card-color"
                        key={item.id}
                        onClick={() => navigate(item.toNavigate)}
                      >
                        <HubH2>{item.title}</HubH2>
                        {item.image}
                        <HubP
                          style={{
                            fontSize:
                              item.description.length > 50 ? "13px" : "18px",
                          }}
                        >
                          {item.description}
                        </HubP>
                      </HubCard>
                    </div>
                  ))}
                </HubWrapper>
              </>
            ) : (
              <>
                <HubWrapper ref={cardContainerRef} className="wrap-style">
                  {contentCardsSettingsAdminDecimetrix.map((item) => (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <HubCard
                        className="background-card-color"
                        key={item.id}
                        onClick={() => navigate(item.toNavigate)}
                      >
                        <HubH2>{item.title}</HubH2>
                        {item.image}
                        <HubP
                          style={{
                            fontSize:
                              item.description.length > 50 ? "13px" : "18px",
                          }}
                        >
                          {item.description}
                        </HubP>
                      </HubCard>
                    </div>
                  ))}
                </HubWrapper>
              </>
            )}
            {value === 0 && <AvailableModules />}
            {value === 3 && <TutorialsHub hideCards={true} value={true} />}
          </CardContainer>
        </HubContainer>
      </Layout>
    </>
  );
}

export default ConfigCardsSettings;
