import { useState, useEffect, useRef } from "react";
import { useSWRConfig } from "swr";
// Config
import { config } from "config.js";
// Components
import OpenDialog from "components/Dialogs/OpenDialog";
import ContentDialog from "./ContentDialog";
// Services
import uploadFileToS3 from "services/uploadFileToS3FromPg";
import { saveCaptureMedia } from "services/lines/uploadFileLines";
// Styles
import {
  ContentButtonGallery,
  Label,
} from "components/ElementDetails/EventDetails/EventDetailsElements";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import { setUpdateAtComponent } from "services/savePointComponent";
import { haveCaptureMediaFields } from "helpers/photoGallery/haveCaptureMedia";

const AddPicture = ({ line, uploadImages }) => {
  const [photosFile, setPhotosFile] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [typeLineMedia, setTypeLineMedia] = useState(null);
  const [dialogNoCaptureMedia, setDialogNoCaptureMedia] = useState(false);
  const { mutate } = useSWRConfig();
  const inputRef = useRef();

  useEffect(() => {
    const typeMedia = line?.fieldsCaptureMedia?.[0]?.name;
    setTypeLineMedia(typeMedia);
  }, [line]);

  const uploadFile = async (e) => {
    const files = e.target.files;
    Object.keys(files).forEach((key) => {
      setPhotosFile((current) => [...current, files[key]]);
    });
    Object.keys(files).forEach((key) => {
      const reader = new FileReader();
      reader.readAsDataURL(files[key]);
      reader.onload = () => {
        setPhotos((current) => [reader.result, ...current]);
      };
    });
    setOpenModal(true);
  };

  const savePhotos = async () => {
    return Promise.all(
      photosFile.map(async (file) => {
        const urlFile = await uploadFileToS3(file, 8);
        const aliasFieldMedia = line?.fieldsCaptureMedia.find(
          (item) => item.name === typeLineMedia
        )?.alias;

        await saveCaptureMedia({
          lineObjectMgId: line?._id || line?.geojsonLineId,
          media: [
            {
              type: typeLineMedia,
              alias: aliasFieldMedia,
              url: urlFile,
            },
          ],
        });
      })
    ).then(() => {
      setOpenModal(false);
      setPhotosFile([]);
      setPhotos([]);
      setUpdateAtComponent(line?.id);
      uploadImages();
      const adminCompanyId = localStorage.getItem("adminCompanyId");
      const urlParams = new URLSearchParams();
      urlParams.append("adminCompanyId", adminCompanyId);
      urlParams.append("id", line.id);
      const urlData = `${
        config.URL_BACKEND_PG
      }api/v1/lines?${urlParams.toString()}`;
      mutate(urlData);
    });
  };

  const handleInputClick = (e) => {
    e.preventDefault();
    if (!haveCaptureMediaFields(line?.fieldsCaptureMedia)) {
      setDialogNoCaptureMedia(true);
      return;
    }
    setDialogNoCaptureMedia(false);
    inputRef.current.click();
  };

  return (
    <>
      <ContentButtonGallery>
        <Label htmlFor="files" onClick={handleInputClick}>
          <AddAPhotoIcon />
          <p>Add Pictures</p>
        </Label>
        {!dialogNoCaptureMedia && (
          <input
            id="files"
            style={{ display: "none" }}
            accept="image/*"
            type="file"
            name="file"
            multiple
            onChange={uploadFile}
            ref={inputRef}
          />
        )}
      </ContentButtonGallery>
      <OpenDialog
        openDialog={openModal}
        setOpenDialog={setOpenModal}
        content={{
          title: "Upload pictures",
          description: (
            <ContentDialog
              pictures={photos}
              line={line}
              typeLineMedia={typeLineMedia}
              setTypeLineMedia={setTypeLineMedia}
            />
          ),
          agree: "Save",
          disagree: "Cancel",
        }}
        execute={savePhotos}
        disagree={() => {
          setPhotos([]);
          setPhotosFile([]);
        }}
      />
      <OpenDialog
        openDialog={dialogNoCaptureMedia}
        setOpenDialog={setDialogNoCaptureMedia}
        content={{
          title: "Error 'captureMedia' field",
          description: (
            <div>
              <h2>Field 'CaptureMedia' is missing</h2>
              <span>
                For uploading images the library '
                {line?.lineType?.lineLibrary?.name}' needs at least one
                'captureMedia' field.
              </span>
              <br />
              <span>
                this can be done in 'Configuration' &gt; 'Libraries'{" "}
                <a href="/library-configurations">library-configurations</a>
              </span>
            </div>
          ),
          disagree: "Close",
        }}
      />
    </>
  );
};

export default AddPicture;
