import React, { useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { PRIMARY_COLOR, SECOND_COLOR } from "utils/globalColorsTheme";
import { styled } from "@mui/material/styles";
import { use } from "react";
import { useState } from "react";

const CustomTabs = ({ value, onChange }) => {
  const StyledTabs = styled(Tabs)({
    display: "flex",
  });
  const role = localStorage.getItem("role");

  const [currentRole, setCurrentRole] = useState(role);

  useEffect(() => {
    setCurrentRole(role);
  }, [currentRole]);

  return (
    <StyledTabs
      className="background-1"
      value={value}
      onChange={onChange}
      TabIndicatorProps={{
        style: {
          backgroundColor: SECOND_COLOR,
          height: "6px",
          borderRadius: "6px",
        },
      }}
    >
      <Tab
        label="Available Modules"
        style={{
          color: PRIMARY_COLOR,
          fontFamily: document.body.style.fontFamily,
        }}
        sx={{
          marginLeft: "115px",
          fontSize: "20px",
          "@media (max-width: 768px)": {
            marginLeft: "50px",
            fontSize: "17px",
          },
        }}
      />
      <Tab
        style={{
          color: currentRole !== "Operario" ? PRIMARY_COLOR : "#9F9E9E",
        }}
        label="SETTINGS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled={currentRole === "Operario"}
      />
      <Tab
        className="module-color"
        label="AI AGENTS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="TUTORIALS"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="LOGIN INFO"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
      <Tab
        className="module-color"
        label="Get More"
        sx={{
          fontFamily: document.body.style.fontFamily,
          fontSize: "20px",
          "@media (max-width: 768px)": {
            fontSize: "17px",
          },
        }}
        disabled
      />
    </StyledTabs>
  );
};

export default CustomTabs;
