import { useState, useEffect, useRef, useContext } from "react";
// Services
import chatResponse from "services/chatgpt";
// Data
import examplesQuestion from "./examplesQuestion";
import promptContextPart1 from "./promptContextPart1.json";
import promptContextPart2 from "./promptContextPart2.json";
// style
import {
  ChatContainer,
  MessagesContainer,
  Messages,
  Message,
  InputContainer,
  Input,
  SendIconButton,
} from "./ChatbotStyle";
import { WHITE_COLOR } from "utils/const";
import { ThemeContext } from "App";

const Chat = () => {
  const { theme } = useContext(ThemeContext);

  const [messages, setMessages] = useState([]);
  const [prompt, setPrompt] = useState("");
  const [placeholder, setPlaceHolder] = useState("What is decimetrix?");
  const [isLoading, setIsLoading] = useState(false);
  const [response, setResponse] = useState(false);
  const containerRef = useRef(null);

  const handlePromptChange = (event) => {
    setPrompt(event.target.value);
  };

  const examplesClickHandler = (e) => {
    const value = e.target.textContent;
    setPrompt(value);
  };

  const examplesHoverHandler = (e) => {
    const value = e.target.textContent;
    setPlaceHolder(value);
  };

  const handleSendMessage = async (event) => {
    event.preventDefault();
    if (prompt) {
      setMessages([...messages, { text: prompt, isResponse: false }]);
      setResponse(true);
    }
  };

  useEffect(() => {
    const findResponse = async () => {
      setIsLoading(true);
      let data = await chatResponse(promptContextPart1, prompt);
      if (data.includes("I'm sorry. I can't solve that question.")) {
        data = await chatResponse(promptContextPart2, prompt);
      }
      data = data.replace(/respuesta: /i, "");
      setIsLoading(false);
      setMessages([
        ...messages,
        {
          text: data,
          isResponse: true,
        },
      ]);
    };

    if (response) {
      findResponse();
      setResponse(false);
      setPrompt("");
    }
    const container = containerRef.current;
    container.scrollTop = container.scrollHeight;
  }, [messages, containerRef, response, prompt]);

  return (
    <ChatContainer ref={containerRef}>
      <MessagesContainer>
        {messages.length === 0 && (
          <section className="examples">
            <h1>Example:</h1>
            <ul>
              {Object.keys(examplesQuestion).map((key) => (
                <li
                  className="border-color-1 color1 button-hover-1"
                  style={{
                    color:
                      theme === "light"
                        ? document.body.style.color
                        : WHITE_COLOR,
                  }}
                  key={key}
                  onClick={examplesClickHandler}
                  onMouseEnter={examplesHoverHandler}
                >
                  {examplesQuestion[key]}
                </li>
              ))}
            </ul>
          </section>
        )}
        <Messages className="color1">
          {messages.map((message, index) => (
            <Message
              className="color1"
              key={index}
              isResponse={message.isResponse}
            >
              {message.text}
            </Message>
          ))}
          {isLoading && <p>Loading...</p>}
        </Messages>
      </MessagesContainer>
      <InputContainer
        className="background-row-2 border-color-1 color1"
        onSubmit={handleSendMessage}
      >
        <span className="search-icon"></span>
        <Input
          className="background-row-2  color1"
          type="text"
          value={prompt}
          onChange={handlePromptChange}
          placeholder={placeholder}
        />
        <SendIconButton className="color1" onClick={handleSendMessage} />
      </InputContainer>
    </ChatContainer>
  );
};

export default Chat;
