import { BLACK_COLOR } from "utils/const";
import Chartsicon from "images/Chartsicon";
import Barsicons from "images/Barsicons";
import Cicleicon from "images/Cicleicon";
import Socratesicon from "images/Socratesicon";
// import Sensoricon from "images/Sensoricon";
// import Calculatoricon from "images/Calculatoricon";
// import Scope3icon from "images/Scope3icon";
// import Scope2icon from "images/Scope2icon";
import AddUsers from "images/AddUsers";
import ConfigIcon from "images/ConfigIcon";
import Settingsicon from "images/Settingsicon";

export const infoHub = [
  {
    title: "DASHBOARD",
    navigate: "/dashboard-admin",
    icon: <Chartsicon />,
    description: "Report of all the objects collected",
    color: BLACK_COLOR,
  },
  {
    title: "DIGITAL TWIN",
    navigate: "/digital-twin-admin",
    icon: <Cicleicon />,
    description: "View all objects and events in the map",
  },
  {
    title: "USERS",
    navigate: "/operators",
    icon: <AddUsers />,
    description: "Add a new user to your team",
  },
  {
    title: "CONFIGURATION: NO CODE DEVELOPMENT",
    navigate: "/config-admin",
    icon: <ConfigIcon />,
    description: "Create the data architecture, platform, configuration, customization without coding barriers",
  },
  {
    title: "PLATFORM SET UP",
    navigate: "/setUp-admin",
    icon: <Barsicons />,
    description: "Configure global app settings",
  },
  {
    title: "DECIMETRIX® SOCRATES",
    navigate: "/chatbot",
    icon: <Socratesicon />,
    description: "Interactive chat with Decimetrix®",
  },

  {
    title: "SETTINGS",
    navigate: "/settings-app",
    icon: <Settingsicon />,
    description: "Customize app settings and preferences",
  },
  {
    title: "UX/UI STYLE PREFERENCES",
    navigate: "/config-preferences",
    icon: <Settingsicon />,
    description: "Customize app settings and preferences",
  },
  // {
  //   title: "SENSORS OPTIMIZATION",
  //   navigate: "/sensor-optimization",
  //   icon: <Sensoricon />,
  //   description: "Sensors location optimization",
  // },
  // {
  //   title: "FLARE EFFICIENCY",
  //   navigate: "/flare-efficiency",
  //   icon: <Calculatoricon />,
  //   description: "Flare efficiency estimator",
  // },
  // {
  //   title: "SCOPE 2",
  //   navigate: "#",
  //   icon: <Scope2icon />,
  //   description:
  //     "Emissions are indirect GHG emissions associated with the purchase of electricity, steam, heat, or cooling",
  // },
  // {
  //   title: "SCOPE 3",
  //   navigate: "#",
  //   icon: <Scope3icon />,
  //   description:
  //     "Emissions include all sources not within an organization’s scope 1 and 2 boundary",
  // },
];
