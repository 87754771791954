import React from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AlertTitle } from "@mui/material";

function MessagesDialog({
  open,
  severity,
  message,
  handleClose,
  position = "fixed",
  title = true,
  margin,
}) {
  return (
    <Snackbar
      style={{
        position,
      }}
      open={open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%", margin: margin }}
      >
        {title && <AlertTitle>{severity}</AlertTitle>}
        {message}
      </Alert>
    </Snackbar>
  );
}

export default MessagesDialog;
